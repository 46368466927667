import React from "react";
import Modals from "components/Modal";
import { FORUM_MGT } from "navigation/routes";
import EnhancedTable from "components/EnhancedTable";
import EditFundraisingForm from "./Edit";
import AddFundraisingFormUser from "./Add";
import ViewFundraisingUser from "./view";
import useFundRaisingUser from "hook/useFundRaisingUser";


const FundRaiserUser = () => {
    const { fundraisings, isFundraisingLoading, columns, page, setPage, setData, open, current, setOpen, createFundraisingMutation, formik, currentData, deleteRecordHandler, updateFundraisingMutation, currentView, setCurrentView, selectedCategory, setSelectedCategory, selectedCountry, setSelectedCountry, selectedState, setSelectedState, handleDescriptionChange, fundraisingTypes, MAX_DESCRIPTION, selectedCity, setSelectedCity, charLeft } = useFundRaisingUser()

    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Campaign</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={FORUM_MGT}>Campaign</a></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end mb-2">
                        <button className="btn btn-primary" onClick={() => currentView === 'table' ? setCurrentView('form') : setCurrentView('table')}>{currentView === 'table' ? 'Create Campaign Event' : 'Back'}</button>
                    </div>
                </div>
                {currentView === 'table' && <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        My Campaign
                    </div>
                    <div className="card-body">
                        <EnhancedTable columns={columns} data={fundraisings?.data?.fund_raising?.data || []} setData={setData}
                            skipPageReset={false}
                            lastPage={fundraisings?.data?.total}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isFundraisingLoading} />
                    </div>
                </div>}
                {currentView === 'form' && <div className="card mb-4">
                    <AddFundraisingFormUser createFundraisingMutation={createFundraisingMutation} formik={formik} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedState={selectedState} setSelectedState={setSelectedState} handleDescriptionChange={handleDescriptionChange} MAX_DESCRIPTION={MAX_DESCRIPTION} charLeft={charLeft} fundraisingTypes={fundraisingTypes} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
                </div>}
            </div>

            <Modals show={open && current === 'edit'}
                onHide={() => { setOpen(false); formik.resetForm() }}
                title={current === 'add' ? 'Create Forum' : 'Edit Forum'}
                size='md'>
                {currentData ? <EditFundraisingForm formik={formik} updateFundraisingMutation={updateFundraisingMutation} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedState={selectedState} setSelectedState={setSelectedState} handleDescriptionChange={handleDescriptionChange} MAX_DESCRIPTION={MAX_DESCRIPTION} charLeft={charLeft} fundraisingTypes={fundraisingTypes} selectedCity={selectedCity} setSelectedCity={setSelectedCity} /> : null}
            </Modals>
            <Modals show={open && current === 'view'}
                onHide={() => setOpen(false)} title='View FundRaiser' size='md'>
                <ViewFundraisingUser id={currentData?.id} />
            </Modals>
            <Modals show={open && current === 'delete'}
                onHide={() => setOpen(false)} title='Delete Advert' footer={<button type="button" className="btn btn-primary" onClick={deleteRecordHandler}>Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{currentData.name}</b>?</p>}
                </div>
            </Modals>
        </>
    );
}

export default FundRaiserUser;
