import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import { format } from "date-fns";
import useDonorReport from "hook/reports/useDonorReport";
import { DONOR__REPORT } from "navigation/routes";
import { Link } from "react-router-dom";

const DonorAnalytics = () => {
  const { dummy_data, dummy_line_data } = useDonorReport()
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donor Analytics</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><Link to={`../${DONOR__REPORT}`} relative="path">Donors</Link></li>
              <li className="breadcrumb-item active">Analytics</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONOR__REPORT}`} relative="path">View List</Link>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <i className="fas fa-chart-bar me-1"></i>
                  {format(new Date(), 'yyyy')} Donors by month
                </div>
                <div className="card-body">
                  <BarChart data={dummy_data} />
                </div>
                <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <i className="fas fa-chart-bar me-1"></i>
                  Donors for {format(new Date(), 'MMM yyyy')}
                </div>
                <div className="card-body">
                  <LineChart data={dummy_line_data} />
                </div>
                <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DonorAnalytics
