const Select = ({ id, options, selected, handleChange, className, disabled = false, placeholder = 'Choose...' }) => {
  return (
    <select
      id={id}
      name={id}
      value={JSON.stringify(selected)}
      className={className}
      disabled={disabled}
      onChange={handleChange}
    >
      <option value="0"  >{placeholder}</option>
      {options?.map((option) => (
        <option key={option.name || option.label} value={JSON.stringify(option)}>
          {option.name || option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
