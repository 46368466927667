import React, { useState } from "react";
import { formatMoney, toStartCase } from "utils/functions";
import BarChart from "../../../components/charts/BarChart";
import LineChart from "../../../components/charts/LineChart";
import Table from "../../../components/TableTwo";
import makeData from "../../../utils/makeData";
import useUserActions from "hook/useUserActions";
import { useQuery } from "react-query";
import { format } from "date-fns";
import EnhancedTable from "components/EnhancedTable";

const UserDashboard = () => { 
    const { dashboard,getUserDonations } = useUserActions()
    const [data, setData] = React.useState(React.useMemo(() => makeData('call history', 30), []))
    const [page, setPage] = useState(1)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const columnCalls = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Cause',
                accessor: 'tagline',
            },
            {
                Header: 'Amount',
                id: 'donation',
                accessor: row => formatMoney(row.donation, 'NGN'),
            }
        ],
        []
    )
    const columnMessages = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Subject',
                accessor: 'subject',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Date',
                accessor: 'messagedate'
            }
        ],
        []
    )

    const columns = React.useMemo(
        () => [
            { 
                Header: 'Date',
                id: 'date',
                accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
                filter: 'dateFilter'
              },
              {
                Header: 'Amount',
                id: 'donation',
                accessor: row => formatMoney(row?.amount)
              },
              {
                Header: 'Cycle',
                id: 'donation_cycle',
                accessor: row => toStartCase(row?.donation_cycle) || toStartCase(row?.donation_type),
              },
              {
                Header: 'Payment Purpose',
                accessor:row => row.payment_purpose=="fund_raising"? 'Campaign': row.payment_purpose,
              },
              {
                Header: 'Type',
                accessor:row => row.payment_purpose=="donation"? row.donation_course.tagline: '-',
              },
              {
                Header: 'Campaign',
                accessor:row => row.payment_purpose=="fund_raising"? row.fund_raising_course.name: '-',
              }
              
        ],
        []
    )

    const {  data: dash } = useQuery(['dashboard'], dashboard, {
        select: (data) => data.data,
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })
    
    const { isFetching, isLoading, data: history123 } = useQuery(['donations123', 'history123'], getUserDonations, {
        select: (data) => data.data.user_donations.data,
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })
    
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Dashboard</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">Dashboard</li>
                </ol>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-primary text-white mb-4">
                            <div className="card-header">My Highest Donation
                            </div>
                            <div className="card-body flex flex-column">
                                <span className="small text-white stretched-link">Cause: {dash?.highest?.payment_purpose==='donation' ? dash?.highest?.donation_course?.tagline: dash?.highest?.fund_raising_course?.name}</span>
                                <span className="small text-white stretched-link">Amount:  { dash?.highest==null ? formatMoney(0): formatMoney(dash?.highest?.amount)}</span>
                                <span className="small text-white stretched-link">Date: {format(new Date(dash?.highest ? dash.highest.created_at : Date()), 'MMM dd yyyy')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-success text-white mb-4">
                            <div className="card-header">Total Donation Count</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link"> {dash?.total}</span>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="col-xl-3 col-md-6">
                        <div className="card bg-warning text-white mb-4">
                            <div className="card-header">My Total calls/messages</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Calls: 230</span>
                                <span className="small text-white stretched-link">SMS: 280</span>
                                <span className="small text-white stretched-link">Email: 180</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-area me-1"></i>
                                Donations Per Month
                            </div>
                            <div className="card-body">
                                <LineChart />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-bar me-1"></i>
                                Donation per month
                            </div>
                            <div className="card-body">
                                <BarChart />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Donation History
                    </div>
                    <div className="card-body">
                        <Table
                            columns={columnCalls}
                            data={data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                        />
                    </div>
                </div> */}
                <div className="card mb-4">
                        <div className="card-header">

                            Donation History
                        </div>
                        <div className="card-body">
                            <EnhancedTable columns={columns} data={history123 || []} setData={setData}
                                skipPageReset={false}
                                lastPage={page}
                                currentPage={page}
                                setCurrentPage={setPage}
                                isFetching={isFetching || isLoading} />
                        </div>
                    </div>
                {/* <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Messages History
                    </div>
                    <div className="card-body">
                        <Table
                            columns={columnMessages}
                            data={data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                        />                       
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default UserDashboard;
