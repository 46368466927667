import { City } from 'country-state-city';
import { getCitiesByStateId } from 'hook/state/generic';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Select from '../../innerComponents/Select';

const CitySelect = ({
    selectedState, selected, handleChange, disabled = false, placeholder = 'City' }) => {
    const cities = useRecoilValue(getCitiesByStateId(selectedState?.id));
    let selectedcities = null;
    if (cities && selected) {
        selectedcities = cities.find(item => item.id === selected.id)
    }
    return (
        <>{!disabled ?
            <Select id="city" className="form-select" placeholder={placeholder} selected={selectedcities} handleChange={handleChange} options={cities} disabled={disabled} />
            : <input className="form-control" id="selectedCity" name='selectedCity' type="text" value={selectedcities?.name} disabled={disabled} />}</>
    );
}

export default CitySelect;
