import MainSelect from "components/innerComponents/AsyncSelect/MainSelect";
import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useDonorReport from "hook/reports/useDonorReport";
import { DONOR__ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash' 

const DonorReport = () => {
  const { currentData, setCurrentData, open, setOpen, skipPageReset, columns, data, setData, current, edit, selectedDonor, selectedDonorDetails, isLoading } = useDonorReport()
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Individual Donor Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><a href='#'>Donors</a></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <div className="mb-2 w-25">
            Select Donor to view details
            <MainSelect />
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONOR__ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header flex justify-content-between">
            <div> <i className="fas fa-table me-1"></i>
              Donors</div>
            <div><b>{selectedDonorDetails?.donor_name} - {selectedDonorDetails?.donor_email}</b></div>
          </div>
          <div className="card-body">
            {selectedDonorDetails ? <div className="donor-info">
              <div className="details">
                {selectedDonorDetails?.donor_phone_number && <p>Donor phone: {selectedDonorDetails?.donor_phone_number}</p>}
                <p><b>Donor city:</b> {selectedDonorDetails?.user?.city?.name}</p>
                <p><b>Donor level:</b> {selectedDonorDetails?.user?.category?.name}</p>
              </div>
            </div> : null}
            <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              fileName={`donor-report-${selectedDonor?.label}`}
              filterTable={Boolean(data?.length)}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donation' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default DonorReport
