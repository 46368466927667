import Input from 'components/innerComponents/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react'
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { scrollToErrors } from "utils/functions";

const AddForumForm = ({ categories, isLoadingCategories, selectedCategory, setSelectedCategory, formik, createForumMutation, img, handleFeaturedImageChange }) => {
  return (
    <form onSubmit={(e) => formik.handleSubmit(e)}>
      <div className="modal-body">
        <div className="form-group mb-3">
          <Input
            name='title'
            value={formik?.values?.title}
            type='title'
            placeholder='Title'
            label='Title'
            className='form-group'
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.errors.title && formik.touched.title ? formik.errors.title : null}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="category">Category</label>
          <ReactSelect
            id='category'
            name="categories"
            placeholder='Select categories'
            isSearchable
            isClearable
            isMulti
            noOptionsMessage={() => 'No Category found.'}
            onChange={(e) => {
              setSelectedCategory(e);
              formik.setFieldValue('categories', e?.map(item => item.id))
            }}
            value={selectedCategory}
            options={categories}
            isDisabled={isLoadingCategories}
            isLoading={isLoadingCategories}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => option['id']}
          />
          {formik.errors.categories && formik.touched.categories && <small className="error">{formik.errors.categories} </small>}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="meta">Description</label>
          <textarea className="form-control" id="meta" name='meta'
            placeholder="description" onChange={formik.handleChange} />
          {formik.errors.meta && formik.touched.meta && <small className="error">{formik.errors.meta} </small>}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="body">Body</label>
          <textarea className="form-control" id="body" name='body' rows={3}
            placeholder="Body" onChange={formik.handleChange} />
          {formik.errors.body && formik.touched.body && <small className="error">{formik.errors.body} </small>}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="tags">Tags</label>
          <CreatableSelect name="tags"
            isMulti
            placeholder='Type to create new tags'
            options={[{ name: 'education' }, { name: 'techie' }]}
            isSearchable
            isClearable
            onChange={(e) => {
              formik.setFieldValue('tags', e?.map(item => item?.name?.toLowerCase()))
            }}
            noOptionsMessage={() => 'type and enter to create a tag'}
            getOptionLabel={(option) => `${option.name || option.label}`}
            getOptionValue={(option) => `${option.name || option.label}`} />
        </div>
        <div className="mb-3">
          <label htmlFor="media">Media</label>
          <input type="file" className="form-control" id="media" aria-describedby="media" aria-label="Upload" name="featured_image" onChange={(e) => {
            handleFeaturedImageChange(e);
            formik.setFieldValue("featured_image", e.target.files[0])
          }} />
          {formik.errors.featured_image && formik.touched.featured_image && <small className="error">{formik.errors.featured_image} </small>}

          <small>Only images of type png, jpeg, jpg, svg, webp and max size of 5mb are accepted</small>
          {img && <div className="mt-2 w-100">
            <img src={img} alt="" className="mw-100 rounded" />
          </div>}
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" onClick={() => scrollToErrors(formik.errors)} className="btn btn-primary" disabled={createForumMutation.isLoading}>{createForumMutation.isLoading && <LoadingIndicator />} Create</button>
      </div>
    </form>
  )
}

export default AddForumForm
