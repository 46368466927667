import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import { ONE_OFF_ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import useRecycleReportCampaign from "hook/reports/useRecycleReportCampaign";
import Select from "components/innerComponents/Select";
import MembershipEnhancedTable from "components/MembershipEnhancedTable";
import ButtonWithTips from "components/ButtonWithTips";

const RecycleReportCampaign = () => {
  const { currentData, setCurrentData, open, setOpen, updateMyData,
    setSearch,setSearchCycle,dataRecycle, isLoading2,page,setPage,setTo,setFrom,
    skipPageReset, columns, data, setData, current, edit, setEdit, isLoading } = useRecycleReportCampaign()
  const donation_cycles = [
    { id: 1, value: 'monthly', label: 'Monthly' },
    { id: 2, value: 'quarterly', label: 'Quarterly' },
    { id: 3, value: 'yearly', label: 'Yearly' },
    { id: 4, value: 'one-off', label: 'One-off' },

  ] 
  
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Recycle Campaign Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item">Recycle Campaigns</li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          {/* <Link className="btn btn-primary mb-2" to={`../${ONE_OFF_ANALYTICS}`} relative="path">View Analytics</Link> */}
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Recycle Campaigns
          </div>
          <div className="row">
          <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                // debugger
                // var rt=dataRecycle
                setSearch(e.target.value);
              }}
              />
            </div>
            
            <div className="col-2" style={{width:'20%'}}>
            
            <Select
                className="form-select"
                // selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  var id=JSON.parse(e.target.value)?.value
                  setSearchCycle(id);
               }}
                
                options={donation_cycles}
              />
            </div>
            <div className="col-3"> 
            <div className="filterParameters" id="filterParameters">
        <div className="datePickerWrapper">
          <div className="d-sm-flex align-items-end gap-2">
            <div className="form-inline">From:
              <input type='date' className="form-control" onChange={(e) => {
                setFrom(e.target.value);
              }}  />
            </div>
            <div className="form-inline">To:
              <input type='date' className="form-control" onChange={(e) => {
                setTo(e.target.value);
              }}/>
            </div>
            {/* <button className='btn btn-primary'><i className="fa fa-refresh"></i></button> */}
            <ButtonWithTips
                id="getExcel"
                tips="Click to download data in excel format"
                btnProps={{
                  className: 'btn btn-success',
                  // onClick: getExcel,
                }}
              >
                <i className="fas fa-file-excel"></i>
              </ButtonWithTips>
          </div>

        </div>
      </div>
            </div>
          </div>
          <div className="card-body">
          <MembershipEnhancedTable
              columns={columns}
              data={dataRecycle?.data?.data?.fund_raising.data || []}
              setData={setData}
              skipPageReset={skipPageReset} 
              lastPage={dataRecycle?.data?.data?.fund_raising.last_page}
              currentPage={dataRecycle?.data?.data?.fund_raising.current_page}
              setCurrentPage={setPage}
               isFetching={isLoading}
            />
            {/* <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              isLoading={isLoading}
              fileName='recycle-campaign-reports'
            /> */}
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donation' size='md'>
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default RecycleReportCampaign
