const Interests = () => {
    return (

        <section className="page-section bg-grey-2" id="interests">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h1 className="section-title mt-0">
                            What is your Interest?
                            <span className="subtitle">
                                ActionAid works with various stakeholders from various sectors in providing support and help. Our MILMAP benefactors can select which of these sectors interests them.
                            </span>
                        </h1>
                        <hr className="divider" />
                    </div>
                    <div className="row gx-4 gx-lg-5 sectors-wrap mt-5">
                        <div className="col-lg-3 col-md-6 text-center interest">
                            <div className="mt-5 interest-wrap">
                                <div className="mb-2"><i className="bi bi-mortarboard fs-0 text-primary"></i></div>
                                <h4 className="mb-3">Education</h4>
                                <p className="text-muted mb-0">We believe Education – especially that of girls and women – saves lives, reduces inequality and...</p>
                                <div className="hover-canvas" style={{ backgroundImage: "url(./img/interests/education.jpg)" }}>
                                    <div className="hover-content">
                                        <h3 className="interest-title text-white">Education</h3>
                                        <div className="description text-white">
                                            <p>ActionAid believes, Education – especially that of girls and women – saves lives, reduces inequality and transforms communities.</p>
                                            <p>10.5million children are out of school in Nigeria. It is estimated that 35 per cent of Nigerian children who attend primary school do not go on to attend secondary school. Half of all Nigerian children did not attend secondary school in 2021. </p>
                                        </div>
                                        <div className="mt-4"></div>
                                        <a className="text-link" href="#" target="_blank">Join movement to change this statistics</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center interest">
                            <div className="mt-5 interest-wrap">
                                <div className="mb-2"><i className="bi bi-gender-female fs-0 text-primary"></i></div>
                                <h4 className="mb-3">Women's Rights</h4>
                                <p className="text-muted mb-0">Women all around the world are more likely to live in poverty - just because they are women. </p>
                                <div className="hover-canvas" style={{ backgroundImage: "url(./img/interests/african-woman.jpg)" }}>
                                    <div className="hover-content">
                                        <h3 className="interest-title text-white">Women's Rights</h3>
                                        <div className="description text-white">
                                            <p>ActionAid Nigeria puts women and women’s rights at the centre of all our work because we believe this inequality is an injustice we must fight and we believe that gender is critical to understanding the causes of poverty and injustice.</p>
                                            <p>We work with women across Nigeria to identify the <strong>changes they want to see and to empower them to claim their rights</strong>.</p>
                                        </div>
                                        <div className="mt-4"></div>
                                        <a className="text-link" href="#" target="_blank">Help forge a gender-equal Nigeria!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center interest">
                            <div className="mt-5 interest-wrap">
                                <div className="mb-2"><i className="bi bi-hospital fs-0 text-primary"></i></div>
                                <h4 className="mb-3">Emergency Response</h4>
                                <p className="text-muted mb-0">When disaster strikes, ActionAid Nigeria responds within hours, providing vital things like food...</p>
                                <div className="hover-canvas" style={{ backgroundImage: "url(./img/interests/disaster.jpg)" }}>
                                    <div className="hover-content">
                                        <h3 className="interest-title text-white">Emergency Response</h3>
                                        <div className="description text-white">
                                            <p>When disaster strikes, ActionAid Nigeria responds within hours, providing vital things like food and shelter. </p>
                                            <p>We link our response to our ongoing projects, and we stick around for as long as we are needed, providing practical support, and making sure local people have a say in rebuilding their communities and livelihoods.</p>
                                        </div>
                                        <div className="mt-4"></div>
                                        <a className="text-link" href="#" target="_blank">Take action to save a life</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center interest">
                            <div className="mt-5 interest-wrap">
                                <div className="mb-2"><i className="bi bi-egg-fried fs-0 text-primary"></i></div>
                                <h4 className="mb-3">Food and Land...</h4>
                                <p className="text-muted mb-0">We are tackling the root causes of hunger and supporting the poorest and most marginalised...</p>
                                <div className="hover-canvas" style={{ backgroundImage: "url(./img/interests/food-land-rights-2.jpg)" }}>
                                    <div className="hover-content">
                                        <h3 className="interest-title text-white">Food and Land Rights</h3>
                                        <div className="description text-white">
                                            <p>Land and water are the key to providing food, decent livelihoods and a sustainable future for everybody. Yet environmental pressures and increasing competition for these resources means that rural communities the world over are being forced off their land. </p>
                                            <p>ActionAid Nigeria stands up for rural women and smallholder farmers by helping them <strong>hold governments, international organisations and corporations to account</strong>.</p>
                                        </div>

                                        <a className="text-link" href="#" target="_blank">Survival Gifts make real Impact. Join the Movement! </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </section >
    );
}

export default Interests;
