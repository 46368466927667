import toast, { Toaster, ToastBar } from 'react-hot-toast';
import ButtonWithTips from './ButtonWithTips';

export const notify = (message, options) => {
    options = options || {};
    options.type = options.type || 'custom';
    options.position = 'top-right';

    return toast[options.type](message, options);
};

const Alerts = (props) => {
    const { callback, callbackLabel, alertRef } = props;
    return (
        <Toaster {...props}>
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <div
                            aria-live="assertive"
                            className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
                        >
                            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="p-4">
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0">{icon}</div>
                                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                                <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                                                    {message}
                                                </p>
                                                {callback && (
                                                    <ButtonWithTips
                                                        id="callbackLabel"
                                                        tips="Toggle featured status"
                                                        btnProps={{
                                                            className:
                                                                'ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                                                            type: 'button',
                                                        }}
                                                    ></ButtonWithTips>
                                                )}
                                            </div>
                                            <div className="ml-4 flex-shrink-0 flex">
                                                <ButtonWithTips
                                                    id="callbackLabel"
                                                    tips="Toggle featured status"
                                                    btnProps={{
                                                        type: 'button',
                                                        className: 'bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                                                        onClick: () => toast.remove(alertRef),
                                                    }}
                                                >
                                                    <span className="sr-only">Close</span>
                                                </ButtonWithTips>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
};

export default Alerts;
