import { useLayoutEffect, useRef, useState } from "react";
import useClickOutside from "../../utils/useClickOutside";
import Sidebar from "./Sidebar";

const AppLayout = ({ children }) => {
    const navRef = useRef(null)
    const [clicked, setClicked] = useState(false)
    useLayoutEffect(() => {
        if (clicked) {
            document.body.classList.toggle('sb-sidenav-toggled');
        } else {
            document.body.classList.remove('sb-sidenav-toggled');
        }
    }, [clicked])

    const clickOutsidehandler = () => {
        if (clicked) {
            setClicked(false);
        }
    };
    useClickOutside(navRef, clickOutsidehandler);
    return (

        <>
            <div id="layoutSidenav">

                <div ref={navRef}><Sidebar clicked={clicked} setClicked={setClicked} /></div>
                <div id="layoutSidenav_content">
                    <main>
                        {children}
                    </main>
                </div>
            </div>
        </>
    );
}

export default AppLayout;
