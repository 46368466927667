import React from 'react'
import PropTypes from 'prop-types'
import ToolTips from './ToolTips';

const ButtonWithTips = ({ id, tips, children, btnProps }) => {
  return (
    <ToolTips id={id} tooltip={tips}>
      <button {...btnProps}>{children}</button>
    </ToolTips>
  )
}

ButtonWithTips.propTypes = {
  id: PropTypes.string,
  tips: PropTypes.string,
  btnProps: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default ButtonWithTips
