const Footer = () => {
    return (
        <footer className="bg-dark">
            <div className="container px-4 px-lg-5">
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget text-widget">
                            <div className="title text-white">
                                <h4>ActionAid Nigeria</h4>
                            </div>
                            <div className="widget-content text-white">
                                <p>ActionAid Nigeria is a member of the ActionAid global federation. We maintain a strong relationship of interdependence and mutual accountability within the international federation, ensuring a strong balance between self-rule and shared-rule.</p>
                            </div>
                            <div className="mt-4">
                                <a className="text-link" href="#who-we-are">Learn more</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget social-icons">
                            <div className="title text-white">
                                <h4>Join The Conversation</h4>
                            </div>
                            <ul className="menu-list">
                                <li className="item">
                                    <p><a href="https://web.facebook.com/ActionaidCommunitySponsorship" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i>  Community Sponsorship</a></p>
                                </li>
                                <li className="item">
                                    <p><a href="https://www.linkedin.com/in/communitysponsorship/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i>  Community Sponsorship</a></p>
                                </li>
                                <li className="item">
                                    <p><a href="https://twitter.com/ActionAidNG" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i>  ActionAid NG</a></p>
                                </li>
                                <li className="item">
                                    <p><a href="https://www.instagram.com/actionaidnigeria/" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i>  ActionAid NG</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget menu">
                            <div className="title text-white">
                                <h4>Get Involved</h4>
                            </div>
                            <ul className="menu-list">
                                <li className="item"><p><a href="https://actionaid-ngr.org/sponsor-a-project/" target="_blank" rel="noreferrer">Sponsor a Project</a></p></li>
                                <li className="item"><p><a href="https://actionaid-ngr.org/donate/" target="_blank" rel="noreferrer">Fundraise</a></p></li>
                                <li className="item"><p><a href="http://actionaid-ngr.org/become-a-supporter/" target="_blank" rel="noreferrer">Become a Supporter</a></p></li>
                                <li className="item"><p><a href="https://actionaid-ngr.org/payroll-giving/" target="_blank" rel="noreferrer">Payroll Giving</a></p></li>
                                <li className="item"><p><a href="https://actionaid-ngr.org/corporate-social-responsibilities/" target="_blank" rel="noreferrer">CSR</a></p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="widget text-widget">
                            <div className="title text-white">
                                <h4>Community Sponsorship</h4>
                            </div>
                            <div className="widget-content text-white">
                                {/* <p>Community Sponsorship© is a voluntary individual giving platform where you make regular (monthly/annual) donations in a structured way in support of ActionAid’s work of lifting the most impoverished and excluded communities across the country out of poverty.</p> */}
                                <p>ActionAid Community Sponsorship Initiative is a standardized form of regular voluntary (individual or organization) giving towards community development in Nigeria. This gives individuals and organizations a platform to impact real lives and give back to society, to support and empower the most vulnerable and excluded people in extremely poor communities across Nigeria, with little or no access to government infrastructure and services.</p>
                                <p>Community Sponsorship focuses on people living in extremely poor and vulnerable communities which include out-of-school children, women who need basic maternal health care, poor women farmers, and the most vulnerable in emergency and conflict situations, amongst others.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="copyright small text-center">Copyright &copy; {new Date().getFullYear()} - <span>ActionAid Nigeria Community Sponsorship Initiative</span></div>
            </div>
        </footer>
    );
}

export default Footer;
