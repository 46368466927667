import useDonation from "hook/useDonation";
import { Link } from "react-router-dom";

const Patriots = () => {

    const { patriot} = useDonation();
    
    return (

        <section className="page-section pb-0" id="donors">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h1 className="section-title mt-0">
                            MILMAP Patriots
                            <span className="subtitle">
                                MILMAP Patriots are regular or one-off donors committed to making an impact and helping put smiles on the faces of the poor, the discriminated and the ignored.
                            </span>
                        </h1>
                        <hr className="divider" />
                    </div>
                    <div className="row gx-4 gx-lg-5 generals-wrap mt-5">
                    {patriot?.slice(0, 2).map((data) => (
                            <div className="col-lg-4 col-md-6 text-center">
                            <div className="donor">
                                <figure>
                                    <img src={data.profile_picture} alt={data.full_name} />
                                    <figcaption>
                                        <h5>{data.full_name}</h5>
                                        <p>{data.profile}</p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                          ))}
                        
                        <div className="action-wrap mt-5 text-center">
                            <Link to="/patriot" className='btn btn-hero-alt' >
                                See More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta-wrap text-center">
                <div className="container">
                    <div className="cta-content text-white">
                        <h4>Be a part of <span className="mm-red">ActionAid</span> Nigeria’s Community Sponsorship Initiative and help us in supporting the poor, the discriminated and the ignored in our communities.</h4>
                        <a className="btn btn-hero-alt" href="https://actionaid-ngr.org/donate/" target="_blank">Donate Now</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Patriots;
