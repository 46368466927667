import { notify } from "components/Alerts";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthLayout from "../../../components/innerComponents/AuthLayout";
import { useMutation } from 'react-query'
import useAuth from "hook/useAuth";
import LoadingIndicator from "components/LoadingIndicator";
import { ADMIN_SIGNIN, SIGNIN } from "navigation/routes";

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const location = useLocation()
    const routeCheck = location.pathname.includes('admin')
    const { forgotPasswordAdmin, forgotPasswordUser } = useAuth()
    const { mutate, isLoading } = useMutation(routeCheck ? forgotPasswordAdmin : forgotPasswordUser, {
        onSuccess: (data) => {
            setEmail(null)
            notify(data.message, { type: 'success' })
        },
        onError: (error) => {
            notify(error, { type: 'error' })
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        mutate({ email })
    }
    return (
        <AuthLayout>
            <div className="card shadow-lg border-0 rounded-lg mt-5 mb-5">
                <div className="card-header bg-red text-white"><h3
                    className="text-center font-weight-light my-4">Password Recovery</h3>
                </div>
                <div className="card-body">
                    <div className="small mb-3 text-muted">Enter your email address and we
                        will send you a link to reset your password.
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <input className="form-control" id="inputEmail" type="email"
                                placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <label htmlFor="inputEmail">Email address</label>
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <Link className="small" to={routeCheck ? ADMIN_SIGNIN : SIGNIN}>Return to login</Link>
                            <button className="btn btn-primary" type='submit' disabled={isLoading || !email}>{isLoading && <LoadingIndicator />} Forgot Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </AuthLayout>
    )
}
export default ForgotPassword;
