import { notify } from "components/Alerts";
import { userSelector } from "hook/state/user";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ProfilePicture from "../../../components/innerComponents/Profile/ProfilePicture";
import UpdateProfile from "../../../components/innerComponents/Profile/UpdateProfile";

const Profile = () => {
    const [edit, setEdit] = useState(false)
    const userDetails = useRecoilValue(userSelector)
    const linkRef = useRef(null)
    const origin = window.location.origin;
    const copyToClipboard = () => {
        if (linkRef.current) {
            navigator.clipboard.writeText(linkRef.current.innerText)
            notify('Link coppied successfully', { type: 'success' })
        }
    }
    return (
        <div className="growth">
            <div className="container px-4">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="card mb-4 rounded-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="card card-body d-flex flex-col text-center">
                                            <h2>Referral Link</h2>
                                            <span>Click the button below to copy your referral link to clipboard</span>
                                            <div><span className="btn btn-primary" ref={linkRef} onClick={copyToClipboard}>{`${origin}/signup/${userDetails?.unique_id}`}</span></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">

                                        <ProfilePicture edit={edit} setEdit={setEdit} user={userDetails} />
                                    </div>
                                    <div className="col-lg-8">

                                        <UpdateProfile edit={edit} setEdit={setEdit} user={userDetails} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
