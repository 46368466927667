const CommingSoon = () => {
    return (
        <div className="growth flex align-items-center justify-content-center">
            <div className="card mb-4 empty-card">
                <div className="card-body flex flex-column align-items-center justify-content-center">
                    <div><i className="fas fa-folder-open empty-icon"></i></div>
                    Coming soon, page in progress
                </div>
            </div>
        </div>
    );
}

export default CommingSoon;
