import { notify } from "components/Alerts"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import useAuth from "./useAuth"

const useSignOnFee = () => {
  const { paySignOnFee } = useAuth()
  return useMutation(paySignOnFee, {
    onSuccess: data => {
      notify(data.message, { type: 'success' })
      window.open(data.data.authorization_url, '_blank', 'noopener,noreferrer');
    },
    onError: error => {
      notify(error.toString(), { type: 'error' })
    }
  })
}

export default useSignOnFee;
