import BarChart from "../../../components/charts/BarChart";
import LineChart from "../../../components/charts/LineChart";
import PieChart from "../../../components/charts/PieChart";

const Reports = () => {
    return (
        <div>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Reports</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                            <li className="breadcrumb-item active">Reports</li>
                        </ol>
                    </div>
                </div>
                <div className="card mb-4 h-50">
                    <div className="card-header">
                        <i className="fas fa-chart-area me-1"></i>
                        Donation Report
                    </div>
                    <div className="card-body">
                        <LineChart />
                    </div>
                    <div className="card-footer small text-muted">Updated yesterday at 11:59 PM</div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-bar me-1"></i>
                                Fundraiser Report
                            </div>
                            <div className="card-body">
                                <BarChart />
                            </div>
                            <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-pie me-1"></i>
                                Activity report
                            </div>
                            <div className="card-body">
                                <PieChart />
                            </div>
                            <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reports;
