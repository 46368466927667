import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useDonationCategoryReport from "hook/reports/useDonationCategoryReport";
import { DONATION__CATEGORY_ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import ButtonWithTips from "components/ButtonWithTips";
import SelectBox from "components/common/Selects/SelectBox";
import MembershipEnhancedTable from "components/MembershipEnhancedTable";

const CategoryReport = () => {
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData,
    setFrom, setTo,setSearch,page,setPage,setLevel,dataCategory,loading,setLoading,getExcel,
    current, edit, setEdit, isLoading } = useDonationCategoryReport()

    const user_level = [      
      { id: '', value: 'All', label: 'All' },
      { id: 1, value: 'Member', label: 'Member' },
      { id: 2, value: 'Champion', label: 'Champion' },
      { id: 3, value: 'Patriot', label: 'Patriot' }
      
    ]; 
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donation by Category Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><Link to='#'>Donations by Category</Link></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONATION__CATEGORY_ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donation by Categorys
          </div>
          <div className="row">
          <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setLoading(true)
                setSearch(e.target.value);
              }}
              />
            </div>
          
            <div className="col-3" style={{width:'20%'}}>
            <SelectBox
                    selectName="donation_cycle"
                    selectLabel={`Donation cycle`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={user_level}
                    onChange={(e) => {
                      setLoading(true)
                       setLevel(e.id);
                    }}
                    selectOption={`Select Category`}
                   
                  />
            
            </div>
            <div className="col-3"> 
            <div className="filterParameters" id="filterParameters">
        <div className="datePickerWrapper">
          <div className="d-sm-flex align-items-end gap-2">
            <div className="form-inline">From:
              <input type='date' className="form-control" onChange={(e) => {
                setLoading(true)
                setFrom(e.target.value);
              }}  />
            </div>
            <div className="form-inline">To:
              <input type='date' className="form-control" onChange={(e) => {
                setLoading(true)
                setTo(e.target.value);
              }}/>
            </div>
            {/* <button className='btn btn-primary'><i className="fa fa-refresh"></i></button> */}
            <ButtonWithTips
                id="getExcel"
                tips="Click to download data in excel format"
                btnProps={{
                  className: 'btn btn-success',
                  onClick: getExcel,
                }}
              >
                <i className="fas fa-file-excel"></i>
              </ButtonWithTips>
          </div>

        </div>
      </div>
            </div>
          </div> 
          <div className="card-body">
          <MembershipEnhancedTable
              columns={columns}
              data={dataCategory?.data?.data?.donation_report.data || []}
              setData={setData}
              skipPageReset={skipPageReset} 
              lastPage={dataCategory?.data?.data?.donation_report.last_page}
              currentPage={dataCategory?.data?.data?.donation_report.current_page}
              setCurrentPage={setPage}
               isFetching={loading}
            />
          
            {/* <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              isLoading={isLoading}
              fileName='donation-by-category-reports'
            /> */}
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donation' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default CategoryReport
