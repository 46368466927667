import React from 'react'

const Input = (props) => {
  return (
    <div className="mb-3">
      <div className={!props.className && props.label ? "form-floating" : props.className}>
        {props.className && props.label && <label htmlFor={props.name}>{props.label}</label>}
        <input className="form-control" id={props.name} name={props.name} type={props.type}
          placeholder={props.placeholder} value={props.value} onChange={props.handleChange} onBlur={props.handleBlur} disabled={props.disabled || false} />
        {!props.className && props.label && <label htmlFor={props.name}>{props.label}{props.required ? '*' : ''}</label>}
      </div>
      {props.error && <small className="error">{props.error} </small>}
    </div>
  )
}

export default Input
