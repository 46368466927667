import EnhancedTable from "components/EnhancedTable";
import { format } from "date-fns";
import useUserActions from "hook/useUserActions";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { formatMoney, toStartCase } from "utils/functions";
import BarChart from "../../../components/charts/BarChart";
import LineChart from "../../../components/charts/LineChart";
import Table from "../../../components/Table";
import { DONATE } from "../../../navigation/routes";
const DonationHistory = () => {
    const [currentView, setCurrentView] = useState('list')
    const [, setData] = useState(null)
    const [page, setPage] = useState(1)
    const { getUserDonations } = useUserActions()
    const { isFetching, isLoading, data: history } = useQuery(['donations', 'history'], getUserDonations, {
        select: (data) => data.data.user_donations.data,
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })

    const columnsOld = useMemo(
        () => [
            {
                Header: 'Date',
                id: 'data',
                accessor: row => format(new Date(row.created_at), 'yyyy/MM/dd')
            },
            {
                Header: 'Cause',
                id: 'cause',
                accessor: row => row.payment_purpose
            },
            {
                Header: 'Amount',
                id: 'amount',
                accessor: row => formatMoney(row.amount, row.currency)
            },
        ],
        []
    )
    const columns = useMemo(
        () => [
            { 
                Header: 'Date',
                id: 'date',
                accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
                filter: 'dateFilter'
              },
              {
                Header: 'Amount',
                id: 'donation',
                accessor: row => formatMoney(row?.amount)
              },
              {
                Header: 'Cycle',
                id: 'donation_cycle',
                accessor: row => toStartCase(row?.donation_cycle) || toStartCase(row?.donation_type),
              },
              {
                Header: 'Payment Purpose',
                accessor:row => row.payment_purpose=="fund_raising"? 'Campaign': row.payment_purpose,
              },
              {
                Header: 'Type',
                accessor:row => row.payment_purpose=="donation"? row.donation_course.tagline: '-',
              },
              {
                Header: 'Campaign',
                accessor:row => row.payment_purpose=="fund_raising"? row.fund_raising_course.name: '-',
              }
              
        ],
        []
    )
    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Donation</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><span role={'button'} onClick={() => setCurrentView('list')}>Donation</span></li>
                            <li className="breadcrumb-item active">{currentView === 'list' ? 'All' : 'Analytics'}</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-sm-end gap-2 mb-2">
                        <a className="btn btn-primary me-2" href={DONATE}>Donate</a>
                        <button className="btn btn-secondary" onClick={() => currentView === 'list' ? setCurrentView('analytics') : setCurrentView('list')}>{currentView === 'list' ? 'View Analylytics' : 'View List'}</button>
                    </div>
                </div>
                {currentView === 'list' ?
                    <div className="card mb-4">
                        <div className="card-header">

                            Donation History
                        </div>
                        <div className="card-body">
                            <EnhancedTable columns={columns} data={history || []} setData={setData}
                                skipPageReset={false}
                                lastPage={page}
                                currentPage={page}
                                setCurrentPage={setPage}
                                isFetching={isFetching || isLoading} />
                        </div>
                    </div> :
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <i className="fas fa-chart-bar me-1"></i>
                                        Eradicate poverty
                                    </div>
                                    <div className="card-body">
                                        <BarChart />
                                    </div>
                                    <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <i className="fas fa-chart-bar me-1"></i>
                                        Educate the girl child
                                    </div>
                                    <div className="card-body">
                                        <LineChart />
                                    </div>
                                    <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    );
}

export default DonationHistory;
