import React from "react";
import Modals from "components/Modal";
import { DASHBOARD } from "navigation/routes";
import EnhancedTable from "components/EnhancedTable";
import LoadingIndicator from "components/LoadingIndicator";
import { scrollToErrors } from "utils/functions";
import useUtilities from "hook/useUtilities";
import Input from "components/innerComponents/Input";

const ForumCategories = () => {

  const { data, isLoading, columns, isFetching, page, setPage, setData, open, current, setOpen, createMutation, formik, setCurrent, currentData, deleteMutation, setCurrentData, deleteRecordHandler, updateMutation
  } = useUtilities()

  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Forum Categories</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><a href={DASHBOARD}>dashboard</a></li>
              <li className="breadcrumb-item active">categories</li>
            </ol>
          </div>
          <button className="btn btn-primary mb-2" onClick={() => {
            setCurrent('add')
            setCurrentData(null)
            setOpen(true)

          }}>Create A Category</button>
        </div>


        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Categories
          </div>
          <div className="card-body">
            <EnhancedTable
              columns={columns}
              data={data?.data?.categories?.data || []}
              setData={setData}
              skipPageReset={false}
              lastPage={data?.data?.categories?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={isFetching || isLoading}
            />
          </div>
        </div>
      </div>

      <Modals show={open && current === 'add'}
        onHide={() => { setOpen(false); formik.resetForm() }} size={'md'} title='Create new Category'>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mb-3">
            <Input
              name='name'
              value={formik.values.name}
              type='name'
              placeholder='Name'
              label='Name'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.name && formik.touched.name ? formik.errors.name : null}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="description">Description</label>
            <textarea className="form-control" id="description" name='description'
              placeholder="description" onChange={formik.handleChange} />
            {formik.errors.description && formik.touched.description && <small className="error">{formik.errors.description} </small>}
          </div>
          <div className="flex justify-content-end"><button type="submit" className="btn btn-primary" disabled={createMutation.isLoading} onClick={() => scrollToErrors(formik.errors)}>{createMutation.isLoading && <LoadingIndicator />} Create</button></div>
        </form>
      </Modals>

      <Modals show={open && current === 'edit'}
        onHide={() => { setOpen(false); formik.resetForm() }} size={'md'} title='Update Category'>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mb-3">
            <Input
              name='name'
              value={formik.values.name}
              type='name'
              placeholder='Name'
              label='Name'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.name && formik.touched.name ? formik.errors.name : null}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="description">Description</label>
            <textarea className="form-control" id="description" name='description'
              placeholder="description" onChange={formik.handleChange} value={formik.values.description} />
            {formik.errors.description && formik.touched.description && <small className="error">{formik.errors.description} </small>}
          </div>
          <div className="flex justify-content-end"><button type="submit" className="btn btn-primary" disabled={updateMutation.isLoading} onClick={() => scrollToErrors(formik.errors)}>{updateMutation.isLoading && <LoadingIndicator />} Update</button></div>
        </form>
      </Modals>
      <Modals show={open && current === 'delete'}
        onHide={() => setOpen(false)} title='Delete Roles' footer={<button type="button" className="btn btn-primary" onClick={() => deleteRecordHandler({ id: currentData?.id })} disabled={deleteMutation.isLoading}>{deleteMutation.isLoading && <LoadingIndicator />} Delete</button>}>
        <div className="striped">
          {currentData && <p>Are you sure you want to delete <b>{currentData.name}</b>?</p>}
        </div>
      </Modals>

    </>
  );
}

export default ForumCategories;
