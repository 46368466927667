import React from "react";
import Modals from "components/Modal";
import { DASHBOARD } from "navigation/routes";
import ReactSelect from "react-select";
import classNames from "utils/classNames";
import EnhancedTable from "components/EnhancedTable";
import { format } from "date-fns";
import LoadingIndicator from "components/LoadingIndicator";
import { startCase } from 'lodash'
import { getNames, scrollToErrors } from "utils/functions";
import useRoleManagement from "hook/useRoleManagement";

const Roles = () => {

    const { formik, getDefaultValues, updateMyData, columns, open, setOpen, roles, edit, setEdit, current, setCurrent, currentData, setCurrentData, skipPageReset, page, setPage, data, setData, updateRoleLoading, handleUpdatePermissions, handleChangePermissions, deleteRoleMutation, deleteRoleLoading, singleRole, allRoles, isDataLoading, isFetching, isLoading, options,
    } = useRoleManagement()
    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Roles Management</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={DASHBOARD}>dashboard</a></li>
                            <li className="breadcrumb-item active">roles</li>
                        </ol>
                    </div>
                    
                    <button className="btn btn-primary mb-2" onClick={() => {
                        setCurrent('add')
                        setOpen(true)
                    }}>Create Role</button>
                </div>


                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Roles
                    </div>
                    <div className="card-body">
                        <EnhancedTable
                            columns={columns}
                            data={allRoles?.data?.roles?.data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                            lastPage={allRoles?.data?.roles?.last_page}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isFetching || isDataLoading}
                        />
                    </div>
                </div>
            </div>

            <Modals show={open && current === 'add'}
                onHide={() => setOpen(false)} size={'md'} title='Create new Roles'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-3">
                        <div className="form-floating">
                            <input className="form-control" id="topic" name='name' type="text"
                                placeholder="My new topic" value={formik.values.name} onChange={formik.handleChange} />
                            <label htmlFor="topic">Title</label>
                        </div>
                        {formik.errors.name && formik.touched.name && <small className={classNames('error', 'col-sm-7 offset-sm-4')}>{formik.errors.name}</small>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="tags">Permissions</label>
                        <ReactSelect
                            isMulti
                            name="permissions"
                            onChange={handleChangePermissions}
                            options={options}
                            className="no-padding"
                            classNamePrefix="form-select"
                        />
                        {formik.errors.permissions && formik.touched.permissions && <small className={classNames('error', 'col-sm-7 offset-sm-4')}>{formik.errors.permissions}</small>}
                    </div>
                    <div className="flex justify-content-end"><button type="submit" className="btn btn-primary" disabled={isLoading} onClick={() => scrollToErrors(formik.errors)}>{isLoading && <LoadingIndicator />} Create</button></div>
                </form>
            </Modals>
            <Modals show={open && current === 'view' && singleRole}
                onHide={() => { setEdit(false); setOpen(false) }} title='View Roles' size='md' footer={currentData?.id !== 1 ? <button type="button" className="btn btn-primary" onClick={() => {
                    if (edit) {
                        setEdit(false)
                        updateMyData()
                    } else {
                        setEdit(true)
                    }
                }}>{updateRoleLoading && <LoadingIndicator />}{edit ? 'Update' : 'Edit'}</button> : null}>
                <dl className="striped">
                    {singleRole && Object.entries(singleRole)?.map(([key, value]) =>
                        key === 'id' || key === 'guard_name' ? [] : key === 'permissions' ? <div className="row">
                            <dt className='col-4'>{`${startCase(key)}: `}</dt>
                            {!edit ? <dd className='col-8'>
                                {getNames(value)}
                            </dd> :
                                <ReactSelect
                                    isMulti
                                    defaultValue={getDefaultValues(value)}
                                    name="permissions"
                                    onChange={(handleUpdatePermissions)}
                                    options={options}
                                    className="col-8 offset-4 no-padding"
                                    classNamePrefix="form-select"
                                />
                            }
                        </div> : key === 'updated_at' || key === 'created_at' ? <div key={key} className='row'>
                            <dt className='col-4'>{`${startCase(key)} :`}</dt>
                            <dd className='col-8'>{format(new Date(value), 'yyyy/MM/dd')}</dd>
                        </div> :
                            <div key={key} className='row'>
                                <dt className='col-4'>{`${startCase(key)} :`}</dt>
                                {!edit && <dd className='col-8'>{value}</dd>}
                                {edit && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
                            </div>)}
                </dl>
            </Modals>
            <Modals show={open && current === 'delete'}
                onHide={() => setOpen(false)} title='Delete Roles' footer={<button type="button" className="btn btn-primary" onClick={() => deleteRoleMutation(currentData?.id)} disabled={deleteRoleLoading}>{deleteRoleLoading && <LoadingIndicator />} Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{currentData.name}</b>?</p>}
                </div>
            </Modals>

        </>
    );
}

export default Roles;
