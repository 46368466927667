import { useEffect, useState } from "react";
import Table from "../../../components/Table";
import useUserActions from "hook/useUserActions";
import ReactTimeago from "react-timeago";



const Notifications = () => {
    const [notification, setNotification] = useState([]);
    const { getUserNotificationsOnly } = useUserActions()
    useEffect(() => {
        getUserNotificationsOnly().then(data=>{
            setNotification(data.data.notifications)
        });
      },[]);

    return (
        <div className="growth">
            <div className="container px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Notifications</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                            <li className="breadcrumb-item active">Notifications</li>
                        </ol>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Notifications
                    </div>
                    <div className="card-body">
                        <div className="row gy-4">
                        {
                                    notification.length > 0
                                    ?
                                    notification?.map((prd, idx) =>
                            <div className="col-sm-6 col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <strong className="mr-auto">{prd.title}</strong>
                                            <small><ReactTimeago date={prd.created_at} /></small>
                                        </div>
                                        <div className="toast-body">
                                        {prd.body}
                                        </div>

                                        <div className="toast-footer flex justify-content-end">
                                            <button type="button" className="btn btn-info me-2">view</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    ):''
                        }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notifications;
