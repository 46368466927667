import { selectedDonorAtom } from "hook/state/generic";
import useAdminActions from "hook/useAdminActions";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { toFullName } from "utils/functions";
import SelectWrapper from "./SelectWrapper";


function MainSelect({ handleChange }) {
  const [options, setOptions] = useState([]);
  const [pageNo, setPage] = useState(0);
  //Checks if there are more pages to be loaded
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  const [selectedValue, setSelectedOption] = useRecoilState(selectedDonorAtom);
  const { getAllDonors } = useAdminActions()

  const loadOptions = async (page) => {
    try {
      setNextPageLoading(true);
      const data = await getAllDonors(page,'','','','','','',100,'');

      const dataOptions = data?.data?.users?.data?.map(({ id, last_name, first_name }) => (
        {
          label: toFullName({ firstName: first_name, lastName: last_name }),
          value: id
        }
      ));
      const itemsData = options.concat(dataOptions);
      setOptions(itemsData);
      setNextPageLoading(false);
      setHasNextPage(data?.data?.users?.to < data?.data?.users?.total);
      setPage(page);
    } catch (err) {
      console.log(err);
    }
  };

  const loadNextPage = async () => {
    await loadOptions(pageNo + 1);
  };

  return (
    <div className="App">
      <div className="dropdown">
        <SelectWrapper
          value={selectedValue}
          placeholder="Select"
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          options={options}
          loadNextPage={loadNextPage}
          onChange={(selected) => setSelectedOption(selected)}
        />
      </div>
    </div>
  );
}

export default MainSelect;
