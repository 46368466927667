import CitySelect from "components/common/Selects/CitySelect";
import CountrySelect from "components/common/Selects/CountrySelect";
import StateSelect from "components/common/Selects/StateSelect";
import useAdminActions from "hook/useAdminActions";
import { Suspense, useState } from "react";
import { useLocation } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notify } from "components/Alerts";
import { useRecoilValue, useSetRecoilState } from "recoil";
import LoadingIndicator from "components/LoadingIndicator";
import { userSelector } from "hook/state/user";
import { authAtom } from "hook/state/auth";
import useUserActions from "hook/useUserActions";


const UpdateProfile = ({ edit, setEdit, user }) => {
  const location = useLocation()
  const setAuth = useSetRecoilState(authAtom)
  const queryClient = useQueryClient()
  const routeCheck = location.pathname.includes('admin')
  const [selectedCountry, setSelectedCountry] = useState({ id: 160, name: 'Nigeria' })
  const [selectedState, setSelectedState] = useState(() => {
    return { id: user?.state_id }
  })
  const { getAdminProfile, updateAdminProfile } = useAdminActions()
  const { getUserProfile, updateUserProfile } = useUserActions()
  const [selectedCity, setSelectedCity] = useState(() => {
    return { id: user?.city_id }
  })

  const [formData, setFormData] = useState({
    ...user,
  })
  const handleSubmit = (e) => {
    e.preventDefault();debugger
    if (routeCheck) {
      mutate({
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_mobile: formData.phone_mobile,
        phone_home: formData.phone_home,
        date_of_birth: formData.date_of_birth,
        profile: formData.profile,
        profile_status: formData.profile_status,
        state_id: selectedState?.id,
        country_id: selectedCountry?.id,
        city_id: selectedCity?.id
      })
    } else {
      mutate({
        // name: `${formData.first_name} ${formData.last_name}`,

        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_mobile: formData.phone_mobile,
        phone_home: formData.phone_home,
        date_of_birth: formData.date_of_birth,
        profile: formData.profile,
        profile_status: formData.profile_status,
        state_id: selectedState?.id,
        country_id: selectedCountry?.id,
        city_id: selectedCity?.id,
        email: formData.email
      })
    }

  };

  const checkRoute = location.pathname.includes('admin')
  const { mutate, isLoading } = useMutation(routeCheck ? updateAdminProfile : updateUserProfile, {
    onSuccess: () => {
      setEdit(false);
      queryClient.invalidateQueries('profile')
      notify('Profile updated successfully', { type: 'success' })
    },
    onError: () => {
      setEdit(false);
      notify('Profile update failed!', { type: 'error' })
    }
  })


  useQuery(['profile'], routeCheck ? getAdminProfile : getUserProfile, {
    onSuccess: (data) => {
      setEdit(false);
      if (data.data.admin) {
        setAuth(prev => { return { token: prev.token, admin: data.data.admin } })
      } else if (data.data.user) {
        setAuth(prev => { return { token: prev.token, user: data.data.user } })
      }

    },
    onError: (error) => {
      notify(error, { type: 'error' })
    },
    staleTime: Infinity
  })
  const changeProfile= (e) =>{
    if(e.target.checked)
    {
      setFormData({ ...formData, profile_status: e.target.value });
    }
      else{
        setFormData({ ...formData, profile_status: 0 });
      }
      
  }
  return (
    <div>
      {edit && (
        <p>
          Start Editing...
        </p>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <input className="form-control" id="fname" name='fname' type="text" value={formData.first_name} disabled={!edit} onChange={(e) => {
            setFormData({ ...formData, first_name: e.target.value })
          }} />
          <label htmlFor="fname">First Name</label>
        </div>
        <div className="form-floating mb-3">
          <input className="form-control" id="lname" name='lname' type="text" value={formData.last_name} disabled={!edit} onChange={(e) => {
            setFormData({ ...formData, last_name: e.target.value })
          }} />
          <label htmlFor="name">Last Name</label>
        </div>
        <div className="form-floating mb-3">
          <input className="form-control" id="email" name='email' type="email" value={formData.email} disabled />
          <label htmlFor="name">Email</label>
        </div>
        <div className="form-floating mb-3">
          <input className="form-control" id="phone_home" name='phone_home' type="text" value={formData.phone_home} disabled={!edit} onChange={(e) => {
            setFormData({ ...formData, phone_home: e.target.value })
          }} />
          <label htmlFor="number1">Phone Home</label>
        </div>
        <div className="form-floating mb-3">
          <input className="form-control" id="phone_mobile" name='phone_mobile' type="text" value={formData.phone_mobile} disabled={!edit} onChange={(e) => {
            setFormData({ ...formData, phone_mobile: e.target.value })
          }} />
          <label htmlFor="number1">Phone Mobile</label>
        </div>
        {!checkRoute && <div className="form-floating mb-3">
          <input className="form-control" id="date_of_birth" name='date_of_birth' type="date" value={formData.date_of_birth || new Date()} disabled={!edit} onChange={(e) => {
            setFormData({ ...formData, date_of_birth: e.target.value })
          }}/>
          <label htmlFor="date_of_birth">Date of Birth  </label>
        </div>}
        <div className="form-floating mb-3">
          <Suspense fallback={<LoadingIndicator />} >
            <CountrySelect selected={selectedCountry} handleChange={(e) => {
              setSelectedCountry(JSON.parse(e.target.value))
            }} disabled={!edit} />
          </Suspense>
          <label htmlFor="country">Country </label>
        </div>
        <div className="form-floating mb-3">
          <Suspense fallback={<LoadingIndicator />} >
            <StateSelect selectedCountry={selectedCountry} selected={selectedState} handleChange={(e) => {
              setSelectedState(JSON.parse(e.target.value))
            }} disabled={!edit} />
          </Suspense>
          <label htmlFor="state">State</label>
        </div>
        <div className="form-floating mb-3">
          <Suspense fallback={<LoadingIndicator />} ><CitySelect selectedState={selectedState} selected={selectedCity} handleChange={(e) => {
            setSelectedCity(JSON.parse(e.target.value))
          }} disabled={!edit} />
          </Suspense>
          <label htmlFor="city">City/town</label>
        </div>
        {!checkRoute && <>
          <div className="form-floating mb-3">
            <input className="form-control" id="referral" name='referral' type="text" disabled={!edit} />
            <label htmlFor="referral">Referred by </label>
          </div>
          {/* <div className="form-floating mb-3">
            <input className="form-control" id="donationType" name='donationType' type="text" disabled={!edit} />
            <label htmlFor="donationType">Donation type </label>
          </div> */}
        </>}
        <div className="form-floating mb-3">
          <input className="form-control" id="profile" name='profile' type="text" value={formData.profile}  disabled={!edit} onChange={(e) => {
            setFormData({ ...formData, profile: e.target.value })
          }}/>
          <label htmlFor="name">Profile</label>
        </div>

        <div className="form-check mb-3">
            <input className="form-check-input" id="inputRememberPassword"
                type="checkbox" value="1" checked={formData.profile_status} 
                onChange={changeProfile}  disabled={!edit}
                />
            <label className="form-check-label"
                htmlFor="inputRememberPassword">Do you want your profile published on this site</label>
        </div>

        <div
          className="d-flex align-items-center justify-content-end mt-4 mb-0">
          <button className="btn btn-primary" type="submit" disabled={!edit || isLoading}>{isLoading && <LoadingIndicator />}Update</button>
        </div>
      </form>
    </div>
  )
}

export default UpdateProfile
