import PieChart from "../../../components/charts/PieChart";
import Table from "../../../components/Table";
import { FORUM_MGT } from "../../../navigation/routes";


const Reporting = () => {
    return (
        <>
            <div className="container-fluid px-4">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item"><a href={FORUM_MGT}>Reports</a></li>
                    <li className="breadcrumb-item active">All</li>
                </ol>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-primary text-white mb-4">
                            <div className="card-header">Current Highest Donation
                            </div>
                            <div className="card-body flex flex-column">
                                <span className="small text-white stretched-link">Name: Ciroma Daniel</span>
                                <span className="small text-white stretched-link">Amount: ₦15,000.00</span>
                                <span className="small text-white stretched-link">Date: 18/05/2022</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-warning text-white mb-4">
                            <div className="card-header">ActionAid User Status</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Active Users: 150</span>
                                <span className="small text-white stretched-link">Inactive Users: 2</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-success text-white mb-4">
                            <div className="card-header">Donor Status</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Active Donors: 150</span>
                                <span className="small text-white stretched-link">Inactive Donors: 2</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-danger text-white mb-4">
                            <div className="card-header">Users Breakdown</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Members: 150</span>
                                <span className="small text-white stretched-link">Champions :		280</span>
                                <span className="small text-white stretched-link">Patriots :		280</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header flex justify-content-between gap-2">
                                <div> <i className="fas fa-table me-1"></i>
                                    Donation Defaulters</div>
                                <span style={{ cursor: 'pointer' }}>Export</span>
                            </div>
                            <div className="card-body">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>User in charge</th>
                                            <th>Payment Trend</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>User in charge</th>
                                            <th>Payment Trend</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>001</td>
                                            <td>Tiger Nixon</td>
                                            <td>Goodwill Emmanuel</td>
                                            <td>click to view trend</td>
                                        </tr>
                                        <tr>
                                            <td>002</td>
                                            <td>Tiger Woods</td>
                                            <td>Samson Emmanuel</td>
                                            <td>click to view trend</td>
                                        </tr>
                                        <tr>
                                            <td>003</td>
                                            <td>Tiger Block</td>
                                            <td>Goodwill Samson</td>
                                            <td>click to view trend</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header flex justify-content-between gap-2">
                                <div> <i className="fas fa-table me-1"></i>
                                    One off Donations</div>
                                <span style={{ cursor: 'pointer' }}>Export</span>
                            </div>
                            <div className="card-body">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>001</td>
                                            <td>Tiger Nixon</td>
                                            <td>#10,000</td>
                                            <td>2022/01/01</td>
                                        </tr>
                                        <tr>
                                            <td>002</td>
                                            <td>Tiger Woods</td>
                                            <td>#10,000</td>
                                            <td>2022/01/01</td>
                                        </tr>
                                        <tr>
                                            <td>003</td>
                                            <td>Tiger Block</td>
                                            <td>#10,000</td>
                                            <td>2022/01/01</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header flex justify-content-between gap-2">
                                <div> <i className="fas fa-table me-1"></i>
                                    Registered Donors</div>
                                <span style={{ cursor: 'pointer' }}>Export</span>
                            </div>
                            <div className="card-body">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Member</th>
                                            <th>Champion</th>
                                            <th>Patriot</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Month</th>
                                            <th>Member</th>
                                            <th>Champion</th>
                                            <th>Patriot</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>Jan</td>
                                            <td>50</td>
                                            <td>30</td>
                                            <td>100</td>
                                        </tr>
                                        <tr>
                                            <td>Feb</td>
                                            <td>23</td>
                                            <td>10</td>
                                            <td>49</td>
                                        </tr>
                                        <tr>
                                            <td>Mar</td>
                                            <td>1000</td>
                                            <td>2000</td>
                                            <td>3000</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header flex justify-content-between gap-2">
                                <div> <i className="fas fa-table me-1"></i>
                                    Fundraisers</div>
                                <span style={{ cursor: 'pointer' }}>Export</span>
                            </div>
                            <div className="card-body">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Creator</th>
                                            <th>Amount Raised</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Type</th>
                                            <th>Creator</th>
                                            <th>Amount Raised</th>
                                            <th>Date</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>Flooding</td>
                                            <td>Tiger Woods</td>
                                            <td>#30,000,000</td>
                                            <td>2022/01/01</td>
                                        </tr>
                                        <tr>
                                            <td>Displacement</td>
                                            <td>Tiger Woods</td>
                                            <td>#30,000,000</td>
                                            <td>2022/01/01</td>
                                        </tr>
                                        <tr>
                                            <td>Earthquake</td>
                                            <td>Tiger Woods</td>
                                            <td>#30,000,000</td>
                                            <td>2022/01/01</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card mb-4">
                            <div className="card-header flex justify-content-between gap-2">
                                <div> <i className="fas fa-table me-1"></i>
                                    Donors per Region</div>
                                <span style={{ cursor: 'pointer' }}>Export</span>
                            </div>
                            <div className="card-body">
                                <PieChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Create new advert</h5>
                            <button type="button" className="btn close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="fa fa-close"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <input className="form-control" id="topic" name='topic' type="text"
                                    placeholder="My new topic" />
                                <label htmlFor="topic">Title</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="tags" name='tags' type="text"
                                    placeholder="My new tags" />
                                <label htmlFor="tags">Tagline</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="body" name='body'
                                    placeholder="My new body" type={'number'} />
                                <label htmlFor="body">Duration(in days)</label>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="media">Media</label>
                                <input type="file" className="form-control" id="media" aria-describedby="media" aria-label="Upload" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reporting;
