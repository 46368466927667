import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useRecycleReport from "hook/reports/useRecycleReport";
import { ONE_OFF_ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import SelectBox from "components/common/Selects/SelectBox";
import ButtonWithTips from "components/ButtonWithTips";
import MembershipEnhancedTable from "components/MembershipEnhancedTable";
 
const RecycleReport = () => {
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData,
     current, edit, setEdit, isLoading,
     setSearch,page,setPage,getExcel,
     setSearchCycle,setFrom, setTo,recycleReport
    } = useRecycleReport()
  const donation_cycles = [
    { id: 1, value: 'monthly', label: 'Monthly' },
    { id: 2, value: 'quarterly', label: 'Quarterly' },
    { id: 3, value: 'yearly', label: 'Yearly' }]
  
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Recycle Donation Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item">Recycle Donations</li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          {/* <Link className="btn btn-primary mb-2" to={`../${ONE_OFF_ANALYTICS}`} relative="path">View Analytics</Link> */}
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Recycle Donations
          </div>
          <div className="row">
          <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
          <div className="col-3" style={{width:'20%'}}>
            <SelectBox
                    selectName="donation_cycle"
                    selectLabel={`Donation cycle`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={donation_cycles}
                    onChange={(e) => {
                       setSearchCycle(e.value);
                    }}
                    selectOption={`Select Cycle`}
                   
                  />
            
            </div>
            <div className="col-3"> 
            <div className="filterParameters" id="filterParameters">
        <div className="datePickerWrapper">
          <div className="d-sm-flex align-items-end gap-2">
            <div className="form-inline">From:
              <input type='date' className="form-control" onChange={(e) => {
                setFrom(e.target.value);
              }}  />
            </div>
            <div className="form-inline">To:
              <input type='date' className="form-control" onChange={(e) => {
                setTo(e.target.value);
              }}/>
            </div>
            {/* <button className='btn btn-primary'><i className="fa fa-refresh"></i></button> */}
            <ButtonWithTips
                id="getExcel"
                tips="Click to download data in excel format"
                btnProps={{
                  className: 'btn btn-success',
                  onClick: getExcel,
                }}
              >
                <i className="fas fa-file-excel"></i>
              </ButtonWithTips>
          </div>

        </div>
      </div>
            </div>
          </div> 
          <div className="card-body">
          <MembershipEnhancedTable
              columns={columns}
              data={recycleReport?.data?.data?.donation_report || []}
              setData={setData}
              skipPageReset={skipPageReset} 
              // lastPage={data.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              // isFetching={allDonors?.isFetching || allDonors?.isLoading}
            />
            {/* <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              pagesize={100}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              isLoading={isLoading}
              fileName='recycle-reports'
            /> */}
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donation' size='md'>
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default RecycleReport
