import { useFormik } from "formik"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useGenericActions from "./useGenericAction"
import * as Yup from 'yup'
import { notify } from "components/Alerts"
import { fileToDataUri } from "utils/fileToData"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { userSelector } from "./state/user"
import useAdminActions from "./useAdminActions"
import ButtonWithTips from "components/ButtonWithTips"
const validationSchema = Yup.object({
  title: Yup.string()
    .required('Field cannot be empty'),
  body: Yup.string()
    .required('Must not be empty'),
  meta: Yup.string()
    .required('Must not be empty'),
  featured_image: Yup.mixed()
    .test('fileSize', 'File too large', (value) => value === null || (value && value.size < 1200000))
    .test(
      'fileFormat',
      'Unsupported file type',
      (value) => value === null || (value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type))
    ),
  post_type: Yup.string(),
  is_active: Yup.number(),
  categories: Yup.array(
    Yup.number().required('at least one category is required'),
  ).min(1),
  tags: Yup.array(
    Yup.string(),
  ),
})
const useForumAdmin = () => {
  const queryClient = useQueryClient()
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const [current, setCurrent] = React.useState(null)
  const [currentData, setCurrentData] = React.useState(null)
  const [img, setImg] = React.useState(() => currentData?.featured_image || null)
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const {
    getAllForumPosts,
    createNewForumPosts,
    updateAForumPost,
    deleteAForumPost,
    togglePostActiveness,
    togglePostPubStatus,
    togglePostFeaturedStatus,
    togglePostApprovedStatus, } = useAdminActions()
  const { getAllCategories } = useGenericActions()
  const handleFeaturedImageChange = async (e) => {
    const image = e.target.files[0];
    if (image?.type?.match(/^image\//)) {
      await fileToDataUri(image)
        .then(async (_image) => {
          setImg(_image);
          formik.setFieldValue("featured_image", image);
        })
        .catch((error) => console.log(error))
    }

  };
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      body: '',
      meta: '',
      featured_image: '',
      post_type: 'forum',
      categories: [],
      tags: [],
    },
    onSubmit: (values) => {
      const formData = new FormData()
      Object.entries(values).map(([key, value]) => key === 'categories' || key === 'tags' ? value?.map((item, i) => formData.append(`${key}[${i}]`, item)) : formData.append(key, value))
      formData.append('is_featured', 0)
      formData.append('is_published', 0)
      createForumMutation.mutate(formData)
    },
  })

  const { data: forums, isLoading: isForumLoading, isFetching } = useQuery(['forum posts'], getAllForumPosts, {
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })
  const { isLoading: isLoadingCategories, data: categories } = useQuery('categories', getAllCategories, {
    select: (data) => data.data.categories.data,
    onError: err => {
      console.log(err)
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })
  const createForumMutation = useMutation(createNewForumPosts, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setOpen(false)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const updateForumMutation = useMutation(updateAForumPost, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const toggleActiveMutation = useMutation(togglePostActiveness, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const toggleApprovalMutation = useMutation(togglePostApprovedStatus, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const toggleFeaturedMutation = useMutation(togglePostFeaturedStatus, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const togglePulishedMutation = useMutation(togglePostPubStatus, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const deleteForumMutation = useMutation(deleteAForumPost, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('forum posts')
      setOpen(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
      },
      {
        Header: 'Categories',
        id: 'categories',
        accessor: row => `${row.categories?.map(item => item?.name).join(', ')}`,
      },
      {
        Header: 'Desciption',
        accessor: 'meta',
      },
      {
        Header: 'Active',
        id: 'active',
        accessor: row => row.is_active ? 'YES' : 'NO'
      },
      {
        Header: 'Approved',
        id: 'approved',
        accessor: row => row.is_approved ? 'YES' : 'NO'
      },
      {
        Header: 'Published',
        id: 'published',
        accessor: row => row.is_published ? 'YES' : 'NO'
      },
      {
        Header: 'Featured',
        id: 'featured',
        accessor: row => row.is_featured ? 'YES' : 'NO'
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex gap-2 w-100">
            <ButtonWithTips id='ViewForum' tips="View Forum details" btnProps={{
              className: "btn btn-info", onClick: () => navigate(`${row.row.original?.id}`)
            }}  ><i className="fas fa-eye" /></ButtonWithTips>

            <div className="dropdown">
              <ButtonWithTips id='dropdownMenuButtonTips' tips="Click to perform other actions" btnProps={{
                className: "btn",
                'data-toggle': "dropdown",
                'aria-haspopup': "true",
                "aria-expanded": "false",
                type: 'button',
                id: 'dropdownMenuButton'
              }}><i className="fa fa-ellipsis-v" aria-hidden="true" /></ButtonWithTips>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                <ButtonWithTips id={`delete${row.row.original.id}`} tips="Delete this Forum" btnProps={{
                  className: "dropdown-item", onClick: () => {
                    setCurrent('delete')
                    handleclick(row.row.original)
                  }
                }}><i className="fas fa-trash" aria-hidden="true" /> Delete</ButtonWithTips>

                <ButtonWithTips id={`edit${row.row.original.id}`} tips="Edit this Forum" btnProps={{
                  className: "dropdown-item", onClick: () => {
                    setCurrent('edit')
                    handleclick(row.row.original)
                  }
                }}><i className="fas fa-pencil" aria-hidden="true" /> Edit</ButtonWithTips>

                <ButtonWithTips id={`toggle${row.row.original.id}`} tips="Toggle activeness" btnProps={{
                  className: "dropdown-item", onClick: () => toggleActiveMutation.mutate({ id: row.row.original.id }), disabled: toggleActiveMutation.isLoading
                }}><i className={row.row.original.is_active ? "fas fa-eye-slash" : "fas fa-eye"} aria-hidden="true" /> Set as {row.row.original.is_active ? 'In Active' : 'Active'}</ButtonWithTips>

                <ButtonWithTips id={`toggleApproval${row.row.original.id}`} tips="Toggle approval status" btnProps={{
                  className: "dropdown-item", onClick: () => toggleApprovalMutation.mutate({ id: row.row.original.id }), disabled: toggleApprovalMutation.isLoading
                }}><i className={row.row.original.is_approved ? "fas fa-circle-xmark" : "fas fa-check-circle"} aria-hidden="true" /> Set as {row.row.original.is_approved ? 'Unapproved' : 'Approved'}</ButtonWithTips>

                <ButtonWithTips id={`toggleFeature${row.row.original.id}`} tips="Toggle featured status" btnProps={{
                  className: "dropdown-item", onClick: () => toggleFeaturedMutation.mutate({ id: row.row.original.id }), disabled: toggleFeaturedMutation.isLoading
                }}><i className={row.row.original.is_featured ? "fa fa-heart-o" : "fa fa-heart"} aria-hidden="true" /> Set as {row.row.original.is_featured ? 'Not Featured' : 'Featured'}</ButtonWithTips>

                <ButtonWithTips id={`toggleFeacture${row.row.original.id}`} tips="Toggle publish status" btnProps={{
                  className: "dropdown-item", onClick: () => togglePulishedMutation.mutate({ id: row.row.original.id }), disabled: togglePulishedMutation.isLoading || !row.row.original.is_active || !row.row.original.is_approved
                }}><i className={row.row.original.is_published ? "fa fa-globe" : "fas fa-globe"} aria-hidden="true" /> Set as {row.row.original.is_published ? 'Not Published' : 'Published'}</ButtonWithTips>
              </div>
            </div>
          </div>
        ),
      }
    ],
    []
  )

  const deleteRecordHandler = () => {
    deleteForumMutation.mutate({ id: currentData?.id })
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  return {
    forums, isForumLoading, columns, isFetching, page, setPage, setData, currentData, current, open, categories, isLoadingCategories, selectedCategory, setSelectedCategory, setOpen, createForumMutation, formik, setCurrent, img, setImg, handleFeaturedImageChange, setCurrentData, deleteRecordHandler, updateForumMutation, toggleApprovalMutation, toggleFeaturedMutation, togglePulishedMutation
  }
}

export default useForumAdmin
