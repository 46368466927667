import LoadingBox from 'components/LoadingBox'
import { format } from 'date-fns'
import useAdminActions from 'hook/useAdminActions'
import useUserActions from 'hook/useUserActions'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { formatMoney, toStartCase } from 'utils/functions'

const ViewFundraisingUser = ({ id }) => {
  const location = useLocation()
  const { getAFundraising } = useUserActions()
  const { getAFundraisingAdmin } = useAdminActions()

  const { data: fundRaisingData, isLoading: isDataLoading, isError } = useQuery(['fundraiser', id], () => location.pathname.includes('admin') ? getAFundraisingAdmin(id) : getAFundraising(id), {
    select: data => data.data.fund_raising,
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })

  return (
    <div className='container mt-3'>
      {isDataLoading ? <LoadingBox /> : !isError ?
        <div className="blog-post">
          <h2 className="blog-post__title">{toStartCase(fundRaisingData?.name)}</h2>
          <p className="blog-post__author">{`by ${fundRaisingData?.user?.first_name} ${fundRaisingData?.user?.last_name}`}</p>
          <p className="blog-post__author">{`Created on: ${format(new Date(fundRaisingData?.created_at), 'MMM dd yyyy')}`}</p>
          <p className="blog-post__content">{fundRaisingData?.description}</p>
          <h4><strong>Fundraiser details</strong></h4>
          <p className="m-0">Amount: {formatMoney(fundRaisingData?.amount)}</p>
          <p className="m-0">Due Date: {format(new Date(fundRaisingData?.due_date), 'MMM dd yyyy')}</p>
          <p className="m-0">Country: {fundRaisingData?.country?.name}</p>
          <p className="m-0">State: {fundRaisingData?.state?.name}</p>
          <p className="m-0">City: {fundRaisingData?.city?.name}</p>
          <p className="m-0">Fundraiser Type: {fundRaisingData?.type?.name}</p>
        </div> : ''}
    </div>
  )
}

export default ViewFundraisingUser
