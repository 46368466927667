// import CommingSoon from "../../../components/CommingSoon";
import { userSelector } from 'hook/state/user';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useRecoilValue } from 'recoil';
import styles from './network.module.css'
import { faker } from '@faker-js/faker';
import { Link } from 'react-router-dom';
import { MY_NETWORK } from 'navigation/routes';
import useNetwork from 'hook/useNetwork';
import LoadingBox from 'components/LoadingBox';
const Card = (props) => {

    return (
        <>
            {props.data?.map((item) => (
                <TreeNode key={item.id} label={<div className={styles.styledDiv}>{item.name}</div>}>
                    {item.children?.length && <Card data={item.children} />}
                </TreeNode>
            ))}
        </>
    );
};

const NetworkChart = () => {
    const { chartData, isLoading } = useNetwork()

    return (
        <div className="container-fluid px-4">
            <div className="d-sm-flex align-items-start justify-content-between mt-4">
                <div>
                    <h1>My Network</h1>
                    <ol className="breadcrumb mb-4">
                        <li className="breadcrumb-item"><a href='#'>My Network</a></li>
                        <li className="breadcrumb-item active">chart</li>
                    </ol>
                </div>
                <div
                    className="d-flex flex-wrap align-items-center justify-content-sm-end gap-2 mb-2">
                    <Link to={`../${MY_NETWORK}`} className="btn btn-secondary me-2">view network list </Link>
                </div>
            </div>
            {isLoading ? <LoadingBox /> : <div className='container overflow-auto'>
                <p className='text-center w-100 mt-2'><b>Chart view of my network downliners up to the eighth level</b></p>
                <Tree
                    lineWidth={"2px"}
                    lineColor={"green"}
                    lineBorderRadius={"10px"}
                    label={<div className={styles.styledDiv}>{chartData?.name}</div>}
                >
                    <Card data={chartData?.children} />
                </Tree>
            </div>}
        </div>
    );
}

export default NetworkChart;
