
import styles from '../MessageChat/Chat/styles.module.css'
import ManageChat from "components/message/ManageChat";
import {useState} from "react";
import NewChat from "../MessageChat/Chat/NewChat";

const LiveChat = () => {
    const [curentView, setCurentView] = useState('list');

    const handleOpenCLicked = (str) => {
        setCurentView(str);
    };

    const handleBackCLicked = () => {
        setCurentView('list');
    };
    return (
        <div>
            {curentView === 'list' ? (
                <div className={styles.wrapper}>
                    <section className='mt-5'>
                        <div className='container-fluid'>
                            <div className="row">
                                <div className="col-12 mb-4" style={{ borderBottom: '1px solid #f1f1f1' }}><h1 className="" style={{ fontSize: '2rem' }}>
                                    Users Chat Forum
                                </h1>
                                </div>
                                <div className='col-12'>
                                    <ManageChat handleOpenCLicked={handleOpenCLicked} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>) : <NewChat handleBackCLicked={handleBackCLicked} />}
        </div>
    );
}
export default LiveChat;
