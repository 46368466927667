import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as Yup from 'yup'
import useUserActions from "./useUserActions"
import { notify } from "components/Alerts"
import { useRecoilValue } from "recoil"
import { userSelector } from "./state/user"
import useGenericActions from "./useGenericAction"
import { format } from "date-fns"
import { formatMoney } from "utils/functions"
import { useLocation } from "react-router-dom"
import useAdminActions from "./useAdminActions"
import classNames from "utils/classNames"
import ButtonWithTips from "components/ButtonWithTips"
const validationSchema = Yup.object({
  name: Yup.string()
    .required('Field cannot be empty'),
  description: Yup.string()
    .required('Must not be empty'),
  due_date: Yup.string()
    .required('Must not be empty'),
  type_id: Yup.number()
    .required('Must not be empty'),
  country_id: Yup.number()
    .required('Must not be empty'),
  state_id: Yup.number()
    .required('Must not be empty'),
  city_id: Yup.number()
    .required('Must not be empty'),
  amount: Yup.number()
    .required('Must not be empty'),
})
const useFundRaisingUser = () => {
  const location = useLocation()
  const MAX_DESCRIPTION = 500;
  const queryClient = useQueryClient()
  const user = useRecoilValue(userSelector)
  const [currentView, setCurrentView] = useState('table')
  const [charLeft, setCharLeft] = useState(MAX_DESCRIPTION);
  const [selectedCategory, setSelectedCategory] = useState('')
  const [currentData, setCurrentData] = React.useState(null)
  const [selectedCountry, setSelectedCountry] = useState({ id: 160, name: 'Nigeria' })
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const [current, setCurrent] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [comment, setComment] = React.useState('')

  const { getFundRaisingTypes } = useGenericActions()
  const {
    getFundraisings,
    createNewFundraising,
    updateAFundraising,
    deleteAFundraising, } = useUserActions()
  const {
    getFundraisingsAdmin,
    createNewFundraisingAdmin,
    updateAFundraisingAdmin,
    deleteAFundraisingAdmin, toggleFundraisingActiveness } = useAdminActions()


  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: current === 'edit' ? currentData?.name : '',
      description: current === 'edit' ? currentData?.description : '',
      due_date: current === 'edit' ? currentData?.due_date : '',
      type_id: current === 'edit' ? currentData?.type_id : '',
      country_id: current === 'edit' ? currentData?.country_id : 160,
      state_id: current === 'edit' ? currentData?.state_id : '',
      city_id: current === 'edit' ? currentData?.city_id : '',
      amount: current === 'edit' ? currentData?.amount : '',
    },
    onSubmit: (values) => {
      const formData = new FormData()

      Object.entries(values).map(([key, value]) => formData.append(key, value))
      if (current === 'edit') {
        formData.append('_method', 'PUT')
        formData.append('id', currentData?.id)
        updateFundraisingMutation.mutate(formData)
      } else {
        createFundraisingMutation.mutate(formData)
      }

    },
  })

  const handleDescriptionChange = (values) => {
    const remaining = charLeft - values.target.value.length;
    formik.setFieldValue('description', values.target.value);
    setCharLeft(remaining);
  };

  useEffect(() => {
    const remm = Math.abs(MAX_DESCRIPTION - formik?.values?.description?.length);
    if (remm >= 0) {
      setCharLeft(remm);
    }
  }, [formik.values.description]);



  const { data: fundraisings, isLoading: isFundraisingLoading, isFetching } = useQuery('fundraising', location.pathname.includes('admin') ? getFundraisingsAdmin : getFundraisings, {
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })

  const { data: fundraisingTypes } = useQuery(['fundraisingtypes'], getFundRaisingTypes, {
    select: data => data?.data?.fund_raising_types?.map(item => ({ label: item.name, value: item.id })),
    onError: err => {
      console.log(err)
    },
  })


  const createFundraisingMutation = useMutation(location.pathname.includes('admin') ? createNewFundraisingAdmin : createNewFundraising, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('fundraising')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentView('table')
      setOpen(false)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const updateFundraisingMutation = useMutation(location.pathname.includes('admin') ? updateAFundraisingAdmin : updateAFundraising, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('fundraising')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const deleteFundraisingMutation = useMutation(location.pathname.includes('admin') ? deleteAFundraisingAdmin : deleteAFundraising, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('fundraising')
      setOpen(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const toggleActiveMutation = useMutation(toggleFundraisingActiveness, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('fundraising')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const handleStatusChange = (id, user, status) => {
    const formData = new FormData()
    formData.append('_method', 'PUT')
    formData.append('id', id)
    formData.append('status', status)
    formData.append('user_id', user)
    formData.append('notify_user', 1)
    if (status === 'rejected') {
      formData.append('comment', comment)
    }
    updateFundraisingMutation.mutate(formData)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Tagline',
        accessor: 'name',
      },
      {
        Header: 'Desciption',
        id: 'description',
        accessor: row => <div className="text-truncate-3-lines">{row?.description}</div>,
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: row => `${formatMoney(row?.amount)}`,
      },
      {
        Header: 'Due Date',
        id: 'due_date',
        accessor: row => `${format(new Date(row?.due_date), 'MM/dd/yyyy')}`,
      },
      {
        Header: 'Published',
        id: 'is_active',
        accessor: row => <span className={classNames(row.is_active === '1' ? 'bg-success' : 'bg-danger', "d-block text-center  rounded-pill py-1 px-2 text-white")}>{row.is_active === '1' ? 'Published' : 'Not Published'}</span>
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => <span className={classNames(row.status === 'pending' ? 'bg-warning' : row.status === 'rejected' ? 'bg-danger' : 'bg-success', "d-block text-center  rounded-pill py-1 px-2 text-white")}>{row.status}</span>
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Fundraising Details" btnProps={{
              className: "btn btn-info", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
            {(row.row.original?.user_id === user.id || location.pathname.includes('admin')) && <>
              <div className="dropdown">
                <ButtonWithTips id='dropdownMenuButtonTips' tips="Click to perform other actions" btnProps={{
                  className: "btn",
                  'data-toggle': "dropdown",
                  'aria-haspopup': "true",
                  "aria-expanded": "false",
                  type: 'button',
                  id: 'dropdownMenuButton'
                }}><i className="fa fa-ellipsis-v" aria-hidden="true" /></ButtonWithTips>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" onClick={() => {
                    setCurrent('delete')
                    handleclick(row.row.original)
                  }}><i className="fas fa-trash" /> Delete</button>

                  <button className="dropdown-item" onClick={() => {
                    setCurrent('edit')
                    handleclick(row.row.original)
                  }}><i className="fas fa-pencil" /> Edit</button>
                  {location.pathname.includes('admin') && <>
                    {row.row.original.status !== 'approved' &&
                      <button className="dropdown-item" onClick={() => {
                        handleStatusChange(row.row.original.id, row.row.original.user_id, 'approved')
                      }} disabled={updateFundraisingMutation.isLoading}><i className={"fas fa-check-circle"} aria-hidden="true" /> Set as Approved</button>}
                    {row.row.original.status !== 'rejected' && <button className="dropdown-item" onClick={() => {
                      setCurrent('rejected')
                      handleclick(row.row.original)
                    }} disabled={updateFundraisingMutation.isLoading}><i className={"fa fa-times-circle"} aria-hidden="true" /> Set as Rejected</button>
                    }
                    <button className="dropdown-item" onClick={() => {
                      toggleActiveMutation.mutate({ id: row.row.original.id })
                    }} disabled={toggleActiveMutation.isLoading || row.row.original.status !== 'approved'}><i className={row.row.original.is_active === '1' ? "fas fa-eye-slash" : "fa fa-eye"} aria-hidden="true" /> Set as {row.row.original.is_active === '1' ? 'In-Active' : 'Active'}</button>
                  </>}
                </div>
              </div>
            </>}
          </div>
        ),
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, user.id]
  )

  const deleteRecordHandler = () => {
    deleteFundraisingMutation.mutate({ id: currentData?.id })
  }

  const handleReject = () => {
    if (!comment) {
      notify('Comment Cannot be empty', { type: 'error', duration: 5000 })
    } else if (!comment || comment?.length < 8) {
      notify('Your Comment is not explanatory enough', { type: 'error', duration: 5000 })
    } else {
      handleStatusChange(currentData.id, currentData.user_id, 'rejected')
    }
  }
  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  return {
    fundraisings, isFundraisingLoading, columns, isFetching, page, setPage, setData, current, open, setOpen, createFundraisingMutation, formik, setCurrent, currentData, setCurrentData, deleteRecordHandler, updateFundraisingMutation, currentView, setCurrentView, selectedCategory, setSelectedCategory, selectedCountry, setSelectedCountry, selectedState, setSelectedState, handleDescriptionChange, MAX_DESCRIPTION, charLeft, setCharLeft, fundraisingTypes, selectedCity, setSelectedCity, comment, setComment, handleReject
  }
}

export default useFundRaisingUser
