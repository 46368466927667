import React, { useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';

const SelectBox = ({ error = null,
  selectName,
  value,
  onChange,
  isMulti = false,
  itemList,
  selectLabel,
  selectOption,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  useEffect(() => {
    if (value) {
      setSelectedOptions(value);
    }
  }, [value])
  const handleChange = (selected) => {
    setSelectedOptions(selected);
    onChange(selected, selectName)
  }
  return (
    <>
      <Select
        name={selectName}
        placeholder={selectOption}
        options={itemList}
        isMulti={isMulti}
        onChange={handleChange}
        value={selectedOptions}
        className="form-group input-wrapper"
      />
      {error && <small className='error'>{error}</small>}
    </>
  );
};

export default SelectBox;
