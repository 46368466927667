import LoadingIndicator from "components/LoadingIndicator";
import { useEffect, useState } from "react";

const Pagination = ({ pageChangeHandler, totalPages, page, loading }) => {
  // Calculating max number of pages
  // const totalPages = Math.ceil(totalRows / rowsPerPage);

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(1);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the butons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    pageChangeHandler(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (totalPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [totalPages, currentPage]);
  return (
    <>{totalPages > 1 ?
      <div className="dataTable-pagination">
        <span>
          Page{' '}
          <strong>
            {page} of {totalPages}
          </strong>{' '}
        </span>
        <>
          <span className='pagintion-btns'>
            <button className='page-link'
              disabled={!canGoBack} onClick={() => setCurrentPage(1)}>
              {'<<'}
            </button>{' '}
            <button className='page-link' onClick={onPrevPage}
              disabled={!canGoBack}>
              {'<'}
            </button>{' '}
            <button className='page-link' onClick={onNextPage}
              disabled={!canGoNext}>
              {'>'}
            </button>{' '}
            <button className='page-link' onClick={() => setCurrentPage(totalPages)}>
              {'>>'}
            </button>{' '}
          </span></>
      </div>
      : null}
      {loading ? <div><LoadingIndicator /></div> : null}

    </>
  );
}

export default Pagination;
