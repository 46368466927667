import Contact from "../components/common/Contact"
import CallToAction from "../components/common/CTA"
import Footer from "../components/common/Footer"
import Header from "../components/common/Header"
import Hero from "../components/common/Hero"
import Interests from "../components/common/Interests"
import Patriots from "../components/common/Patriots"
import Services from "../components/common/Services"
import WhoWeAre from "../components/common/WhoWeAre"


const LandingPage = () => {
  return (
    <div id='page-top'>
      <Header />
      <Hero />
      <WhoWeAre />
      <Services />
      <CallToAction />
      <Interests />
      <Patriots />
      <Contact />
      <Footer />
    </div>
  )
}

export default LandingPage
