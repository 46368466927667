import LoadingBox from 'components/LoadingBox'
import useAuth from 'hook/useAuth'
import { SIGNIN } from 'navigation/routes'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Logout = () => {
  const { logout } = useAuth()
  useEffect(() => {
    logout(SIGNIN)
  }, [])
  return (
    <LoadingBox />
  )
}

export default Logout
