import { atom, selector } from 'recoil';
import { authAtom } from './auth';

const usersAtom = atom({
  key: 'users',
  default: null
});

const adminAtom = atom({
  key: 'admins',
  default: null
});

const userAtom = atom({
  key: 'user',
  default: null
});

const userSelector = selector({
  key: 'userState',
  get: ({ get }) => {
    const details = get(authAtom);
    if (details?.admin) {
      return details?.admin;
    } else {
      return details?.user;
    }
  },
});

const userRoleSelector = selector({
  key: 'userRoleState',
  get: ({ get }) => {
    const details = get(authAtom);
    if (details?.admin) {
      const roles = details.admin.roles.map(item => item.name)
      return roles;
    } else {
      const roles = details?.user?.roles.map(item => item.name)
      return roles;
    }
  },
});


export {
  usersAtom,
  userAtom,
  adminAtom,
  userSelector,
  userRoleSelector
};
