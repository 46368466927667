import { notify } from 'components/Alerts';
import  classNames from 'utils/classNames';
import styles from './styles.module.css'
import React, { useEffect } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query';
import LoadingIndicator from 'components/LoadingIndicator';
import useAdminActions from 'hook/useAdminActions';




const validationSchema = Yup.object({
    name: Yup.string()
        .required('Name Must not be empty'),
    description: Yup.string()
        .required('Description Must not be empty'),
})
const NewChat = ({ handleBackCLicked }) => {
    const queryClient = useQueryClient()
  
    const {  createAdminChat } = useAdminActions()


    const handleChange = (
        newValue,
        textValue
    ) => {
        if (textValue.length && textValue !== '\n') {
            formik.setFieldValue('body', newValue)
        } else {
            formik.setFieldError('body', 'Post body cannot be empty')
        }
    };

    const formik = useFormik({
        validationSchema,
        initialValues: {
            name: '',
            description: '',
        },
        onSubmit: (values) => {
            const data = new FormData();
            data.append("name", values.name);
            data.append("description", values.description);
            mutate(data)
        },
    })

    const { mutate, isLoading } = useMutation(createAdminChat, {
        onSuccess: (res) => {
            formik.resetForm()
            handleBackCLicked()
            notify(res.message, { type: 'success' })
        },
        onError: error => {
            console.log(error)
            notify("The name has already been taken.", { type: 'error' })
        }
    })






    return (
        <>
            <div className={styles.wrapper}>
                <section className='mt-5'>
                    <div className='container-fluid'>
                        <div className="forum_header sticky-top">
                            <div className="row">
                                <div className="col-12 mb-4" >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h1 className="" style={{ fontSize: '2rem' }}>
                                                Create Chat Forum
                                            </h1>
                                        </div>
                                        <div className="col-md-3 offset-md-3">
                                            <p onClick={handleBackCLicked} className='btn btn--secondary'><i className="fa fa-arrow-left"></i> back</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className={classNames(styles.newPostForm, ' mb-4')}>
                                        <form className='w-auto mx-auto' onSubmit={formik.handleSubmit}>

                                            <div className="row form-group">
                                                <label htmlFor="name" className='col-sm-4'>Name</label>
                                                <select id="name" name="name" className='form-control col-sm-7' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option>Select Chat Forum</option>
                                                    <option value="capro_chat_forum">Capro Members Chat Forum</option>
                                                    <option value="board_chat_forum">Board Chat Forum</option>
                                                </select>
                                                {formik.errors.name && formik.touched.name && <small className={classNames(styles.error, 'col-sm-7 offset-sm-4')}>{formik.errors.name}</small>}
                                            </div>

                                            <div className="row form-group">
                                                <label htmlFor="description" className='col-sm-4'>Description</label>
                                                <textarea
                                                    className="form-control col-sm-7"
                                                    name="description"
                                                    rows="4"
                                                    placeholder="description..."
                                                    value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                ></textarea>
                                                {formik.errors.description && formik.touched.description && <small className={classNames(styles.error, 'col-sm-7 offset-sm-4')}>{formik.errors.description}</small>}
                                            </div>


                                            <div className='mt-2'>
                                                <button type='submit' className='btn button--burgundy' disabled={isLoading}>{isLoading ? <LoadingIndicator /> : 'Submit'}</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default NewChat;
