import { authAtom } from "hook/state/auth";
import { useCallback, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ADMIN_ROUTES, DASHBOARD, DONATION, SIGNIN, SIGNUP, USER_ROUTES } from "../../navigation/routes";
import classNames from "../../utils/classNames";

const Header = () => {
    const { pathname } = useLocation();
    const userDetails = useRecoilValue(authAtom)
    const [stickyMenu, setStickyMenu] = useState(false)
    const onScroll = useCallback(() => {
        const { scrollY } = window;
        const headerScrollPos = 160
        if (scrollY > headerScrollPos) {
            setStickyMenu(true)
        } else if (scrollY <= headerScrollPos) {
            setStickyMenu(false)
        }
    }, []);
    useEffect(() => {
        //add eventlistener to window

        window.addEventListener("scroll", onScroll, { passive: true });
        // remove event on unmount to prevent a memory leak with the cleanup
        return () => {
            window.removeEventListener("scroll", onScroll, { passive: true });
        }
    }, []);

    return (
        <nav className={classNames(stickyMenu ? 'navbar-shrink' : '', "navbar navbar-expand-lg navbar-light fixed-top py-3")} id="mainNav">
            <div className="container px-4 px-lg-5">
                <Link to="/" className="navbar-brand">
                    <img className="main-logo" src="/img/logo_light.png" alt="actionaid MILMAP" />
                    <img className="alt-logo" src="/img/logo.png" alt="actionaid MILMAP" />
                </Link>

                <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto my-2 my-lg-0">
                        {![SIGNIN, SIGNUP, '/blog12', DONATION].some(substring => pathname.includes(substring)) &&
                            <>
                                <li className="nav-item">
                                    <a href="/#who-we-are" className='nav-link' >
                                        Who We Are
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/activity" className='nav-link' >
                                        Impact Stories
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#interests" className='nav-link' >
                                        Interests
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#contact" className='nav-link' >
                                        Contact
                                    </a>
                                </li> 
                            </>}
                        <li className="nav-item">
                            <Link to="/blog" className='nav-link' >
                                Blog
                            </Link>
                        </li>
                        {!userDetails && <li className="nav-item">
                            <a href="/signin" className='nav-link' >
                                Login
                            </a>
                        </li>}
                    </ul>
                    <div className="nav-btn-wrap">
                        <Link className="btn nav-btn btn-slide me-2" to={DONATION}>Donate</Link>
                        {!userDetails && <><Link className="btn nav-btn btn-slide" to={SIGNUP}>Join Movement</Link></>}
                        {userDetails && <Link className="btn nav-btn btn-slide me-2" to={userDetails.user ? `${USER_ROUTES}/${DASHBOARD}` : `${ADMIN_ROUTES}/${DASHBOARD}`}>Dashboard</Link>}
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
