import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { motion } from "framer-motion";

const AuthLayout = ({ children }) => {

    const variants = {
        hidden: { opacity: 0, x: -200, y: 0 },
        enter: { opacity: 1, x: 0, y: 0 },
        exit: { opacity: 0, x: 0, y: -100 },
    }
    return (

        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <header className="bg-dark sticky-top" style={{ height: '71px' }}>
                    <div className="">
                        <Header />
                    </div>
                </header>
                <motion.main
                    variants={variants}
                    initial="hidden"
                    animate="enter"
                    exit="exit"
                    transition={{ type: 'linear' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                {children}
                            </div>
                        </div>
                    </div>
                </motion.main>
            </div>
            <Footer />
        </div >
    );
}

export default AuthLayout;
