import SelectBox from "components/common/Selects/SelectBox";
import EnhancedTable from "components/EnhancedTable";
import MembershipEnhancedTable from "components/MembershipEnhancedTable";
import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useFundraiserReport from "hook/reports/useFundraiserReport";
import useDonation from "hook/useDonation";
import { startCase } from 'lodash'

const FundRaiserReport = () => {
  
  // const{    
  //   // fundraisingType
  // } = useDonation();
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, isLoading, current, edit, setEdit,page,
    setPage,fundraisingType, setSearch, setSearchFundraiseType } = useFundraiserReport()
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Campaign Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><a href='{FORUM_MGT}'>Campaigns</a></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Campaigns
          </div>

          <div className="row">
          <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
            <div className="col-3" style={{width:'20%'}}>
            <SelectBox
                    selectName="fundraise_type"
                    selectLabel={`fundraise type`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={fundraisingType}
                    onChange={(e) => {
                       setSearchFundraiseType(e.id);
                    }}
                    selectOption={`Fundraise Type`}
                   
                  />
            
            {/* <Select id="funding_type"
                name={'funding_type'}
                className="form-select" selected={selectedCycle} 
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'funding_type',
                    JSON.parse(e.target.value)?.value
                  );                  
                    setSelectedCycle(JSON.parse(e.target.value))
                }} options={fundraisingType} /> */}
            </div>
            
          </div>
          <div className="card-body">
            

 <MembershipEnhancedTable
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              // lastPage={data.last_page}
              currentPage={page}
              setCurrentPage={setPage}
               isFetching={data!==undefined}          
            />

{/* <MembershipEnhancedTable
              columns={columns}
              data={data?.data?.data?.donation_report || []}
              setData={setData}
              skipPageReset={skipPageReset} 
              // lastPage={data.last_page}
              currentPage={page}
              setCurrentPage={setPage}
               isFetching={data!==undefined}
            /> */}
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Campaign' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default FundRaiserReport
