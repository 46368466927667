import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import PieChart from "components/charts/PieChart";
import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import { format } from "date-fns";
import { subMonths } from "date-fns/esm";
import useDonationReport from "hook/reports/useDonationReport";
import { DONATION_REPORT } from "navigation/routes";
import { useState } from "react";
import { Link } from "react-router-dom";

const DonationAnalytics = () => {
  const { dummy_data, dummy_line_data, dummy_line2_data, dummy_pie_data } = useDonationReport()
  return (
    <>

      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donation Analytics</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><Link to={`../${DONATION_REPORT}`}>Donations</Link></li>
              <li className="breadcrumb-item active">Analytics</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONATION_REPORT}`} relative="path">View List</Link>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <i className="fas fa-chart-bar me-1"></i>
                  {format(new Date(), 'yyyy')} Donations by month
                </div>
                <div className="card-body">
                  <BarChart data={dummy_data} />
                </div>
                <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <i className="fas fa-chart-bar me-1"></i>
                  Donations for {format(new Date(), 'MMM yyyy')}
                </div>
                <div className="card-body">
                  <LineChart data={dummy_line_data} />
                </div>
                <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <i className="fas fa-chart-bar me-1"></i>
                  Donations for {format(subMonths(new Date(), 1), 'MMM yyyy')}
                </div>
                <div className="card-body">
                  <LineChart data={dummy_line2_data} />
                </div>
                <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-header">
                  <i className="fas fa-chart-bar me-1"></i>
                  Total Donations in Naira categorized by cycle for {format(new Date(), 'yyyy')}
                </div>
                <div className="card-body">
                  <PieChart data={dummy_pie_data} />
                </div>
                <div className="card-footer small text-muted">Updated yesterday at 11:59 PM
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DonationAnalytics;
