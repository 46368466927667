import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist'
const localStorage = typeof window !== `undefined` ? window.localStorage : null
const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // this key is using to store data in local storage
  storage: localStorage, // configurate which stroage will be used to store the data
})

const authAtom = atom({
  key: 'auth',
  // get initial state from local storage to enable user to stay logged in
  default: null,
  effects_UNSTABLE: [persistAtom],
});

const userToken = selector({
  key: 'userToken',
  get: ({ get }) => {
    const details = get(authAtom);
    return details?.token
  },
});


export { authAtom, userToken };
