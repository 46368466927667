import Modals from "components/Modal";
import { DONATION_ANALYTICS, USER_ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import { excludedFields } from "utils/constants";
import { Suspense, useState } from "react";
import useAdminActions from "hook/useAdminActions";
import ButtonWithTips from "components/ButtonWithTips";
import TableUser from "components/TableUser";
import BirthReport from "hook/reports/BirthReport";
import SelectBox from "components/common/Selects/SelectBox";
 
const BirthdayReport = () => {
  const { currentData, setCurrentData, open, setOpen, skipPageReset, columns, data, 
    setData, current, edit, isLoading,
    setSearch, 
    searchLevel,search, setFrom, setTo,sFrom,sTo,pagesize
  } = BirthReport()

  const months = [      
    
    { id: 1, value: '1', label: 'January' },
    { id: 2, value: '2', label: 'Feburary' },
    { id: 3, value: '3', label: 'March' },
    { id: 4, value: '4', label: 'April' },
    { id: 5, value: '5', label: 'May' },
    { id: 6, value: '6', label: 'June' },
    { id: 7, value: '7', label: 'July' },
    { id: 8, value: '8', label: 'August' },
    { id: 9, value: '9', label: 'September' },
    { id: 10, value: '10', label: 'October' },
    { id: 11, value: '11', label: 'November' },
    { id: 12, value: '12', label: 'December' }    
  ];  
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  
  const {getAllDonorDownload} = useAdminActions();
  const levels=[
    { id: 1, value: 'member', label: 'Member' },
    { id: 2, value: 'champion', label: 'Champion' },
    { id: 3, value: 'patriot', label: 'Patriot' }
  ];
 function getExcel(){  
  getAllDonorDownload(1,search,searchLevel, selectedState,selectedCity,sFrom,sTo)
  .then((response) => {    
   const url = window.URL.createObjectURL(new Blob([response]));
   const link = document.createElement('a');
   link.href = url;
   link.setAttribute('download', `userReport.xlsx`);
   document.body.appendChild(link);
   link.click();
})
 }
  return (
    <>

      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Birthday</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item">Users </li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${USER_ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donations
          </div>
          <div className="row">
          <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
           
            <div className="col-3">
            <div className="filterParameters" id="filterParameters">
        <div className="datePickerWrapper">
          <div className="d-sm-flex align-items-end gap-2">
            <div className="form-inline">
             
               <SelectBox selectName="donation_group" selectLabel={`Donation category`} style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={months}
                    onChange={(e) => {
                      setFrom(e.id);
                    }}
                    selectOption={`Select Month`}
                   
                  />
            </div> 
            
            {/* <button className='btn btn-primary'><i className="fa fa-refresh"></i></button> */}
            {/* <ButtonWithTips
                id="getExcel"
                tips="Click to download data in excel format"
                btnProps={{
                  className: 'btn btn-success',
                  onClick: getExcel,
                }}
              >
                <i className="fas fa-file-excel"></i>
              </ButtonWithTips> */}
          </div>

        </div>
      </div>
            </div>
            <div className="col-3">
            
            {/* <button class= "btn btn-success" type="submit"
                         onClick={(e) =>{    
                           e.preventDefault()
                           getAllDonorDownload(1,search,searchLevel, selectedState,selectedCity)
                           .then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `ongoingProject.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        })
                        }}
                        >
                        Download
                        </button> 
               */}
            </div>

          </div>
          <div className="card-body">
            <TableUser
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              pagesize={pagesize}
              filterTable={data?.length}
              isLoading={isLoading}
              fileName='Users'
            />
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View User' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            excludedFields.includes(key) ? (
              []
            ) :
              typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
                <dt className='col-4'>{`${startCase(key)} :`}</dt>
                {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
                {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
              </div>)}
        </dl>
      </Modals>
    </>
  );
}

export default BirthdayReport;
