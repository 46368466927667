import { notify } from 'components/Alerts'
import LoadingBox from 'components/LoadingBox'
import { formatDistanceToNowStrict } from 'date-fns'
import useAdminActions from 'hook/useAdminActions'
import useGenericActions from 'hook/useGenericAction'
import useUserActions from 'hook/useUserActions'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

const ViewForum = () => {
  const { forumId } = useParams()
  const location = useLocation()
  const queryClient = useQueryClient()
  const [openComment, setOpenComment] = useState(false);
  const [comments, setComments] = useState('');
  const { addAComment, getAPost } = useGenericActions()
  const dummyref = useRef(null);
  const { data: forumData, isLoading: isDataLoading, isFetching, isError } = useQuery(['forum post', forumId], () => getAPost(forumId), {
    select: data => data.data.forum,
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })
  useEffect(() => {
    if (openComment) {
      dummyref?.current?.scrollIntoView({ behavior: "smooth" });
    }

  }, [forumData, openComment]);
  const { mutate, isLoading } = useMutation(addAComment, {
    onSettled: res => {
      if (res.success) {
        queryClient.invalidateQueries('forum post')
        notify(res.message, { type: 'success' })
        setComments('')
      }
      console.log(res)
    }
  })

  return (
    <div className='container mt-3'>
      {isDataLoading ? <LoadingBox /> : !isError ?
        <div className="blog-post">
          <h2 className="blog-post__title">{forumData?.title}</h2>
          <div className='blog-post__image--container'><img className="blog-post__image rounded" src={forumData?.featured_image} alt={forumData?.title} /></div>
          <p className="blog-post__content">{forumData?.body}</p>
          <button
            className="blog-post__comments-toggle"
            onClick={() => setOpenComment(!openComment)}
          >
            {openComment ? 'Close' : 'Show'} Comments
          </button>
          {openComment && (
            <div className="blog-post__comments">

              <ul className="messages-list">

                {forumData?.comments?.length ? forumData?.comments?.map((comment, index) => (
                  <li className="message-box" key={index}>
                    <div className="message__top">
                      <p className="message__author">{`${comment.user.first_name} ${comment.user.last_name}`}</p>
                      <p className="message__date">{`${formatDistanceToNowStrict(new Date(comment.updated_at))} ago`}</p>
                    </div>
                    <p className="message__text">
                      {comment.body}
                    </p>
                  </li>
                ))
                  :
                  <div className="blog-post__comment">
                    <p className="text-center">No comments yet! Be the first to comment</p>
                  </div>}

                <div ref={dummyref} />
              </ul>
              <div className="messages-compose">
                <div className="new-message">
                  <textarea
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    className="new-message__input"
                    placeholder={'Your message...'}
                  > 
                  </textarea>
                  <button disabled={isLoading} className="new-message__button" onClick={() => { mutate({ body: comments, postId: forumId }) }}><i className='d-sm-none fas fa-paper-plane'></i> <span className='d-none d-sm-flex'>Leave a comment</span></button>
                </div>
              </div>
            </div>
          )}
        </div> : ''}
    </div>
  )
}

export default ViewForum
