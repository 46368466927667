import Helmet from "react-helmet";
import CommonLayout from "../../components/common/Layout";
import useGenericActions from "hook/useGenericAction";
import { useQuery } from "react-query";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
export const Card = (props) => {
    return (
        // <div className="card-subcontainer">
        //     <div className="card-image">
        //         <img src={props.image} alt="" />
        //     </div>
        //     <div className="card-content">
        //         <h3 className="post-title mb-2  text-truncate-2-lines"><Link to={`${props.slug}`}>{props.title}</Link></h3>
        //         <p className="text-muted mb-0 text-truncate-3-lines">{props.description}</p>
        //     </div>
        // </div>

       
             <div className="col-lg-3 col-md-6 text-center interest">
                            <div className="mt-5 interest-wrap">
                                <div className="mb-2 card-image">
                                <img src={props.image} alt="" />
                                </div>
                                <h4 className="mb-3">{props.title}</h4>
                                <p className="text-muted mb-0">{props.description}</p>
                                <div className="hover-canvas" style={{ backgroundImage: `url(${props.image})` }}>
                                    <div className="hover-content">
                                        <h3 className="interest-title text-white">{props.title}</h3>
                                        <div className="description text-white">
                                            <p>{props.description}</p>
                                            
                                        </div>
                                        <div className="mt-4"></div>
                                        <Link to={`${props.slug}`}>{props.title}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
       
    );
}

const ActivityPage = () => {
    const { getActivities } = useGenericActions()
    const { data, isLoading } = useQuery(['activities', 'generic'], getActivities, {
        select: data => data.data.activity.data
    })
    return (
        <CommonLayout pageText="Our Impact Stories">
            <Helmet>
                <title>Activity | An ActionAid Nigeria’s Community Sponsorship Initiative</title>
            </Helmet>
            <section className="page-section impact-stories" id="impact-stories">
            <div className="container px-4 px-lg-5">
                <h1 className="section-title text-center mt-0">
                    Our Actitivies
                    <span className="subtitle">
                    See how donations and supports from our MILMAP benefactors are being utilised to put smiles on the faces of Nigerians.
                    </span>
                </h1> 
                <hr className="divider" />

              



                <div className="row g-4 g-lg-5 cards-container mt-5">
                {data?.length ? data?.map(post => 
                    <Card
                    key={post.id}
                    image={post.featured_image || '/img/placeholder.png'}
                    title={post.tagline}
                    slug={post.id}
                    description={post.title} />) : ''}


                </div>
            </div>
        </section>
        </CommonLayout>
    );
}

export default ActivityPage;
