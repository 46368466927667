import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types'
import useUserActions from 'hook/useUserActions';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
};
const dummy_data_ol = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
        {
            label: "Revenue",
            backgroundColor: "rgba(2,117,216,1)",
            borderColor: "rgba(2,117,216,1)",
            data: [4215, 5312, 6251, 7841, 9821, 14984],
        },
    ],
};

const BarChart = () => {
    const { getUserDonationDashboard } = useUserActions()
    var amount=[];
    var amount2=[5,10,30,50,20,5];
    var dateAcquired= [];
    const { isFetching, isLoading, data: history } = useQuery(['donations', 'history'], getUserDonationDashboard, {
        select: (data) => {
            // debugger;
            var ret= data.data.user_donations.data
            ret.forEach(item => {
                amount.push(item.amount)
                dateAcquired.push(format(new Date(item.created_at), 'MMM dd yyyy'))
            });
            // console.log(amount);
        },
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })

    const dummy_data = {
        // labels: ["January", "February", "March", "April", "May", "June"],
        labels: dateAcquired,
        datasets: [
            {
                label: "Revenue",
                backgroundColor: "rgba(2,117,216,1)",
                borderColor: "rgba(2,117,216,1)",
                data: amount,
            },
        ],
    };
    return (
        <Bar options={options} data={dummy_data} />
    );
}

export default BarChart;
BarChart.propTypes = {
    data: PropTypes.object,
}
