import { format, subMonths } from "date-fns";
import React from "react"
import { useEffect } from "react"
import { useQuery } from "react-query";
import { formatMoney, toStartCase } from "utils/functions"
import makeData from "utils/makeData"
import ButtonWithTips from "components/ButtonWithTips";
import useAdminActions from "hook/useAdminActions";


const BirthReport = () => {
  const { getBirthDonors } = useAdminActions()
  const [page, setPage] = React.useState(1);
  const [pagesize, setPageSize] = React.useState(100);
  const [searchLevel, setSearchLevel] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [selectedState2, setSelectedState2] = React.useState('')
    const [selectedCity2, setSelectedCity2] = React.useState('')
    const [sFrom, setFrom] =React.useState('')
  const [sTo, setTo] =React.useState('')
  const { data, isLoading } = useQuery(['users', page,search,sFrom,sTo], () => getBirthDonors(page,search,sFrom,sTo), {
    select: data => data.data.users.data
  }) 
  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [skipPageReset, setSkipPageReset] = React.useState(false)

  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }











  const columns = React.useMemo(
    () => [
      
      {
        Header: 'Name',
        id: 'fullname',
        accessor: row => toStartCase(`${row?.first_name} ${row?.last_name}`),
      },
      {
        Header: 'Phone Number',
        id: 'user_phone_number',
        accessor: row => `${row?.phone_mobile}${row?.phone_home ? ', ' + row?.phone_home : ''}`,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: row => toStartCase(row?.email) || '-',
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: row => row?.address || ' -',
      },
      {
        Header: 'Birth Date',
        accessor: row => row.date_of_birth=="0000-00-00"?null: format(new Date(row?.date_of_birth), 'MMM dd')
      }
      
    ],
    []
  )
  return { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, pagesize,
    current, edit, setEdit, isLoading,setFrom,setTo,sFrom,sTo,
  setSearch, setSearchLevel,setSelectedCity2,setSelectedState2 ,searchLevel,selectedCity2,selectedState2,search}
}

export default BirthReport;
