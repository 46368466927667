import Logout from "pages/innerPages/Logout";
import ResetPassword from "pages/innerPages/ResetPassword";
import Roles from "pages/innerPages/Roles";
import SettingsPage from "pages/innerPages/Settings";
import { Routes, Route, Link } from "react-router-dom";
import CommingSoon from "../components/CommingSoon";
import BlogPage from "../pages/Blog/Blog";
import SingleBlogPage from "../pages/Blog/SingleBlogPage";
import DonationPage from "../pages/Donation";
import AAidUserMgt from "../pages/innerPages/AAidUserMgt";
import FundRaiser from "../pages/innerPages/Activities/FundRaiser";
import MyActivities from "../pages/innerPages/Activities/MyActivities";
import Advert from "../pages/innerPages/Advert";
import AdminLogs from "../pages/innerPages/CallSmsMgt/Admins";
import UsersLog from "../pages/innerPages/CallSmsMgt/UsersLog";
import ChartPage from "../pages/innerPages/Chat";
import Dashboard from "../pages/innerPages/Dashboard";
import Donation from "../pages/innerPages/Donation";
import DonationHistory from "../pages/innerPages/Donation/DonationHistory";
import DonationMgt from "../pages/innerPages/DonationMgt";
import ForgotPassword from "../pages/innerPages/ForgotPassword";
import ForumPage from "../pages/innerPages/Forum";
import ForumMgt from "../pages/innerPages/ForumMgt";
import LiveChat from "../pages/innerPages/Network/LiveChat";
import MyNetwork from "../pages/innerPages/Network/MyNetwork";
import NetworkChart from "../pages/innerPages/Network/NetworkChart";
import Notifications from "../pages/innerPages/Notification";
import Growth from "../pages/innerPages/Progress/Growth";
import Reports from "../pages/innerPages/Progress/Reports";
import Reporting from "../pages/innerPages/Reporting";
import SigninPage from "../pages/innerPages/Signin";
import SignupPage from "../pages/innerPages/Signup";
import VolunteerMgt from "../pages/innerPages/Membership";
import ChatPage from "../pages/innerPages/MessageChat/chat";
import LandingPage from "../pages/LandingPage";
import ProtectedRoutes from "../utils/protectedRoutes";
import {
  ACTION_AID_USER_MGT,
  ACTIVITIES,
  ADMIN_LOGS,
  ADMIN_ROUTES,
  ADMIN_SIGNIN,
  ADVERTS,
  ANY_ROUTE,
  BLOG,
  CHART,
  CRM,
  DASHBOARD,
  DEFAULT_ANALYTICS,
  DEFAULT_DONATION,
  DEFAULT_CAMPAIGN,
  DONATE,
  DONATION,
  DONATION_ANALYTICS,
  DONATION_HISTORY,
  DONATION_MGT,
  DONATION_REPORT,
  DONATION__CATEGORY,
  DONATION__CATEGORY_ANALYTICS,
  DONOR__ANALYTICS,
  DONOR__CLASSIFICATION,
  DONOR__CLASSIFICATION_ANALYTICS,
  DONOR__REPORT,
  DONOR__SEGMENTATION,
  DONOR__STATUS,
  DONOR__STATUS_ANALYTICS,
  FORGOTPASSWORD,
  FORUM,
  FORUM_CATEGORIES,
  FORUM_MGT,
  FUNDRAISER,
  FUNDRAISERMGT,
  FUNDRAISER_TYPES,
  FUNDRAISER__DONATION,
  GROWTH,
  HOME,
  LIVE_CHAT,
  LOGOUT,
  MEMBERSHIP,
  CHAT,
  MY_ACTIVITY,
  MY_NETWORK,
  NETWORK_CHART,
  NOTIFICATION,
  NOTIFICATION_ANIVERSARIES,
  NOTIFICATION_BIRTHDAYS,
  NOTIFICATION_LOGS,
  NOTIFICATION_REMINDERS,
  NOTIFICATION_TEMPLATES,
  RECYCLE_DONATION,
  ONE_OFF_ANALYTICS,
  ONE_OFF_DONATION,
  ONE_OFF_CAMPAIGN_DONATION,
  RECYCLE_CAMPAIGN_DONATION,
  PROFILE,
  REPORTING,
  REPORTS,
  RESETPASSWORD,
  ROLES,
  SIGNIN,
  COMPLETE_REGISTRATION,
  SIGNUP,
  SIGNUP_NEXT,
  SINGLEBLOG_ITEM,
  SINGLEFORUMPAGE,
  USER_ANALYTICS,
  USER_LOG,
  USER_REPORT,
  BIRTHDAY_REPORT,
  USER_ROUTES,
  ACTIVITY,
  PATRIOT,
} from "./routes";
import UserDashboard from "pages/innerPages/Dashboard/userDashboard";
import SignupNextPage from "pages/innerPages/Signup/middleMan";
import DonationReport from "pages/innerPages/Reports/DonationReport/";
import DefaultReport from "pages/innerPages/Reports/DefaultReport/";
import OneOffReport from "pages/innerPages/Reports/OneOffReport/";
import DonorReport from "pages/innerPages/Reports/DonorReport/";
import CategoryReport from "pages/innerPages/Reports/CategoryReport/";
import FundRaiserReport from "pages/innerPages/Reports/FundRaiserReport/";
import DonorStatusReport from "pages/innerPages/Reports/DonorStatusReport/";
import DonorClassificationReport from "pages/innerPages/Reports/DonorClassificationReport/";
import ErrorFallback from "components/ErrorFallback";
import DonationAnalytics from "pages/innerPages/Reports/DonationReport/DonationAnalytics";
import DefaultAnalytics from "pages/innerPages/Reports/DefaultReport/DefaultAnalytics";
import OneOffAnalytics from "pages/innerPages/Reports/OneOffReport/OneOffAnalytics";
import DonorAnalytics from "pages/innerPages/Reports/DonorReport/DonorAnalytics";
import CategoryAnalytics from "pages/innerPages/Reports/CategoryReport/CategoryAnalytics";
import DonorClassificationAnalytics from "pages/innerPages/Reports/DonorClassificationReport/DonorClassificationAnalytics";
import DonorStatusAnalytics from "pages/innerPages/Reports/DonorStatusReport/DonorStatusAnalytics";
import ViewForum from "pages/innerPages/Forum/viewForum";
import ActivityUser from "pages/innerPages/Activity/user";
import ActivityMgt from "pages/innerPages/Activity/admin";
import FundRaisingTypes from "pages/innerPages/Utilities/FundRaisingTypes";
import ForumCategories from "pages/innerPages/Utilities/ForumCategories";
import FundRaiserUser from "pages/innerPages/Fundraiser/user";
import FundraiserMgt from "pages/innerPages/Fundraiser/admin";
import UserAnalytics from "pages/innerPages/Reports/UserReport/UserAnalytics";
import UserReport from "pages/innerPages/Reports/UserReport";
import BirthdayReport from "pages/innerPages/Reports/BirthdayReport";
import IndividualFundRiaserDonation from "pages/innerPages/Reports/FundRaiserReport/individual_fund_raiser_donation";
import CompleteRegistration from "pages/innerPages/Signup/completeRegistration";
import RecycleReport from "pages/innerPages/Reports/OneOffReport/recycleReport";
import OneOffReportC from "pages/innerPages/Reports/Campaign";
import RecycleReportCampaign from "pages/innerPages/Reports/Campaign/recycleReport";
import ActivityPage from "pages/Activity/Activity";
import DefaultCampaignReport from "pages/innerPages/Reports/DefaultReport/campaign";
import NotificationTemplates from "pages/innerPages/AdminNotification";
import ViewActivity from "pages/Activity/viewActivity";
import ViewPatriot from "pages/innerPages/Patriot/viewPatriot";

const AppNavigator = () => {

  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path={HOME} element={<LandingPage />} />
      <Route path={SIGNIN} element={<SigninPage />} />
      <Route path={ADMIN_SIGNIN} element={<SigninPage />} />
      <Route path={COMPLETE_REGISTRATION} element={<CompleteRegistration />} />
      <Route path={SIGNUP} element={<SignupPage />} >
        <Route path={`${SIGNUP}/:referrer`} element={<SignupPage />} />
      </Route>
      <Route path={SIGNUP_NEXT} element={<SignupNextPage />} />
      <Route path={BLOG} element={<BlogPage />} />
      <Route path={SINGLEBLOG_ITEM} element={<SingleBlogPage />} />
      <Route path={PATRIOT} element={<ViewPatriot />} />
      <Route path={ACTIVITY} element={<ActivityPage />} />
      <Route path={`${ACTIVITY}/:activityId`} element={<ViewActivity />} />
      <Route path={DONATION} element={<DonationPage />} />
      
        {/* <Route path={SINGLEBLOG_ITEM} element={
          <ProtectedRoutes authRequired={true}>
            <SingleBlogPage />
          </ProtectedRoutes>
        } />
       */}
      
      <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={`/admin${FORGOTPASSWORD}`} element={<ForgotPassword />} />
      <Route path={RESETPASSWORD} element={<ResetPassword />} />
      <Route path={`/admin${RESETPASSWORD}`} element={<ResetPassword />} />
      <Route path={USER_ROUTES} element={<ProtectedRoutes authRequired={true} />} >
        <Route index element={<UserDashboard />} />
        <Route path={DASHBOARD} element={<UserDashboard />} />
        <Route path={FORUM} element={<ForumPage />} />
        <Route path={SINGLEFORUMPAGE} element={<ViewForum />} />
        <Route path={DONATE} element={<Donation />} />
        <Route path={DONATION_HISTORY} element={<DonationHistory />} />
        <Route path={MY_ACTIVITY} element={<ActivityUser />} />
        <Route path={FUNDRAISER} element={<FundRaiserUser />} />
        
        <Route path={MY_NETWORK} element={<MyNetwork />} />
        <Route path={NETWORK_CHART} element={<NetworkChart />} />
        <Route path={LIVE_CHAT} element={<LiveChat />} />
        <Route path={GROWTH} element={<Growth />} />
        <Route path={REPORTS} element={<Reports />} />
        <Route path={NOTIFICATION} element={<Notifications />} />
        <Route path={PROFILE} element={<SettingsPage />} />
        <Route path={LOGOUT} element={<Logout />} />
      </Route>
      <Route path={ADMIN_ROUTES} element={<ProtectedRoutes authRequired={true} />} >
        {/* ADMIN */}
        <Route index element={<Dashboard />} />
        <Route path={DASHBOARD} element={<Dashboard />} />

        <Route path={DONATION_MGT} element={<DonationMgt />} />

        <Route path={FORUM_MGT} element={<ForumMgt />} />
        <Route path={`${FORUM_MGT}/:forumId`} element={<ViewForum />} />

        <Route path={ADVERTS} element={<Advert />} />

        <Route path={MEMBERSHIP} element={<VolunteerMgt />} />
        <Route path={CHAT} element={<ChatPage />} />

        <Route path={ACTIVITIES} element={<ActivityMgt />} />
        <Route path={FUNDRAISERMGT} element={<FundraiserMgt />} />

        <Route path={ACTION_AID_USER_MGT} element={<AAidUserMgt />} />
        <Route path={USER_LOG} element={<UsersLog />} />
        <Route path={ROLES} element={<Roles />} />
        <Route path={ADMIN_LOGS} element={<AdminLogs />} />
        <Route path={DONATION_REPORT} element={<DonationReport />} />
        <Route path={DONATION_ANALYTICS} element={<DonationAnalytics />} />

        <Route path={DEFAULT_DONATION} element={<DefaultReport />} />
        <Route path={DEFAULT_ANALYTICS} element={<DefaultAnalytics />} />

        <Route path={DEFAULT_CAMPAIGN} element={<DefaultCampaignReport />} />

        <Route path={RECYCLE_DONATION} element={<RecycleReport />} />

        <Route path={ONE_OFF_DONATION} element={<OneOffReport />} />
        <Route path={ONE_OFF_ANALYTICS} element={<OneOffAnalytics />} />

        <Route path={ONE_OFF_CAMPAIGN_DONATION} element={<OneOffReportC />} />
        <Route path={RECYCLE_CAMPAIGN_DONATION} element={<RecycleReportCampaign />} />

        <Route path={DONOR__REPORT} element={<DonorReport />} />
        <Route path={DONOR__ANALYTICS} element={<DonorAnalytics />} />

        <Route path={DONATION__CATEGORY} element={<CategoryReport />} />
        <Route path={DONATION__CATEGORY_ANALYTICS} element={<CategoryAnalytics />} />

        <Route path={FUNDRAISER__DONATION} element={<FundRaiserReport />} />
        <Route path={`${FUNDRAISER__DONATION}/:fundraisingId`} element={<IndividualFundRiaserDonation />} />
        <Route path={DONOR__STATUS} element={<DonorStatusReport />} />
        <Route path={DONOR__STATUS_ANALYTICS} element={<DonorStatusAnalytics />} />

        <Route path={DONOR__SEGMENTATION} element={<CommingSoon />} />

        <Route path={DONOR__CLASSIFICATION} element={<DonorClassificationReport />} />
        <Route path={DONOR__CLASSIFICATION_ANALYTICS} element={<DonorClassificationAnalytics />} />

        <Route path={USER_REPORT} element={<UserReport />} />
        <Route path={BIRTHDAY_REPORT} element={<BirthdayReport />} />
        <Route path={USER_ANALYTICS} element={<UserAnalytics />} />

        <Route path={NOTIFICATION_LOGS} element={<CommingSoon />} />
        <Route path={NOTIFICATION_TEMPLATES} element={<NotificationTemplates />} />
        <Route path={NOTIFICATION_BIRTHDAYS} element={<CommingSoon />} />
        <Route path={NOTIFICATION_REMINDERS} element={<CommingSoon />} />
        <Route path={NOTIFICATION_ANIVERSARIES} element={<CommingSoon />} />
        <Route path={REPORTING} element={<Reporting />} />
        <Route path={NOTIFICATION} element={<Notifications />} />
        <Route path={CRM} element={<CommingSoon />} />
        <Route path={PROFILE} element={<SettingsPage />} />
        <Route path={FUNDRAISER_TYPES} element={<FundRaisingTypes />} />
        <Route path={FORUM_CATEGORIES} element={<ForumCategories />} />
        <Route path={LOGOUT} element={<Logout />} />
      </Route>
      <Route path={ANY_ROUTE} element={<ErrorFallback error={{ message: 'Page not found' }} action={<Link to={'/'} className="btn btn-primary">Go Home</Link>} />} />
    </Routes>
  );
};

export default AppNavigator;
