import { format } from 'date-fns'
import namor from 'namor'
import {
    uniqueNamesGenerator,
    names, adjectives, colors, NumberDictionary
} from "unique-names-generator";
import { states } from './states';
const config = {
    dictionaries: [names, names],
    separator: " ",
    length: 2,
    style: "capital"
};
const statusChance = Math.random()
const numberDictionary = NumberDictionary.generate({ min: 9178234563, max: 9199999999 });
const minDate = new Date(2020, 0, 1)
const maxDate = Date.now();
const timestamp = new Date(+minDate + Math.floor(Math.random() * (maxDate - minDate)));
export const range = len => {
    const arr = []
    for (let i = 0; i < len; i++) {
        arr.push(i)
    }
    return arr
}

export const newAdvert = () => {
    const minDate = new Date(2020, 0, 1)
    const maxDate = Date.now();
    const timestamp = new Date(+minDate + Math.floor(Math.random() * (maxDate - minDate)));

    return {
        poster: uniqueNamesGenerator(config),
        tagline: namor.generate({ words: 4, numbers: 0 }),
        date: format(timestamp, 'yyyy/MM/dd'),
        donation: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 2000, max: 10000 })] }),
    }
}
export const callHistory = () => {
    const firstName = uniqueNamesGenerator({ dictionaries: [names] })
    const lastName = uniqueNamesGenerator({ dictionaries: [names] })
    const hrs = Math.round(Math.random() * 11) + 1;
    const mins = Math.round(Math.random() * 59);
    const contact = uniqueNamesGenerator({ dictionaries: [numberDictionary] })
    const addDictionary = NumberDictionary.generate({ min: 1, max: 10 });
    const center = uniqueNamesGenerator({ dictionaries: [addDictionary] })

    return {
        tagline: namor.generate({ words: 4, numbers: 0 }),
        donation: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 2000, max: 10000 })] }),
        name: `${firstName} ${lastName}`,
        contactno: `+${contact}`,
        email: `${firstName}@${lastName}.com`,
        callcenter: `Center ${center}`,
        calltype: statusChance > 0.56 ? 'Incoming' : 'Outgoing',
        duration: `${mins} : ${Math.round(Math.random() * 50) + 1}`,
        date: format(timestamp, 'yyyy/MM/dd'),
        crmid: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 1, max: 5 })], separator: ' ' }),
        totalcalls: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 1, max: 50 })], separator: ' ' }),
        time: `${hrs} : ${mins} ${statusChance > 0.66
            ? 'am' : 'pm'}`,
        type: statusChance > 0.66
            ? 'SMS' : 'Email',
        subject: uniqueNamesGenerator({ dictionaries: [adjectives, colors], separator: ' ' }),
        messagedate: `${format(timestamp, 'yyyy/MM/dd')} ${hrs} : ${mins} ${statusChance > 0.66
            ? 'am' : 'pm'}`,
    }
}
export const aaidUser = () => {
    const statusChance = Math.random()
    const timestamp = new Date(+minDate + Math.floor(Math.random() * (maxDate - minDate)));
    const firstName = uniqueNamesGenerator({ dictionaries: [names] })
    const lastName = uniqueNamesGenerator({ dictionaries: [names] })
    const addDictionary = NumberDictionary.generate({ min: 1, max: 50 });
    const contact = uniqueNamesGenerator({ dictionaries: [numberDictionary] })
    const address = uniqueNamesGenerator({ dictionaries: [addDictionary, names, names], separator: ' ' })

    return {
        name: `${firstName} ${lastName}`,
        email: `${firstName}@${lastName}.com`,
        contactno: `+${contact}`,
        address: `${address} street`,
        state: uniqueNamesGenerator({ dictionaries: [states] }),
        city: uniqueNamesGenerator({ dictionaries: [colors] }),
        fundraise: namor.generate({ words: 3, numbers: 0 }),
        level: statusChance > 0.66
            ? 'Patriot' : statusChance > 0.33 ? 'Champion' : 'Member',
        cycle: statusChance > 0.66
            ? 'Monthly' : statusChance > 0.33 ? 'Yearly' : 'Quarterly',
        donation: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 2000, max: 10000 })] }),
        duration: uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 10, max: 50 })] }),
        age: `${uniqueNamesGenerator({ dictionaries: [NumberDictionary.generate({ min: 10, max: 100 })] })} days`,
        status: statusChance > 0.66
            ? 'Active' : 'Inactive',
        date: format(timestamp, 'yyyy/MM/dd'),
    }
}
