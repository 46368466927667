import { notify } from "components/Alerts";
import LoadingIndicator from "components/LoadingIndicator";
import Input from "components/innerComponents/Input";
import { useFormik } from "formik";
import useAuth from "hook/useAuth";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useMutation } from "react-query";
import { scrollToErrors } from "utils/functions";
import * as Yup from 'yup';

const Contact = () => {
    const [message, setMessage] = useState('');
    const { contactUs } = useAuth()
    const validationSchema = Yup.object({
        full_name: Yup.string()
            .matches(
                /^[a-zA-Z0-9\s .!?,"-]+$/,
                'field accepts only string, numbers and hyphens'
            )
            .required('Field cannot be empty'),
        email: Yup.string()
            .email('must be valid email')
            .required('Must not be empty'),
        phone_mobile: Yup.string()
            .required('Field cannot be empty')
            .length(13, 'Phone number must be 13 digits only')
            .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
        message: Yup.string()
            .matches(
                /^[a-zA-Z0-9\s .!?,"-]+$/,
                'field accepts only string, commas, numbers and hyphens'
            )
            .required('Field cannot be empty')
    })

    const { mutate, isLoading } = useMutation(contactUs, {
        onSuccess: data => {
            // setAuth(data.data);
            notify(data.message, { type: 'success' })
        },
        onError: error => {
            notify(error, { type: 'error' })
        }
    })

    const handlePhoneMobile=(e)=>{
        formik.setFieldValue('phone_mobile',e);
      }
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            full_name: '',
            email: '',
            phone_mobile: '',
            message: ''
        },
        onSubmit: (values, {resetForm}) => {
            // setAuth(null)
            
            const credentials = {
                full_name: values.full_name,
                email: values.email,
                phone_mobile: values.phone_mobile,
                message: values.message
            }
            mutate(credentials)
            resetForm({values:''})
        },
    })
    return (

        <section className="page-section" id="contact">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 col-xl-6 text-center">
                        <h2 className="mt-0">Get In Touch</h2>
                        <hr className="divider" />
                        <p className="text-muted mb-5">Need to know more about how ActionAid MILMAP works? Send us a messages and we will get back to you as soon as possible!</p>
                    </div>
                </div>
                <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
                    <div className="col-lg-6">

                        {/* <!-- to get an API token!--> */}
                        <form id="contactForm" onSubmit={formik.handleSubmit}>
                            {/* <!-- Name input--> */}
                            <div className="form-floating mb-3">
                                {/* <input className="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" /> */}
                                <Input
                                    name='full_name'
                                    value={formik.values.full_name}
                                    type='text'
                                    placeholder='Full Name'
                                    label='Full Name'
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    required
                                    error={formik.errors.full_name && formik.touched.full_name ? formik.errors.full_name : null}
                                />
                            </div>
                            {/* <!-- Email address input--> */}
                            <div className="form-floating mb-3">
                                <Input
                                name='email'
                                value={formik.values.email}
                                type='email'
                                placeholder='Email'
                                label='Email'
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                required
                                error={formik.errors.email && formik.touched.email ? formik.errors.email : null}
                            />
                            </div>
                            {/* <!-- Phone number input--> */}
                            <div className="form-floating mb-3">
                                    
            <span style={{ fontSize: 'xx-small' }}>Phone Number (Mobile)</span>
              <PhoneInput
                country={'ng'}
                preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                excludeCountries={['ru', 'nk']}
                value={formik.values.phone_mobile}
                onChange={handlePhoneMobile}
                inputProps={{
                  required: true,
                  type: 'tel',
                  autoComplete: 'tel-national',
                  id: 'phone_mobile',
                  name: 'phone_mobile',
                  inputMode: 'tel',
                  className: 'form-control phone-input',
                }}
                error={
                  formik.errors.phone_mobile && formik.touched.phone_mobile
                    ? formik.errors.phone_mobile
                    : null
                }
              />  
                            </div>
                            {/* <!-- Message input--> */}
                            <div className="form-floating mb-3">
                            <span style={{ fontSize: 'xx-small' }}>Message</span> 
                            <textarea className="form-control"
                            placeholder='Enter your message here...'
                            {...formik.getFieldProps('message')}
                            name='message'
                            style={{ height: "10rem" }}
                            rows={6}
                            error={formik.errors.message && formik.touched.message ? formik.errors.message : null}
                           
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                            />        
                              
                            </div>

                            <div className="d-none" id="submitSuccessMessage">
                                <div className="text-center mb-3">
                                    <div className="fw-bolder">Your requst submission was successful!</div>
                                </div>
                            </div>

                            <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending your request!</div></div>
                            {/* <!-- Submit Button--> */}
                            <div className="d-grid">
                            <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={() => scrollToErrors(formik.errors)}>{(isLoading) && <LoadingIndicator />} Submit</button>
                                {/* <button className="btn btn-primary btn-xl" id="submitButton" type="submit">Submit</button> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
