import React from "react";
import Modals from "components/Modal";
import { FORUM_MGT } from "navigation/routes";
import EnhancedTable from "components/EnhancedTable";
import AddFundRaisingFormAdmin from "./Add";
import EditFundraisingFormAdmin from "./Edit";
import useFundRaisingUser from "hook/useFundRaisingUser";
import { formatMoney } from "utils/functions";
import ViewFundraisingUser from "../user/view";


const FundraiserMgt = () => {
    const { fundraisings, isFundraisingLoading, columns, page, setPage, setData, open, current, setOpen, createFundraisingMutation, formik, currentData, deleteRecordHandler, updateFundraisingMutation, currentView, setCurrentView, selectedCategory, setSelectedCategory, selectedCountry, setSelectedCountry, selectedState, setSelectedState, handleDescriptionChange, fundraisingTypes, MAX_DESCRIPTION, selectedCity, setSelectedCity, charLeft, comment, setComment, handleReject } = useFundRaisingUser()

    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Campaign</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={FORUM_MGT}>Campaign</a></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end mb-2">
                        <button className="btn btn-primary" onClick={() => currentView === 'table' ? setCurrentView('form') : setCurrentView('table')}>{currentView === 'table' ? 'Create Fundraiser Event' : 'Back'}</button>
                    </div>
                </div>
                {currentView === 'table' && <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        My Campaign
                    </div>
                    <div className="card-body">
                        <EnhancedTable columns={columns} data={fundraisings?.data?.fund_raising?.data || []} setData={setData}
                            skipPageReset={false}
                            lastPage={fundraisings?.data?.total}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isFundraisingLoading} />
                    </div>
                </div>}
                {currentView === 'form' && <div className="card mb-4">
                    <AddFundRaisingFormAdmin createFundraisingMutation={createFundraisingMutation} formik={formik} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedState={selectedState} setSelectedState={setSelectedState} handleDescriptionChange={handleDescriptionChange} MAX_DESCRIPTION={MAX_DESCRIPTION} charLeft={charLeft} fundraisingTypes={fundraisingTypes} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
                </div>}
            </div>

            <Modals show={open && current === 'edit'}
                onHide={() => { setOpen(false); formik.resetForm() }}
                title={current === 'add' ? 'Create Forum' : 'Edit Forum'}
                size='md'>
                {currentData ? <EditFundraisingFormAdmin formik={formik} updateFundraisingMutation={updateFundraisingMutation} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedState={selectedState} setSelectedState={setSelectedState} handleDescriptionChange={handleDescriptionChange} MAX_DESCRIPTION={MAX_DESCRIPTION} charLeft={charLeft} fundraisingTypes={fundraisingTypes} selectedCity={selectedCity} setSelectedCity={setSelectedCity} /> : null}
            </Modals>
            <Modals show={open && current === 'view'}
                onHide={() => setOpen(false)} title='View Campaign' size='md'>
                <ViewFundraisingUser id={currentData?.id} />
            </Modals>
            <Modals show={open && current === 'delete'}
                onHide={() => setOpen(false)} title='Delete Campaign' footer={<button type="button" className="btn btn-primary" onClick={deleteRecordHandler}>Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{currentData.name}</b>?</p>}
                </div>
            </Modals>
            <Modals show={open && current === 'rejected'}
                onHide={() => {
                    setOpen(false);
                    setComment('')
                }} title='Reject Fundraising' footer={<>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setOpen(false);
                        setComment('')
                    }}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={handleReject}>Reject</button>
                </>}>
                <div className="striped">
                    {currentData && <div>
                        <p>Are you sure you want to reject this campaign?</p>
                        <p>Name: <b>{currentData.name}</b><br /> Amount: <b>{formatMoney(currentData.amount)}</b></p>
                        <p>If yes please leave a comment below with your reasons otherwise click on the cancel button to exist</p>
                        <textarea type='text' className="form-control" name='comment' value={comment} onChange={e => setComment(e.target.value)} placeholder='Leave your comment here' />
                    </div>}
                </div>
            </Modals>
        </>
    );
}

export default FundraiserMgt;
