import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useIndividualFundraiserReport from "hook/reports/useIndividualFundraiserReport";
import { startCase } from 'lodash'

const IndividualFundRiaserDonation = () => {
  
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, isLoading, current, edit, setEdit } = useIndividualFundraiserReport()
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Campaign Donation Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><a href='{FORUM_MGT}'>Campaigns</a></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Campaign Donations
          </div>
          <div className="card-body">
            <TableTwo
              columns={columns}
              data={data || []} 
              setData={setData}
              skipPageReset={skipPageReset}
              // filterTable={data?.length}
              isLoading={isLoading}
              fileName='campaign-reports'             
            />
          </div>
        </div>
      </div>
      
    </>
  )
}

export default IndividualFundRiaserDonation
