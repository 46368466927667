import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import { DEFAULT_ANALYTICS, DEFAULT_DONATION } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import useCampaignDefaultReport from "hook/reports/useCampaignDefaultReport";
const DefaultCampaignReport = () => { 
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns,data, setData, current, edit, isLoading } = useCampaignDefaultReport()
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Default Campaign Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item">Defaults</li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DEFAULT_DONATION}`} relative="path">Donation Report</Link>
          <Link className="btn btn-primary mb-2" to={`../${DEFAULT_ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Defaults
          </div>
          <div className="card-body">
            <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              isLoading={isLoading}
              fileName='Default-Reports'
            />
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default DefaultCampaignReport
