import { baseURL } from "hook/useAxios";
import useGenericActions from "hook/useGenericAction";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const Services = () => {
    const { getActivities } = useGenericActions()
    const { data, isLoading } = useQuery(['activities', 'generic'], getActivities, {
        select: data => data.data.activity.data
    })

    return (
        <section className="page-section impact-stories" id="impact-stories">
            <div className="container px-4 px-lg-5">
                <h1 className="section-title text-center mt-0">
                    Our Success Stories
                    <span className="subtitle">
                        See how donations and supports from our MILMAP benefactors are being utilised to put smiles on the faces of Nigerians.
                    </span>
                </h1>
                <hr className="divider" />
                <div className="row gx-4 gx-lg-5 posts-wrap mt-5">
                    {/* <Card
                        key={post.id}
                        image={post.featured_image || '/img/placeholder.png'}
                        title={post.title}
                        slug={post.id}
                        description={post.body} />) */}
                    {data?.length ? data.splice(0, 3)?.map(post => <div className="col-lg-4 col-md-6 post" key={post.id}>
                        <div className="post-item">
                            <div className="post-image">
                                <figure>
                                    <img src={post.featured_image || '/img/placeholder.png'} alt={post.title} />
                                </figure>
                            </div>
                            <div className="post-content">
                                {/* <h3 className="post-title mb-2  text-truncate-2-lines"><Link to={`/blog/${post.id}`}>{post.title}</Link></h3> */}
                                <h3 className="post-title mb-2  text-truncate-2-lines">{post.title}</h3>
                                <p className="text-muted mb-0 text-truncate-3-lines">{post.body}</p>

                            </div>
                        </div> 
                    </div>) : ''}

                </div>                
            </div>
            <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                    <Link to="/activity" className='btn btn-hero-alt' >
                                View More
                    </Link>
                    
                    </div>
            </div>
            
        </section>
    );
}

export default Services;
