import Helmet from "react-helmet";
import CommonLayout from "../../components/common/Layout";
import ServicesFull from "../../components/common/ServicesFull";

const BlogPage = () => {
    return (
        <CommonLayout pageText="Our Impact Stories">
            <Helmet>
                <title>Blog | An ActionAid Nigeria’s Community Sponsorship Initiative</title>
            </Helmet>
            <ServicesFull />
        </CommonLayout>
    );
}

export default BlogPage;
