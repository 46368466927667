const WhoWeAre = () => {
    return (
        <section className="page-section bg-grey" id="who-we-are">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h1 className="section-title mt-0">
                            Who We Are
                            <span className="subtitle">
                                ActionAid is an international non-governmental organisation working to defeat poverty and injustice in 47 countries across Africa, Europe, Asia, and the Americas, including the EU.
                            </span>
                        </h1>
                        <p className="mb-5">ActionAid Nigeria is a member of ActionAid global federation. Registered in Nigeria in 1999 as a Country Programme of ActionAid International, we have transformed into an autonomous national organisation with a strong national governance structure – a Board and a General Assembly - consisting of reputable Nigerians providing strategic oversight.</p>
                        {/* <a className="btn btn-hero-alt" href="#impact-stories">Impact Stories</a> */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhoWeAre;
