import { useFormik } from "formik"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useGenericActions from "./useGenericAction"
import * as Yup from 'yup'
import useUserActions from "./useUserActions"
import { notify } from "components/Alerts"
import { fileToDataUri } from "utils/fileToData"
import { useNavigate } from "react-router-dom"
import { FORUM } from "navigation/routes"
import { useRecoilValue } from "recoil"
import { userSelector } from "./state/user"
import ButtonWithTips from "components/ButtonWithTips"
const validationSchema = Yup.object({
  title: Yup.string()
    .required('Field cannot be empty'),
  body: Yup.string()
    .required('Must not be empty'),
  meta: Yup.string()
    .required('Must not be empty'),
  featured_image: Yup.mixed()
    .test('fileSize', 'File too large', (value) => value === null || (value && value.size < 1200000))
    .test(
      'fileFormat',
      'Unsupported file type',
      (value) => value === null || (value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type))
    ),
  post_type: Yup.string(),
  is_active: Yup.number(),
  categories: Yup.array(
    Yup.number().required('at least one category is required'),
  ).min(1),
  tags: Yup.array(
    Yup.string(),
  ),
})
const useForumUser = () => {
  const queryClient = useQueryClient()
  const user = useRecoilValue(userSelector)
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const [current, setCurrent] = React.useState(null)
  const [currentData, setCurrentData] = React.useState(null)
  const [img, setImg] = React.useState(() => currentData?.featured_image || null)
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const {
    createNewForumPosts,
    updateAForumPost,
    deleteAForumPost,
    toggleForumPostStatus } = useUserActions()
  const { getAllCategories, getPosts: getAllForumPosts } = useGenericActions()
  const handleFeaturedImageChange = async (e) => {

    const image = e.target.files[0];
    if (image?.type?.match(/^image\//)) {
      await fileToDataUri(image)
        .then(async (_image) => {
          setImg(_image);
          formik.setFieldValue("featured_image", image);
        })
        .catch((error) => console.log(error))
    }

  };
  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: {
      title: currentData?.title || '',
      body: currentData?.body || '',
      meta: currentData?.meta || '',
      featured_image: currentData?.featured_image || '',
      post_type: currentData?.post_type || 'forum',
      categories: currentData?.categories || [],
      tags: currentData?.tags || [],
    },
    onSubmit: (values) => {
      const formData = new FormData()
      Object.entries(values).map(([key, value]) => key === 'categories' || key === 'tags' ? value?.map((item, i) => formData.append(`${key}[${i}]`, item)) : formData.append(key, value))
      createForumMutation.mutate(formData)
    },
  })

  const { data: forums, isLoading: isForumLoading, isFetching } = useQuery(['forum posts'], getAllForumPosts, {
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })
  const { isLoading: isLoadingCategories, data: categories } = useQuery('categories', getAllCategories, {
    select: (data) => data.data.categories.data,
    onError: err => {
      console.log(err)
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })
  const createForumMutation = useMutation(createNewForumPosts, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setOpen(false)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const updateForumMutation = useMutation(updateAForumPost, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const toggleActiveMutation = useMutation(toggleForumPostStatus, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum posts')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const deleteForumMutation = useMutation(deleteAForumPost, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('forum posts')
      setOpen(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: row => `${row.comments?.length}`,
      },
      {
        Header: 'Desciption',
        accessor: 'meta',
      },
      // {
      //   Header: 'Status',
      //   id: 'status',
      //   accessor: row => row.is_published ? <span className="d-block text-center bg-light-green rounded-pill py-1 px-2 text-white">Published</span> : <span className="d-block text-center bg-red rounded-pill py-1 px-2 text-white">Not Published</span>
      // },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Forum Details" btnProps={{
              className: "btn btn-info", onClick: () => navigate(`${row.row.original?.id}`, {
                state: {
                  id: row.row.original?.id,
                },
              })

            }}><i className="fas fa-eye" /></ButtonWithTips>

            {/* {row.row.original.forumable_id === user.id && <>
              <div className="dropdown">
                <ButtonWithTips id='dropdownMenuButtonTips' tips="Click to perform other actions" btnProps={{
                  className: "btn",
                  'data-toggle': "dropdown",
                  'aria-haspopup': "true",
                  "aria-expanded": "false",
                  type: 'button',
                  id: 'dropdownMenuButton'
                }}><i className="fa fa-ellipsis-v" aria-hidden="true" /></ButtonWithTips>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" onClick={() => {
                    setCurrent('delete')
                    handleclick(row.row.original)
                  }}><i className="fas fa-trash" /> Delete</button>

                  <button className="dropdown-item" onClick={() => {
                    setCurrent('edit')
                    handleclick(row.row.original)
                  }}><i className="fas fa-pencil" /> Edit</button>
                  <button className="dropdown-item" onClick={() => {
                    toggleActiveMutation.mutate({ id: row.row.original.id })
                  }} disabled={toggleActiveMutation.isLoading}><i className={row.row.original.is_active ? "fas fa-eye" : "fas fa-eye-slash"} /> Set as {row.row.original.is_active ? 'In Active' : 'Active'}</button>
                </div>
              </div>
            </>} */}
          </div>
        ),
      }
    ],
    []
  )

  const deleteRecordHandler = () => {
    deleteForumMutation.mutate({ id: currentData?.id })
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  return {
    forums, isForumLoading, columns, isFetching, page, setPage, setData, currentData, current, open, categories, isLoadingCategories, selectedCategory, setSelectedCategory, open, current, setOpen, createForumMutation, formik, setCurrent, img, setImg, handleFeaturedImageChange, currentData, setCurrentData, deleteRecordHandler, updateForumMutation
  }
}

export default useForumUser
