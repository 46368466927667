import { Spinner } from "react-bootstrap";

const LoadingBox = () => {
  return (
    <div className='loading'>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <style>{`
                    .loading{
                        min-height: 100vh;
                        display: grid;
                        place-items: center;
                    }
                `}
      </style>
    </div>
  );
}

export default LoadingBox;
