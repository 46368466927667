import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";

const CommonLayout = ({ img = '/img/milmap-home-hero-bg.jpg', pageText = 'Million Movement of People Against Poverty', children, page = '' }) => {
    return (
        <div id="page-top">
            <Header />
            <Banner img={img} pageText={pageText} page={page} />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    );
}

export default CommonLayout;
