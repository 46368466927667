import { Suspense, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CitySelect from "../../../components/common/Selects/CitySelect";
import CountrySelect from "../../../components/common/Selects/CountrySelect";
import StateSelect from "../../../components/common/Selects/StateSelect";
import AuthLayout from "../../../components/innerComponents/AuthLayout";
import * as Yup from 'yup'
import { useFormik } from "formik";
import Input from "components/innerComponents/Input";
import LoadingIndicator from "components/LoadingIndicator";
import useAuth from "hook/useAuth";
import { useMutation, useQuery } from "react-query";
import { authAtom } from "hook/state/auth";
import { useSetRecoilState } from "recoil";
import { notify } from "components/Alerts";
import { DASHBOARD, SIGNUP_NEXT } from "navigation/routes";
import { scrollToErrors } from "utils/functions";
import { da } from "date-fns/locale";
import YupPassword from 'yup-password';
import Select from "components/innerComponents/Select";
import useDonation from "hook/useDonation";
import PhoneInput from "react-phone-input-2";
YupPassword(Yup)

const CompleteRegistration = () => {
    const navigate = useNavigate()
    const params = useParams()
    const donation_cycles = [
        { id: 1, value: 'one-off', label: 'One Off' },
        { id: 2, value: 'recurrent', label: 'Recurrent' }];
    const [selectedCountry, setSelectedCountry] = useState('')
    const setAuth = useSetRecoilState(authAtom);
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedCycle, setSelectedCycle] = useState('');
    const { registrationComplete,getDetailsByPaymentReference } = useAuth()
    const{
        fundraisingType
      } = useDonation();
    const { mutate, isLoading } = useMutation(registrationComplete, {
        onSuccess: data => {
            setAuth(data.data);
            navigate(`/users/${DASHBOARD}`, { replace: true })
        },
        onError: error => {
            notify(error, { type: 'error' })
        }
    })
    //const { trxref } = useParams();
    
    const location = useLocation()
  const param = new URLSearchParams(location.search);
    const { data } = useQuery(['forum post',param.get('trxref')], () => getDetailsByPaymentReference(param.get('trxref')), {
        select: data => JSON.parse(data.data.transactions.metadata)
      })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            first_name: data?.payment_purpose=="donation"? data?.donor_name.split(' ')[0]: data?.first_name ,
            last_name: data?.payment_purpose=="donation"? data?.donor_name.split(' ')[1]:data?.last_name,
            email:data?.payment_purpose=="donation"? data?.donor_email: data?.email,
            phone_home: data?.phone_home,
            phone_mobile: data?.payment_purpose=="donation"? data?.donor_phone_number:data?.phone_mobile ,
            date_of_birth: '',
            address: params?.address ?? '',
            dob_month: '',
            dob_day: '',
            gender: '',
            country_id: '',
            state_id: '',
            city_id: '',
            referrer: params?.referrer ?? '',
            password: '',
            password_confirmation: '',
        },
        onSubmit: (values) => {
            setAuth(null)
            const credentials = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_home: values.phone_home,
                phone_mobile: values.phone_mobile,
                date_of_birth: `1970-${values.dob_month}-${values.dob_day}`,
                address: values.address,
                gender: values.gender,
                country_id: values.country_id,
                state_id: values.state_id,
                city_id: values.city_id,
                referrer: values.referrer,
                password: values.password,
                password_confirmation: values.password,
                trxref:param.get('trxref'),
                //status_id: 1
            }
            mutate(credentials)
        },
    })

    return (
        <AuthLayout>
            <div className="card shadow-lg border-0 rounded-lg mt-5 mb-5">
                <div className="card-header bg-red text-white"><h3
                    className="text-center font-weight-light my-4">Complete Registration</h3></div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        
                        <Input
                            name='first_name'
                            value={formik.values.first_name}
                            type='text'
                            placeholder='First Name'
                            label='First Name'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            required
                            error={formik.errors.first_name && formik.touched.first_name ? formik.errors.first_name : null}
                        />
                        <Input
                            name='last_name'
                            value={formik.values.last_name}
                            type='text'
                            placeholder='Last Name'
                            label='Last Name'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            required
                            error={formik.errors.last_name && formik.touched.last_name ? formik.errors.last_name : null}
                        />
                        <Input
                            name='email'
                            value={formik.values.email}
                            type='email'
                            placeholder='Email'
                            label='Email'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            required
                            error={formik.errors.email && formik.touched.email ? formik.errors.email : null}
                        />
                        {/* <Input
                            name='phone_mobile'
                            value={formik.values.phone_mobile}
                            maxLength= "17"
                            type='tel'
                            placeholder='Phone Number 1(Mobile)'
                            label='Phone Number 1(Mobile123)'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            required
                            error={formik.errors.phone_mobile && formik.touched.phone_mobile ? formik.errors.phone_mobile : null}
                        /> */}
                        <PhoneInput
                        country={'ng'}
                        preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                        excludeCountries={['ru', 'nk']}
                        onChange={formik.handleChange}
                        value={formik.values.phone_mobile}
                        // isValid={(value, country) => {
                        //     return value.length === 11;
                        //   }}
                        inputProps={{
                          required: true,
                          type: 'tel',
                          autoComplete: 'tel-national',
                          id: 'phone_mobile',
                          name: 'phone_mobile',
                          inputMode: 'tel',
                          className: 'form-control phone-input',
                          maxLength:17,
                          
                        }}
                        
                      />
                        {/* <Input
                            name='phone_home'
                            value={formik.values.phone_home}
                            type='tel'
                            maxLength= "17"
                            placeholder='Phone Number 2(Home-optional)'
                            label='Phone Number 2(Home-optional)'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!!params?.phone_home}                           
                            error={formik.errors.phone_home && formik.touched.phone_home ? formik.errors.phone_home : null}
                        /> */}
                        <br/>
                        <PhoneInput
                        country={'ng'}
                        preferredCountries={['ng', 'gh', 'ke', 'us', 'uk']}
                        excludeCountries={['ru', 'nk']}
                        onChange={formik.handleChange}
                        value={formik.values.phone_home}
                        // isValid={(value, country) => {
                        //     return value.length === 11;
                        //   }}
                        inputProps={{
                          required: true,
                          type: 'tel',
                          autoComplete: 'tel-national',
                          id: 'phone_home',
                          name: 'phone_home',
                          inputMode: 'tel',
                          className: 'form-control phone-input',
                          maxLength:17,
                          
                        }}
                        
                      />
                      <br/>

<div className="mb-3">
            <div className="form-floating">
                <Select id="funding_type"
                name={'funding_type'}
                className="form-select" selected={selectedCycle} 
                handleChange={(e) => {
                  formik.setFieldValue(
                    'funding_type',
                    JSON.parse(e.target.value)?.value
                  );                  
                    setSelectedCycle(JSON.parse(e.target.value))
                }} options={fundraisingType} />
                <label htmlFor="funding_type">Funding Type</label>                
            </div>
            
              {formik.errors.funding_type &&
              formik.touched.funding_type && (
                <small className="error">
                  {formik.errors.funding_type}{' '}
                </small>
              )}
          </div>

          <div className="mb-3">
            <div className="form-floating">
                <Select id="d_cycle"
                name={'donation_cycle'}
                className="form-select" selected={selectedCycle} 
                handleChange={(e) => {
                  formik.setFieldValue(
                    'donation_cycle',
                    JSON.parse(e.target.value)?.value
                  );                  
                    setSelectedCycle(JSON.parse(e.target.value))
                }} options={donation_cycles} />
                <label htmlFor="d_cycle">Donation Cycles</label>                
            </div>
            
              {formik.errors.donation_cycle &&
              formik.touched.donation_cycle && (
                <small className="error">
                  {formik.errors.donation_cycle}{' '}
                </small>
              )}
          </div>

                        <label> Date of birth*</label>
                        <div className="flex gap-3">
                            <div className="mb-3 flex-fill">
                                <div className="form-floating">
                                    <select className="form-select" value={formik.values.dob_month} id="dob_month" name='dob_month' onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value='' disabled >Choose one...</option>
                                        {months.map(month =>
                                            <option value={month.value} key={month.value}>{month.label}</option>
                                        )}
                                    </select>
                                    <label htmlFor="dob_month">Month  </label>
                                </div>
                                {formik.errors.dob_month && formik.touched.dob_month && <small className="error">{formik.errors.dob_month} </small>}
                            </div>
                            <div className="mb-3 flex-fill">
                                <div className="form-floating">
                                    <input className="form-control" value={formik.values.dob_day} id="dob_day" name='dob_day' type="number" min={1} max={31} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <label htmlFor="dob_day">Day  </label>
                                </div>
                                {formik.errors.dob_day && formik.touched.dob_day && <small className="error">{formik.errors.dob_day} </small>}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label> Gender*</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio1" value="male" onChange={(e) => formik.setFieldValue('gender', e.target.value)} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio2" value="female" onChange={(e) => formik.setFieldValue('gender', e.target.value)} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                </div>
                                {formik.errors.gender && formik.touched.gender && <small className="error">{formik.errors.gender} </small>}
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-floating">
                                <Suspense fallback={<LoadingIndicator />} >
                                    <CountrySelect selected={selectedCountry} handleChange={(e) => {
                                        setSelectedCountry(JSON.parse(e.target.value))
                                        formik.setFieldValue('country_id', JSON.parse(e.target.value)?.id)
                                    }} />
                                </Suspense>
                                <label htmlFor="country">Country* </label>
                            </div>
                            {formik.errors.country_id && formik.touched.country_id && <small className="error">{formik.errors.country_id} </small>}
                        </div>
                        <div className="mb-3">
                            <div className="form-floating">
                                <Suspense fallback={<LoadingIndicator />} >
                                    <StateSelect selectedCountry={selectedCountry} selected={selectedState} handleChange={(e) => {
                                        setSelectedState(JSON.parse(e.target.value))
                                        formik.setFieldValue('state_id', JSON.parse(e.target.value)?.id)
                                    }} />
                                </Suspense>
                                <label htmlFor="state">State*</label>
                            </div>
                            {formik.errors.state_id && formik.touched.state_id && <small className="error">{formik.errors.state_id} </small>}
                        </div>
                        <div className="mb-3">
                            <div className="form-floating">
                                <Suspense fallback={<LoadingIndicator />} ><CitySelect selectedState={selectedState} selected={selectedCity} handleChange={(e) => {
                                    setSelectedCity(JSON.parse(e.target.value))
                                    formik.setFieldValue('city_id', JSON.parse(e.target.value)?.id)
                                }} />
                                </Suspense>
                                <label htmlFor="city">City/town*</label>
                            </div>
                            {formik.errors.city_id && formik.touched.city_id && <small className="error">{formik.errors.city_id} </small>}
                        </div>
                        <Input
                            name='address'
                            value={formik.values.address}
                            type='text'
                            placeholder='Address'
                            label='Address'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            error={formik.errors.address && formik.touched.address ? formik.errors.address : null}
                        />
                        <Input
                            name='referrer'
                            value={formik.values.referrer}
                            type='text'
                            placeholder='Referral Code'
                            label='Referral Code'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!!params?.referrer}
                            error={formik.errors.referrer && formik.touched.referrer ? formik.errors.referrer : null}
                        />
                        <Input
                            name='password'
                            value={formik.values.password}
                            type='password'
                            placeholder='Password'
                            label='Password'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            required
                            error={formik.errors.password && formik.touched.password ? formik.errors.password : null}
                        />
                        <Input
                            name='password_confirmation'
                            value={formik.values.password_confirmation}
                            type='password'
                            placeholder='Confirm Password'
                            label='Confirm Password'
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            required
                            error={formik.errors.password_confirmation && formik.touched.password_confirmation ? formik.errors.password_confirmation : null}
                        />
                        <div className="flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={() => scrollToErrors(formik.errors)}>{(isLoading) && <LoadingIndicator />} Create</button>
                        </div>
                    </form>
                </div>

                {/* disabled={isLoading || paySignOnFeeMutation?.isLoading} */}
                <div className="card-footer">
                    Already have an account? <Link className="small" to="/signin">Signin</Link>
                </div>
            </div>
        </AuthLayout>
    );
}

export default CompleteRegistration;
export const months = [{ value: 1, label: 'January' }, { value: 2, label: 'February' }, { value: 3, label: 'March' }, { value: 4, label: 'April' }, { value: 5, label: 'May' }, { value: 6, label: 'June' }, { value: 7, label: 'July' }, { value: 8, label: 'August' }, { value: 9, label: 'September' }, { value: 10, label: 'October' }, { value: 11, label: 'November' }, { value: 12, label: 'December' }];


const validationSchema = Yup.object({
    first_name: Yup.string()
        .matches(
            /^[a-zA-Z0-9\s .!?,"-]+$/,
            'field accepts only string, numbers and hyphens'
        )
        .required('Field cannot be empty'),
    last_name: Yup.string()
        .matches(
            /^[a-zA-Z0-9\s .!?,"-]+$/,
            'field accepts only string, numbers and hyphens'
        )
        .required('Field cannot be empty'),
    email: Yup.string()
        .email('must be valid email')
        .required('Must not be empty'),
    phone_mobile: Yup.string()
        .required('Field cannot be empty')
        .length(13, 'Phone number must be 11 digits only')
        .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
    // phone_home: Yup.string()
    //     .required('Field cannot be empty')
    //     .length(11, 'Phone number must be 11 digits only')
    //     .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
    dob_month: Yup.string()
        .required('Must not be empty'),
    dob_day: Yup.number().when('dob_month', {
        is: (dob_month) => ['1', '3', '5', '7', '8', '10', '12'].includes(dob_month) && !['February'].includes(dob_month),
        then: Yup.number()
            .positive('Must be a positive number')
            .lessThan(32, 'Values must be 31 or less').required('Field is required'),
        otherwise: Yup.number().when('dob_month', {
            is: (dob_month) => ['2'].includes(dob_month),
            then: Yup.number()
                .positive('Must be a positive number')
                .lessThan(30, 'Values must be 29 or less').required('Field is required'),
            otherwise: Yup.number().positive('Must be a positive number')
                .lessThan(31, 'Values must be 30 or less').required('Field is required'),
        })

    }),
    gender: Yup.string().required('Field cannot be empty'),
    address: Yup.string()
        .matches(
            /^[a-zA-Z0-9\s .!?,"-]+$/,
            'field accepts only string, commas, numbers and hyphens'
        ),
        //.required('Field cannot be empty'),
    country_id: Yup.number()
        .required('Must not be empty'),
    state_id: Yup.number()
        .required('Must not be empty'),
    city_id: Yup.number()
        .required('Must not be empty'),
    password: Yup.string()
        .required('Must not be empty') 
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .minLowercase(1, 'password must contain at least 1 lower case letter')
        .minUppercase(1, 'password must contain at least 1 upper case letter')
        .minNumbers(1, 'password must contain at least 1 number')
        .minSymbols(1, 'password must contain at least 1 special character'),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Passwords must match'),
})
