import { Link, useLocation, useSearchParams } from "react-router-dom";
import AuthLayout from "../../../components/innerComponents/AuthLayout";
import { useMutation } from 'react-query';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAuth from "hook/useAuth";
import { notify } from "components/Alerts";
import LoadingIndicator from "components/LoadingIndicator";
import { ADMIN_SIGNIN, SIGNIN } from "navigation/routes";
import { scrollToErrors } from "utils/functions";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Must be valid email')
        .required('Must be valid email'),
    password: Yup.string()
        .min(8, 'Password cannot be less than 8 characters')
        .required('Password cannot be empty'),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Passwords must match'),
})

const ResetPassword = () => {
    const location = useLocation()
    const [params] = useSearchParams()
    const routeCheck = location.pathname.includes('admin')
    const { resetPasswordAdmin, resetPasswordUser } = useAuth()

    const { mutate, isLoading } = useMutation(routeCheck ? resetPasswordAdmin : resetPasswordUser, {
        onSuccess: (data) => {
            // setEmail(null)
            notify(data.message, { type: 'success' })
            window.location.replace(SIGNIN)
        },
        onError: (error) => {
            notify(error, { type: 'error' })
        }
    })

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: '',
            password_confirmation: '',
        },
        onSubmit: (values) => {
            const credentials = {
                ...values,
                token: params?.get('token'),
                callbackContactUrl: 'http://localhost:8080/admin/resetPassword'
            }

            mutate(credentials)
        },
    })

    return (
        <AuthLayout>
            <div className="card shadow-lg border-0 rounded-lg mt-5 mb-5">
                <div className="card-header bg-red text-white"><h3
                    className="text-center font-weight-light my-4">Password Recovery</h3>
                </div>
                <div className="card-body">
                    <div className="small mb-3 text-muted">Complete your details to reset your password.
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            <div className="form-floating">
                                <input className="form-control" id="inputEmail" name="email" type="email"
                                    placeholder="name@example.com" value={formik.values.email} onChange={formik.handleChange} />
                                <label htmlFor="inputEmail">Email address</label>
                            </div>
                            {(formik.errors.email ||
                                formik.touched.email) && <small className="error">{formik.errors.email} </small>}
                        </div>
                        <div className="mb-3">
                            <div className="form-floating">
                                <input className="form-control" id="newPassword" name="password" type="password"
                                    placeholder="name@example.com" value={formik.values.password} onChange={formik.handleChange} />
                                <label htmlFor="newPassword">New Password</label>
                            </div>
                            {(formik.errors.password ||
                                formik.touched.password) && <small className="error">{formik.errors.password} </small>}
                        </div>
                        <div className="mb-3">
                            <div className="form-floating">
                                <input className="form-control" name='password_confirmation' id="password_confirmation" type="password"
                                    placeholder="name@example.com" value={formik.values.password_confirmation} onChange={formik.handleChange} />
                                <label htmlFor="password_confirmation">Confirm Password</label>
                            </div>
                            {(formik.errors.password_confirmation ||
                                formik.touched.password_confirmation) && <small className="error">{formik.errors.password_confirmation} </small>}
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <Link className="small" to={routeCheck ? ADMIN_SIGNIN : SIGNIN}>Return to login</Link>
                            <button className="btn btn-primary" disabled={isLoading} type="submit" onClick={() => scrollToErrors(formik.errors)}>{isLoading && <LoadingIndicator />}Reset
                                Password</button>
                        </div>

                    </form>
                </div>
            </div>
        </AuthLayout>
    )
}
export default ResetPassword;
