import { aaidUser, callHistory, newAdvert, range } from "./datamodels"


export default function makeData(type, ...lens) {
    const makeDataLevel = (depth = 0) => {
        const len = lens[depth]
        return range(len).map((d, i) => {
            switch (type) {
                case 'advert':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'call history':
                    return {
                        id: i + 1,
                        ...callHistory(),
                    }
                case 'aausers':
                    return {
                        id: i + 1,
                        ...aaidUser(),
                    }
                case 'users':
                case 'volunteers':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'out calls':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'incalls':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'call record':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'sms records':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'donations':
                case 'donation':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'donors':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'forum mgt':
                case 'forum':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'reporting':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'activities':
                case 'fundraiser':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                case 'network':
                    return {
                        id: i + 1,
                        ...newAdvert(),
                    }
                default:
                    return null
            }
        })
    }

    return makeDataLevel()
}
