import CitySelect from 'components/common/Selects/CitySelect';
import CountrySelect from 'components/common/Selects/CountrySelect';
import StateSelect from 'components/common/Selects/StateSelect';
import Input from 'components/innerComponents/Input';
import Select from 'components/innerComponents/Select';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { Suspense } from 'react'
import { scrollToErrors } from "utils/functions";

const AddFundraisingFormUser = ({ createFundraisingMutation, formik, selectedCategory, setSelectedCategory, selectedCountry, setSelectedCountry, selectedState, setSelectedState, handleDescriptionChange, MAX_DESCRIPTION, charLeft, fundraisingTypes, selectedCity, setSelectedCity }) => {

  return (
    <form onSubmit={(e) => formik.handleSubmit(e)}>
      <div className="card-header">
        <i className="fas fa-table me-1"></i>
        Create Campaign
      </div>
      <div className="card-body">
        <div className='row'>
          <div className="form-group mb-3 col-lg-6">
            <Input
              name='name'
              value={formik.values.name}
              type='text'
              placeholder='Name'
              label='Name'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.name && formik.touched.name ? formik.errors.name : null}
            />
          </div>
          <div className='col-lg-6 mb-3'>
            <div className="form-floating">
              <Select id="type_id" className="form-select" selected={selectedCategory} handleChange={(e) => {

                setSelectedCategory(JSON.parse(e.target.value));
                formik.setFieldValue('type_id', JSON.parse(e.target.value)?.value)
              }} options={fundraisingTypes} />
              <label htmlFor="type_id">Campaign Type</label>
            </div>
            {formik.errors.type_id && formik.touched.type_id && <small className="error">{formik.errors.type_id} </small>}
          </div>

          <div className="col-lg-6 mb-3">
            <div className="form-floating">
              <Suspense fallback={<LoadingIndicator />} >
                <CountrySelect selected={selectedCountry} handleChange={(e) => {
                  setSelectedCountry(JSON.parse(e.target.value))
                  formik.setFieldValue('country_id', JSON.parse(e.target.value)?.id)
                }} />
              </Suspense>
              <label htmlFor="country">Country </label>
            </div>
            {formik.errors.country_id && formik.touched.country_id && <small className="error">{formik.errors.type_id} </small>}
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-floating">
              <Suspense fallback={<LoadingIndicator />} >
                <StateSelect selectedCountry={selectedCountry} selected={selectedState} handleChange={(e) => {
                  setSelectedState(JSON.parse(e.target.value))
                  formik.setFieldValue('state_id', JSON.parse(e.target.value)?.id)
                }} />
              </Suspense>
              <label htmlFor="state">State</label>
            </div>
            {formik.errors.state_id && formik.touched.state_id && <small className="error">{formik.errors.state_id} </small>}
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-floating">
              <Suspense fallback={<LoadingIndicator />} >
                <CitySelect selectedState={selectedState} selected={selectedCity} handleChange={(e) => {
                  setSelectedCity(JSON.parse(e.target.value))
                  formik.setFieldValue('city_id', JSON.parse(e.target.value)?.id)
                }} />
              </Suspense>
              <label htmlFor="city">City/town</label>
            </div>
            {formik.errors.city_id && formik.touched.city_id && <small className="error">{formik.errors.city_id} </small>}
          </div>

          <div className=' col-lg-6'>
            <label htmlFor="amount">Amount</label>
            <div className="input-group mb-3 col-lg-6">
              <div className="input-group-prepend">
                <span className="input-group-text" id="amount">₦</span>
              </div>
              <input type="number" className="form-control" placeholder="1,000,000" aria-label="amount" aria-describedby="amount" name='amount' value={formik.values.amount} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {formik.errors.amount && formik.touched.amount && <small className="error">{formik.errors.amount} </small>}
            </div>
          </div>
          <div className="form-group mb-3 col-lg-6">
            <Input
              name='due_date'
              value={formik.values.due_date}
              type='date'
              placeholder='Donation Due Date'
              label='Donation Due Date'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.due_date && formik.touched.due_date ? formik.errors.due_date : null}
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="description">Description</label>
          <textarea className="form-control" id="description" name='description' rows={6} value={formik.values.description} maxLength={MAX_DESCRIPTION} onChange={handleDescriptionChange}
          />
          <small>{charLeft} characters remaining out of{' '}
            {MAX_DESCRIPTION}</small>
          {formik.errors.description && formik.touched.description && <small className="error">{formik.errors.description} </small>}
        </div>
      </div>
      <div className="card-footer">
        <button type="submit" onClick={() => scrollToErrors(formik.errors)} className="btn btn-primary" disabled={createFundraisingMutation.isLoading}>{createFundraisingMutation.isLoading && <LoadingIndicator />} Create</button>
      </div>
    </form>
  )
}

export default AddFundraisingFormUser
