import useAxios from "./useAxios";

const useUserActions = () => {
  const axios = useAxios()


  return {
    getNetwork,
    getFundraisings,
    createNewFundraising,
    getAFundraising,
    updateAFundraising,
    deleteAFundraising,
    getActivities,
    createNewActivity,
    getAnActivity,
    updateAnActivity,
    deleteAnActivity,
    getAllForumPosts,
    createNewForumPosts,
    getAForumPost,
    updateAForumPost,
    deleteAForumPost,
    toggleForumPostStatus,
    getUserDonations,
    getUserDonationDashboard,
    getUserDonationsOnly,
    updateUserProfile,
    getUserProfile,
    updateUserPassword,
    updateUserProfilePicture,
    getUserNotificationsOnly,

    getUserChatRoom,
    getAllChatMessage,
    createMessage,
    getUnreadNotification,
    markNotificationAsRead,
    dashboard
  }

  function dashboard() {
    return axios.get(`/user/dashboard`)
  }
  // My Network
  function getNetwork() {
    return axios.get(`/user/networks`)
  }

  // FundRaising
  function getFundraisings() {
    return axios.get(`/user/fund-raisings?include=user,country,state,city`)//TODO include type
  }
  function createNewFundraising(payload) {
    return axios.post(`/user/fund-raisings`, payload)
  }

  function getAFundraising(id) {
    return axios.get(`/user/fund-raisings/${id}?include=user,country,state,city`)
  }

  function updateAFundraising(payload) {
    return axios.post(`/user/fund-raisings/${payload.get('id')}`, payload)
  }

  function deleteAFundraising({ id }) {
    return axios.delete(`/user/fund-raisings/${id}`)
  }

  // Activities
  function getActivities() {
    return axios.get(`/user/activities`)
  }
  function createNewActivity(payload) {
    return axios.post(`/user/activities`, payload)
  }

  function getAnActivity(id) {
    return axios.get(`/user/activities/${id}`)
  }

  function updateAnActivity(payload) {
    return axios.post(`/user/activities/${payload.get('id')}`, payload)
  }

  function deleteAnActivity({ id }) {
    return axios.delete(`/user/activities/${id}`)
  }

  // Forum Post
  function getAllForumPosts() {
    return axios.get(`/user/forums?include=categories,tags`)
  }
  function createNewForumPosts(payload) {
    return axios.post(`/user/forums`, payload)
  }

  function getAForumPost(id) {
    return axios.get(`/user/forums/${id}?include=categories,comments,comments.user,comments.replies,comments.replies.user`)
  }

  function updateAForumPost(payload) {
    return axios.post(`/user/forums/${payload.get('id')}`, payload)
  }

  function deleteAForumPost({ id }) {
    return axios.delete(`/user/forums/${id}`)
  }

  function toggleForumPostStatus({ id }) {
    return axios.patch(`/user/forums/${id}/toggle-active`)
  }


  // Donation
  function getUserDonations() {
    return axios.get(`/user/my-donations`)
  }

  function getUserDonationDashboard() {
    return axios.get(`/user/my-donations-dashboard`)
  }

  function getUserDonationsOnly() {
    return axios.get(`/user/my-donations`)
    // return axios.get(`/user/my-donationsonly`)
  }
  function getUserNotificationsOnly() {
    return axios.get(`/user/my-notification`)
  }
  //PROFILE UPDATE
  function getUserProfile() {
    return axios.get(`/user/profile`)
  }

  function updateUserProfile(payload) {
    return axios.put(`/user/profile`, payload)
  }

  function updateUserProfilePicture(payload) {
    return axios.post(`/user/profile/update-profile-picture`, payload)
  }

  function updateUserPassword(payload) {
    return axios.post(`/user/change-password`, payload)
  }

  function getUserChatRoom(page = 1) {
    return axios.get(`/user/chat/rooms`);
  }

  function getAllChatMessage(roomId) {
    console.log("IIIIIIDDDDDD", roomId)
    return axios.get(`/user/chat/rooms/${roomId}/messages`);
  }

  function createMessage(payload) {
    const {data, id} = payload
    return axios.post(`/user/chat/rooms/${id}/message`, data);
  }
  function getUnreadNotification(page = 1) {
    return axios.get(`/user/chat/rooms/1/unread-messages?page=${page}`);
  }
  function markNotificationAsRead(page = 1) {
    return axios.get(`/user/chat/rooms/1/mark-as-read?page=${page}`);
  }

}

export default useUserActions;
