import useAdminActions from "hook/useAdminActions";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { notify } from "components/Alerts";
import { useRecoilValue } from "recoil";
import { userSelector } from "hook/state/user";
import { toStartCase } from "utils/functions";
import ButtonWithTips from "components/ButtonWithTips";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .required('Must not be empty'),
  last_name: Yup.string()
    .required('Must not be empty'),
  email: Yup.string()
    .email('must be valid email')
    .required('Must not be empty'),
  state_id: Yup.string()
    .required('Must not be empty'),
  city_id: Yup.string()
    .required('Must not be empty'),
  roles: Yup.array().min(1).required('at least one item needs to be here'),

})
const useAaidUserManagement = () => {
  const queryClient = useQueryClient()
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [data, setData] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [skipPageReset] = React.useState(false)
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [edit, setEdit] = React.useState(false)
  const [roles, setRoles] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [shouldEditRole, setShouldEditRole] = React.useState(false)
  const { assignRoleToAdminUser, deleteAnAdminUser, getAllRoles, getAllAdminUsers, createAnAdminUser, getAnAdminUser, updateAnAdminUser } = useAdminActions()
  const user = useRecoilValue(userSelector)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => toStartCase(`${row.first_name} ${row.last_name}`),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Contact no',
        accessor: 'phone',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">

            <ButtonWithTips id={`view`} tips="View User Details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>

            <ButtonWithTips id={`delete`} tips="delete User" btnProps={{
              className: "btn btn-primary", onClick: () => {
                setCurrent('delete')
                handleclick(row.row.original)
              },
              disabled: row.row.original?.id === 1 || row.row.original?.id === user?.id
            }}><i className="fas fa-trash" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )

  const { isLoading: isDataLoading } = useQuery(['roles'], getAllRoles, {
    onSuccess: (res) => {
      const _roles = res.data.roles.data?.filter(item => {
        return item.id !== 1
      })
      const roleOpt = _roles?.map(item => {
        return { id: item.id, value: item.id, label: item.name }
      })
      setRoles(roleOpt)
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const { data: allAdmins, isLoading: isLoadingAdmins, isFetching: isFetchingAdmins } = useQuery(['admins', page], () => getAllAdminUsers(page), {
    onSuccess: (res) => {
      setData(res.data.admins.data)
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const { data: singleAdmin, isLoading: isSingleAdminLoading } = useQuery(['admins', 'id', currentData?.id], () => getAnAdminUser(currentData?.id), {
    select: data => data.data.admin,
    onError: err => {
      notify(err.message, { type: 'error' })
    },
    staleTime: Infinity,
    enabled: current === 'view'
  })

  const { mutate: createAdminMutation, isLoading: createAdminLoading } = useMutation(createAnAdminUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('admins')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const { mutate: updateAdminMutation, isLoading: updateAdminLoading } = useMutation(updateAnAdminUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('admins')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const { mutate: deleteAdminMutation, isLoading: deleteAdminLoading } = useMutation(deleteAnAdminUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('admins')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const { mutate: updateAdminRoleMutation, isLoading: updateAdminRoleLoading } = useMutation(assignRoleToAdminUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('admins')
      setShouldEditRole(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const updateMyData = () => {
    const details = {
      id: currentData.id,
      first_name: currentData.first_name,
      last_name: currentData.last_name,
      email: currentData.email,
      address: currentData.address,
      _method: 'PUT',
    }
    updateAdminMutation(details)
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const handleChangeRoles = async (e) => {
    if (e.length) {
      const roles = e.map(item => item.value)
      formik.setFieldValue('roles', roles)
    } else {
      formik.setFieldValue('roles', e)
    }
  }

  const handleUpdateRoles = async (e) => {
    setCurrentData({ ...currentData, role_id: e.id })
  }

  const handleAssignRoles = async () => {
    updateAdminRoleMutation({
      id: currentData?.id, role_id: currentData?.role_id
    })
  }

  const getDefaultValues = (arr) => {
    const permOpt = arr.map(item => {
      return { id: item.id, value: item.id, label: item.name }
    })
    return permOpt
  }

  const formik = useFormik({
    validationSchema,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      country_id: '',
      state_id: '',
      city_id: '',
      roles: [],
    },
    onSubmit: (values) => {
      const credentials = {
        ...values,
        callbackUrl: `${window.location.origin}/admin/reset-password`
      }
      createAdminMutation(credentials)
    },
  })

  return {
    shouldEditRole, setShouldEditRole,
    user,
    handleUpdateRoles,
    handleAssignRoles,
    formik, getDefaultValues, handleChangeRoles, updateMyData, updateAdminRoleLoading, updateAdminLoading, deleteAdminMutation, deleteAdminLoading, createAdminLoading, isSingleAdminLoading, singleAdmin, allAdmins, isLoadingAdmins, isFetchingAdmins, isDataLoading, columns, open, setOpen, roles, edit, setEdit, current, setCurrent, currentData, setCurrentData, skipPageReset, page, setPage, data, setData, selectedCity, setSelectedCity, selectedState, setSelectedState, selectedCountry, setSelectedCountry
  }
}

export default useAaidUserManagement
