const Banner = ({ img, pageText, page }) => {
    return (
        <header className="banner" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, ${page === 'donate' ? '0.1' : '0.7'}) 0%, rgba(28, 28, 28,  ${page === 'donate' ? '0.2' : '0.8'}) 100%), url(${img})` }}>
            <div className="container px-4 px-lg-5 h-100">
                <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div className="col-lg-8 align-self-end">
                        {page !== 'donate' && <><h1 className="text-white font-weight-bold">{pageText}</h1>
                            <hr className="divider" /></>}
                    </div>

                </div>
            </div>
        </header>
    );
}

export default Banner;
