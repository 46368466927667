import React from 'react';
import PropTypes from 'prop-types';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useAsyncDebounce,
  useFilters,
} from 'react-table';
import Filters from './innerComponents/Filters/DateFilter';
import zipcelx from 'zipcelx';
import LoadingIndicator from './LoadingIndicator';
import { isEmpty } from 'lodash';
import ButtonWithTips from './ButtonWithTips';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className="form-control"
        placeholder={`Search ${count} records...`}
      />
    </span>
  );
}
function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = new Date(filterValues[0]);
  let ed = new Date(filterValues[1]);
  return rows.filter((r) => {
    var time = new Date(r.values[id]);
    if (filterValues.length === 0) return rows;
    return time >= sd && time <= ed;
  });
}

// dateBetweenFilterFn.autoRemove = val => !val;
export default function TableUser({
  columns,
  data,
  setData,
  isLoading,
  skipPageReset,
  deleteRecords,
  pagesize,
  filterTable,
  fileName,
}) {
  const filterTypes = React.useMemo(
    () => ({
      dateFilter: dateBetweenFilterFn,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    setFilter,
    dispatch,
    state: {
      pageIndex,
      pageSize,
      selectedRowIds,
      globalFilter: globalFilterState,
    },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      autoResetPage: !skipPageReset,
      initialState: { pageSize: pagesize || 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              {deleteRecords && (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              )}
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              {deleteRecords && (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )}
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteRecordHandler = (event) => {
    const newData = removeByIndexs(
      data,
      Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
    );
    setData(newData);
  };

  function getHeader(column) {
    if (column.totalVisibleHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: 'string',
        },
      ];
    } else {
      const span = [...Array(column.totalVisibleHeaderCount - 1)].map((x) => ({
        value: '',
        type: 'string',
      }));
      return [
        {
          value: column.Header,
          type: 'string',
        },
        ...span,
      ];
    }
  }

  function getExcel() {
    const config = {
      filename: fileName,
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          if (column.id !== 'selection' && column.id !== 'action') {
            headerRow.push(...getHeader(column));
          }
        });
      }

      dataSet.push(headerRow);
    });

    // FILTERED ROWS
    if (rows.length > 0) {
      rows.forEach((row) => {
        const dataRow = [];

        Object.entries(row.values).forEach(([key, value]) =>
          key === 'action' || key === 'selection'
            ? []
            : dataRow.push({
                value,
                type: typeof value === 'number' ? 'number' : 'string',
              })
        );

        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: 'No data',
          type: 'string',
        },
      ]);
    }

    return zipcelx(config);
  }

  return (
    <div className="table-responsive">
      
      <div className="d-flex align-items-center justify-content-end gap-2 mb-2">
        {deleteRecords && (
          <span title="Select record(s) to delete">
            <ButtonWithTips
              id="detBtn"
              tips="Click to delete Records"
              btnProps={{
                disabled: Object.keys(selectedRowIds).length <= 0,
                className: 'btn btn-primary',
                onClick: deleteRecordHandler,
              }}
            >
              Delete Selected
            </ButtonWithTips>
          </span>
        )}
      </div>
      {Object.keys(selectedRowIds).length > 0 && (
        <p>
          {Object.keys(selectedRowIds).length} record(s) selected
          {/* {selectedFlatRows.map(
                        d => d.original.firstName
                    ).join(', ')} selected */}
        </p>
      )}
      <table className="table table-striped" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} style={{ fontSize: '14px' }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      {isLoading ? (
        <LoadingIndicator />
      ) : pageOptions.length > 0 ? (
        <div className="pagination">
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          {pageOptions.length > 1 && (
            <>
              {' '}
              <span>
                Go to page:{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '}
              <span className="pagintion-btns">
                <button
                  className="page-link"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {'<<'}
                </button>{' '}
                <button
                  className="page-link"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {'<'}
                </button>{' '}
                <button
                  className="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {'>'}
                </button>{' '}
                <button
                  className="page-link"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </button>{' '}
              </span>
            </>
          )}
        </div>
      ) : (
        <p className="text-center">No entries found</p>
      )}
    </div>
  );
}
TableUser.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  deleteRecords: PropTypes.bool,
  filterTable: PropTypes.bool,
  isLoading: PropTypes.bool,
  fileName: PropTypes.string,
};
