import React, { useEffect, useState } from "react";
import AdminBarChart from "../../../components/charts/AdminBarChart";
import Table from "../../../components/TableTwo";
import makeData from "../../../utils/makeData";
import useAdminActions from "hook/useAdminActions";
import { useQuery } from "react-query";
import { formatMoney } from "utils/functions";
import { format } from "date-fns";
import AdminLineChart from "components/charts/AdminLineChart";

const Dashboard = () => {
    const {dashboard,dailyDonation } =useAdminActions(); 
    const [data, setData] = React.useState(React.useMemo(() => makeData('call history', 30), []))
    const [skipPageReset, setSkipPageReset] = React.useState(false)
// debugger
    const { data: history } = useQuery(['history'], dashboard, {
        select: (data) => data.data,
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })

    const {  data: graphData } = useQuery(['donations', 'graphData'], dailyDonation, {
        select: (data) => data.data,
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })

    
    //const member =history?.category?.filter((x=>x.category_id==1)).map((x)=>x.total)[0];
    // console.log(member)
    // useEffect(() =>{
    //     dashboard().then(resp =>{
    //         setDash(resp);
    //         var g=0;
    //     });
    // });
    // const { dash: allAdmins, isLoading: isLoadingAdmins, isFetching: isFetchingAdmins } = useQuery(['admins'], () => dashboard(), {
    //     onSuccess: (res) => {
    //       setData(res.data.admins.data)
    //     },
    //     onError: err => {
    //       console.log(err)
    //       // notify('post creation failed!', { type: 'error' })
    //     },
    //     staleTime: Infinity,
    //     cacheTime: Infinity
    //   })

    // const [dash, setDash] = dashboard().then(resp =>{
    //     setDash(resp);
    //     var g=0;
    // });
    const columnCalls = React.useMemo(
        () => [ 
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Duration(mm:ss)',
                accessor: 'duration',
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Time',
                accessor: 'time'
            }
        ],
        []
    )
    const columnMessages = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Subject',
                accessor: 'subject',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Date',
                accessor: 'messagedate'
            }
        ],
        []
    )
    return (
        <>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Dashboard</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">Dashboard</li>
                </ol>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-primary text-white mb-4">
                            <div className="card-header">Current Highest Donation
                            </div>
                            <div className="card-body flex flex-column">
                                <span className="small text-white stretched-link">Name:  {history?.highest?.user?.full_name} </span>
                                <span className="small text-white stretched-link">Amount: { formatMoney(history?.highest?.amount)}</span>
                                <span className="small text-white stretched-link">Date: {format(new Date(history?.highest ? history.highest.created_at : Date()), 'MMM dd yyyy')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-warning text-white mb-4">
                            <div className="card-header">Highest Sign-up Region</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Name: {history?.state?.state?.name}</span>
                                <span className="small text-white stretched-link">Number: {history?.state?.total}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-success text-white mb-4">
                            <div className="card-header">Users Count</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Active Users: 150</span>
                                <span className="small text-white stretched-link">Inactive Users: 2</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-danger text-white mb-4">
                            <div className="card-header">Users Breakdown</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Members: { history?.category?.filter((x=>x.category_id==1)).map((x)=>x.total)[0] }</span>
                                <span className="small text-white stretched-link">Champions :{history?.category?.filter((x=>x.category_id==2)).map((x)=>x.total)[0]}</span>
                                <span className="small text-white stretched-link">Patriots : { history?.category?.filter((x=>x.category_id==3)).map((x)=>x.total)[0]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-area me-1"></i>
                                Donations Per day
                            </div>
                            <div className="card-body">
                                <AdminLineChart data={graphData}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-bar me-1"></i>
                                Calls per month
                            </div>
                            <div className="card-body">
                                <AdminBarChart />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Call History
                    </div>
                    <div className="card-body">
                        <Table
                            columns={columnCalls}
                            data={data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                        />
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Messages History
                    </div>
                    <div className="card-body">
                        <Table
                            columns={columnMessages}
                            data={data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                        />
                        {/* <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Subject</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Name</th>
                                    <th>Subject</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td>Tiger Nixon</td>
                                    <td>Donation recived</td>
                                    <td>SMS</td>
                                    <td>2011/04/25 09:10:30 AM</td>
                                </tr>
                                <tr>
                                    <td>Tiger Woods</td>
                                    <td>Post approved</td>
                                    <td>Email</td>
                                    <td>2011/04/25 09:10:30 AM</td>
                                </tr>
                            </tbody>
                        </Table> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
