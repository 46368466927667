import React from "react";
import Modals from "components/Modal";
import { FORUM_MGT } from "navigation/routes";
import EnhancedTable from "components/EnhancedTable";
import useActivityAdmin from "hook/useActivityAdmin";
import EditActivityFormAdmin from "./EditActivity";
import AddActivityFormAdmin from "./AddActivity";
import ViewActivity from "../user/viewActivities";


const ActivityMgt = () => {
    const { activities, columns, page, setPage, setData, open, current, setCurrent, setOpen, deleteRecordHandler, currentData, setCurrentData, formik, img, isActivityLoading, comment, setComment, handleReject } = useActivityAdmin()

    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Activity Management</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={FORUM_MGT}>Activity</a></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end mb-2">
                        <button className="btn btn-primary" onClick={() => {
                            setCurrent('add')
                            setOpen(true)
                        }}>Create Activity</button>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Activitys
                    </div>
                    <div className="card-body">
                        <EnhancedTable columns={columns} data={activities?.data?.activity?.data || []} setData={setData}
                            skipPageReset={false}
                            lastPage={page}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isActivityLoading} />
                    </div>
                </div>
            </div>

            <Modals show={open && (current === 'add' || current === 'edit')}
                onHide={() => { setOpen(false); formik.resetForm() }}
                title={current === 'add' ? 'Create Activity' : 'Edit Activity'}
                size='md'>
                {currentData && current === 'edit' ? <EditActivityFormAdmin data={currentData} setData={setCurrentData} img={img} /> : <AddActivityFormAdmin />}
            </Modals>
            <Modals show={open && current === 'view'}
                onHide={() => setOpen(false)} title='View Activity' size='md'>
                <ViewActivity id={currentData?.id} />
            </Modals>
            <Modals show={open && current === 'delete'}
                onHide={() => setOpen(false)} title='Delete Advert' footer={<button type="button" className="btn btn-primary" onClick={deleteRecordHandler}>Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{currentData.poster}</b>?</p>}
                </div>
            </Modals>

            <Modals show={open && current === 'rejected'}
                onHide={() => {
                    setOpen(false);
                    setComment('')
                }} title='Reject Fundraising' footer={<>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setOpen(false);
                        setComment('')
                    }}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={handleReject}>Reject</button>
                </>}>
                <div className="striped">
                    {currentData && <div>
                        <p>Are you sure you want to reject this Activity?</p>
                        <p>Name: <b>{currentData.tagline}</b></p>
                        <p>If yes please leave a comment below with your reasons otherwise click on the cancel button to exist</p>
                        <textarea type='text' className="form-control" name='comment' value={comment} onChange={e => setComment(e.target.value)} placeholder='Leave your comment here' />
                    </div>}
                </div>
            </Modals>
        </>
    );
}

export default ActivityMgt;
