import { set } from "date-fns";
import React from "react";
import Modals from "../../../components/Modal";
// import Modal from "../../../components/Modal";
// import Table from "../../../components/Table";
import TableTwo from "../../../components/TableTwo";
import { FORUM_MGT } from "../../../navigation/routes";
import makeData from "../../../utils/makeData";
import { startCase } from 'lodash'


const Advert = () => {
    const [data, setData] = React.useState(React.useMemo(() => makeData('advert', 100), []))

    const columns = React.useMemo(
        () => [
            {
                Header: 'Poster',
                accessor: 'poster',
            },
            {
                Header: 'Tagline',
                accessor: 'tagline',
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => (
                    <div className="flex flex-wrap gap-2 w-100">
                        <button className="btn btn-info" onClick={e => {
                            setCurrent('view')
                            handleclick(row.row.original)
                        }} data-toggle="modal" data-target="#viewmodal"><i className="fas fa-eye" /></button>
                        <button className="btn btn-primary" onClick={e => {
                            setCurrent('delete')
                            handleclick(row.row.original)
                        }} data-toggle="modal" data-target="#deletemodal"><i className="fas fa-trash" /></button>
                    </div>
                ),
            }
        ],
        []
    )
    const [currentData, setCurrentData] = React.useState(null)
    const [current, setCurrent] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [edit, setEdit] = React.useState(false)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const updateMyData = () => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        setData(old =>
            old.map((row) => {
                if (row.id === currentData.id) {
                    return {
                        ...currentData
                    }
                }
                return row
            })
        )

        setOpen(false)
    }

    const handleclick = (obj) => {
        setOpen(true)
        setCurrentData(obj)
    }
    const removeByIndexs = (array, index) =>
        array.filter((item) => item.id !== index)

    const deleteRecordHandler = event => {
        const newData = removeByIndexs(
            data,
            currentData?.id
        )
        setData(newData)

        setOpen(false)
    }
    // const addRecordHandler = record => {
    //     const newData = data.concat([record])
    //     setData(newData)
    // }
    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Advert Management</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={FORUM_MGT}>Adverts</a></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end mb-2">
                        <button className="btn btn-primary" onClick={() => {
                            setCurrent('add')
                            setOpen(true)
                        }}>Create Advert</button>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Adverts
                    </div>
                    <div className="card-body">
                        <TableTwo
                            columns={columns}
                            data={data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                            deleteRecords={true}
                        />
                    </div>
                </div>
            </div>
            <Modals show={open && current === 'add'}
                onHide={() => setOpen(false)} size={'md'} title='Create new advert' footer={<button type="button" className="btn btn-primary">Create</button>}>
                <div className="form-floating mb-3">
                    <input className="form-control" id="topic" name='topic' type="text"
                        placeholder="My new topic" />
                    <label htmlFor="topic">Title</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="tags" name='tags' type="text"
                        placeholder="My new tags" />
                    <label htmlFor="tags">Tagline</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="body" name='body'
                        placeholder="My new body" type={'number'} />
                    <label htmlFor="body">Duration(in days)</label>
                </div>
                <div className="mb-3">
                    <label htmlFor="media">Media</label>
                    <input type="file" className="form-control" id="media" aria-describedby="media" aria-label="Upload" />
                </div>
            </Modals>
            <Modals show={open && current === 'view'}
                onHide={() => setOpen(false)} title='View Advert' size='md' footer={<button type="button" className="btn btn-primary" onClick={() => {
                    if (edit) {
                        setEdit(false)
                        updateMyData()
                    } else {
                        setEdit(true)
                    }
                }}>{edit ? 'Update' : 'Edit'}</button>}>
                <dl className="striped">
                    {currentData && Object.entries(currentData)?.map(([key, value]) =>
                        <div key={key} className='row'>
                            <dt className='col-4'>{`${startCase(key)} :`}</dt>
                            {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
                            {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
                        </div>)}
                </dl>
            </Modals>
            <Modals show={open && current === 'delete'}
                onHide={() => setOpen(false)} title='Delete Advert' footer={<button type="button" className="btn btn-primary" onClick={deleteRecordHandler}>Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{currentData.poster}</b>?</p>}
                </div>
            </Modals>

        </>
    );
}

export default Advert;
