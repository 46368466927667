import Modals from 'components/Modal';
import { authAtom } from 'hook/state/auth';
import { userSelector } from 'hook/state/user';
import useAuth from 'hook/useAuth';
import { SIGNIN } from 'navigation/routes';
import React, { Component, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Header from "../Navbar";
import { navAdminMenu, navUserMenu } from './menu';
import { isArray } from 'lodash'

const navChildren = (items) => {
    return items.map(item => item.href)
}


const Sidebar = ({ clicked, setClicked }) => {
    const location = useLocation();
    const { pathname } = location;
    const [navMenu, setNavMenu] = useState(null)
    const { logout } = useAuth()
    const [open, setOpen] = useState(false)
    const auth = useRecoilValue(authAtom)
    const userDetails = useRecoilValue(userSelector)
    const splitLocation = pathname.split("/");
    React.useEffect(() => {
        if (auth?.admin) {
            // setNavMenu(navAdminMenu)
            //console.log(navAdminMenu)
            
            let menu2=[];
            let milmap=navAdminMenu.MILMAP;
            const crm=navAdminMenu.CRM;
            const settings=navAdminMenu.SETTINGS;
            const call_mgt=navAdminMenu['CALL MANAGEMENT'];
            auth?.admin.roles[0]?.permissions.forEach(element => {

                milmap.filter(function (el) {
                    if(el.permType== element.name)
                    {return el.permission =true;}
                    }
                );

                crm.filter(function (el) {
                    if(el.permType== element.name)
                    {return el.permission =true;}
                    }
                );

                settings.filter(function (el) {
                    if(el.permType== element.name)
                    {return el.permission =true;}
                    }
                );
                call_mgt.filter(function (el) {
                    if(el.permType== element.name)
                    {return el.permission =true;}
                    }
                );
            });
            menu2['MILMAP']=milmap;
            menu2['CRM']=crm;
            menu2['CALL MANAGEMENT']=call_mgt;
            menu2['SETTINGS']=settings;
            
            setNavMenu(Object.assign({}, menu2))
        } else {
            //debugger
            // if(auth.user.present_level_contribution.level==="Patriot")
            // {
            //     navUserMenu.filter(function (el) {
            //             if(el.name== "Network")
            //             {
                            
            //             }
            //         }
            //     );
            // }
            const network=navUserMenu.Network;
            var tryer=navUserMenu;
            // console.log(navUserMenu)
            setNavMenu(navUserMenu)
        }
    }, [])
    return (
        <div>
            <Header clicked={clicked} setClicked={setClicked} setOpen={setOpen} user={userDetails?.first_name} />
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        {isArray(navMenu) ?
                            <div className="nav">
                                {navMenu?.map(item => item.hasChild ?
                                    <div key={item.id}>
                                        <a className={navChildren(item.children).includes(splitLocation[splitLocation.length - 1]) ? "nav-link active collapsed" : "nav-link collapsed"} href="#" data-bs-toggle="collapse"
                                            data-bs-target={`#${item.id}`} aria-expanded="false"
                                            aria-controls={item.id}>
                                            <div className="sb-nav-link-icon"><i className={item.icon}></i></div>
                                            {item.name}
                                            <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i>
                                            </div>
                                        </a>
                                        <div className="collapse" id={item.id} aria-labelledby="headingTwo"
                                            data-bs-parent="#sidenavAccordion">
                                            <nav className="sb-sidenav-menu-nested nav accordion" id={`sidenavAccordion${item.id}`}>
                                                {item.children.map(child =>child.name==="Live Chat"
                                                ?(
                                                    auth.user.present_level_contribution.level==="Patriot"?
                                                    (
                                                        <Link className={splitLocation[splitLocation.length - 1] === child.href ? "nav-link active" : "nav-link"} to={child.href} key={child.href}>
                                                            <div className="sb-nav-link-icon"><i className={child.icon}></i></div>
                                                            {child.name}
                                                        </Link>
                                                    ):''

                                                ):
                                                   
                                                
                                                    <Link className={splitLocation[splitLocation.length - 1] === child.href ? "nav-link active" : "nav-link"} to={child.href} key={child.href}>
                                                        <div className="sb-nav-link-icon"><i className={child.icon}></i></div>
                                                        {child.name}
                                                    </Link>
                                                    )
                                                    
                                                }

                                            </nav>
                                        </div>
                                    </div>
                                    :
                                    <Link className={splitLocation[splitLocation.length - 1] === item.href ? "nav-link active" : "nav-link"} to={item.href} key={item.href}>
                                        <div className="sb-nav-link-icon"><i className={item.icon}></i></div>
                                        {item.name}
                                    </Link>
                                )}
                            </div>
                            :
                            <div className="nav">
                                {navMenu && Object.entries(navMenu).map(([key]) => (
                                    <div key={key}>
                                        <div className='bg-red text-white message__author px-4 py-2'>{key}</div>
                                        {navMenu[key].map(item => item.hasChild ?
                                            item.permission ? 
                                            <div key={item.id}>
                                                <a className={navChildren(item.children).includes(splitLocation[splitLocation.length - 1]) ? "nav-link active collapsed" : "nav-link collapsed"} href="#" data-bs-toggle="collapse"
                                                    data-bs-target={`#${item.id}`} aria-expanded="false"
                                                    aria-controls={item.id}>
                                                    <div className="sb-nav-link-icon"><i className={item.icon}></i></div>
                                                    {item.name}
                                                    <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i>
                                                    </div>
                                                </a>
                                                <div className="collapse" id={item.id} aria-labelledby="headingTwo"
                                                    data-bs-parent="#sidenavAccordion">
                                                    <nav className="sb-sidenav-menu-nested nav accordion" id={`sidenavAccordion${item.id}`}>
                                                        {item.children.map(child =>
                                                            // child.permission ? 
                                                            <Link className={splitLocation[splitLocation.length - 1] === child.href ? "nav-link active" : "nav-link"} to={child.href} key={child.href}>
                                                                <div className="sb-nav-link-icon"><i className={child.icon}></i></div>
                                                                {child.name}
                                                            </Link>
                                                            // :''
                                                            )}

                                                    </nav>
                                                </div>
                                            </div>
                                            :''
                                            : 
                                            item.permission ? 
                                            <Link className={splitLocation[splitLocation.length - 1] === item.href ? "nav-link active" : "nav-link"} to={item.href} key={item.href}>
                                                <div className="sb-nav-link-icon"><i className={item.icon}></i></div>
                                                {item.name}
                                            </Link>
                                            :''
                                        )}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </nav>
            </div >

            <Modals show={open}
                onHide={() => setOpen(false)} size='md' footer={<div className='flex gap-1'><button type="button" className="btn btn-danger" onClick={() => logout(SIGNIN)}>Log out</button><button type="button" className="btn btn-secondary" onClick={() => setOpen(false)}>Cancel</button></div>}>
                <div>
                    Are you sure you want to logout?
                </div>
            </Modals>
        </div>
    )
}
export default Sidebar;
