import * as _ from 'lodash';

export const formatNumber = (num) => {
  num = Number(num.toString().replace(/\D/g, ''));
  const formatter = new Intl.NumberFormat();
  return formatter.format(num);
};


export const formatMoney = (value, currency = 'NGN', locale = 'en-NG') => {
  value = Number(value);
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency });
  return formatter.format(value);
};

export function extractContent(s, space) {
  const div = document.createElement('div');
  div.innerHTML = s;

  if (space) {
    var children = div.querySelectorAll('*');
    for (var i = 0; i < children.length; i++) {
      if (children[i].textContent)
        children[i].textContent += ' ';
      else
        children[i].innerText += ' ';
    }
  }

  return [div.textContent || div.innerText].toString().replace(/ +/g, ' ');
};
export const getNames = (arr) => {
  const names = arr.map(item => item.name)
  return names.join(', ')
}
export const toStartCase = (str) => {
  str = str || '';
  return str
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, (str, $1, $2) => $1 + ' ' + $2)
    .replace(/(\s|^)(\w)/g, (str, $1, $2) => $1 + $2.toUpperCase());
};

export const toFullName = (data) =>
  [data.firstName, data.lastName].filter((val) => val).join(' ');

export function toInitials(name) {
  const displayName = _.trim(name);
  const split = _.filter(displayName.split(' '), (i) => !!i.length);

  // if display name is only one word then take its first two letters
  if (split.length < 2) {
    return displayName.substr(0, 2).toUpperCase();
  }

  // if display name is make from more then one word then take first word first letter
  // and second word first letter
  return `${split[0][0]}${split[1][0]}`.toUpperCase();
}

export const scrollToErrors = (errors) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length > 0) {
    document.getElementsByName(errorKeys[0])[0].focus();
  }
}

export const occurrencesOf = (number, numbers) => numbers.reduce((counter, currentNumber) => (number == currentNumber ? counter + 1 : counter), 0);
