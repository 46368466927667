
import {
    ACTION_AID_USER_MGT,
    ADMIN_LOGS,
    ADVERTS,
    DASHBOARD,
    DEFAULT_DONATION,
    DONATE,
    DONATION_HISTORY,
    DONATION_MGT,
    DONATION__CATEGORY,
    DONATION_REPORT,
    DONOR__CLASSIFICATION,
    DONOR__REPORT,
    DONOR__STATUS,
    FORUM,
    FORUM_MGT,
    FUNDRAISER,
    FUNDRAISER__DONATION,
    GROWTH,
    LIVE_CHAT,
    MY_ACTIVITY,
    MY_NETWORK,
    NETWORK_CHART,
    NOTIFICATION,
    NOTIFICATION_ANIVERSARIES,
    NOTIFICATION_BIRTHDAYS,
    NOTIFICATION_LOGS,
    NOTIFICATION_REMINDERS,
    NOTIFICATION_TEMPLATES,
    ONE_OFF_DONATION,
    REPORTS,
    ROLES,
    USER_LOG,
    MEMBERSHIP,
    ACTIVITIES,
    FUNDRAISER_TYPES,
    FORUM_CATEGORIES,
    FUNDRAISERMGT,
    USER_REPORT,
    BIRTHDAY_REPORT,
    RECYCLE_DONATION,
    ONE_OFF_CAMPAIGN_DONATION,
    RECYCLE_CAMPAIGN_DONATION,
    CHAT,
} from "navigation/routes";

export const navAdminMenu = {
    MILMAP: [ 
        { name: 'Dashboard', hasChild: false, href: DASHBOARD, id: 'dashboard', allowed: ['admin'], icon: 'fas fa-tachometer-alt', permission: true },
        { name: 'Donation', permType:'view all donations',hasChild: false, href: DONATION_MGT, id: 'donation-mgt', allowed: ['admin'], icon: 'fas fa-money-bill-1'   },
        { name: 'Forum',permType:'view forum', hasChild: false, href: FORUM_MGT, id: 'forum-mgt', allowed: ['admin'], icon: 'fas fa-table' },
        { name: 'Adverts',permType:'view advert', hasChild: false, href: ADVERTS, id: 'adverts', icon: 'fas fa-table' },
        { name: 'Membership',permType:'view membership', hasChild: false, href: MEMBERSHIP, id: 'volunteer-mgt', allowed: ['admin'], icon: 'fas fa-user' },
        // { name: 'Chat',permType:'view membership', hasChild: false, href: CHAT, id: 'chat', allowed: ['admin'], icon: 'fas fa-user' },
        { name: 'Activities',permType:'view activities', hasChild: false, href: ACTIVITIES, icon: 'fas fa-bell' },
        { name: 'Campaigns',permType:'view campaigns', hasChild: false, href: FUNDRAISERMGT, id: 'fundraiser', icon: 'fas fa-magnifying-glass-dollar' },
    ],
    CRM: [
        { name: 'Dashboard', hasChild: false, href: DASHBOARD, id: 'dashboard', allowed: ['admin'], icon: 'fas fa-tachometer-alt',permission: true },
        { name: 'Birthday',permType:'view all users', hasChild: false, href: BIRTHDAY_REPORT, id: 'user', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'User Report',permType:'view all users', hasChild: false, href: USER_REPORT, id: 'user', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Donation Report (Others)',permType:'view all donation report', hasChild: false, href: DONATION_REPORT, id: 'global', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Default Report (Others)',permType:'view all default donation report', hasChild: false, href: DEFAULT_DONATION, id: 'default', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'One Off Donations Report (Others)',permType:'view on off donation repor', hasChild: false, href: ONE_OFF_DONATION, id: 'one-off', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Recycle Donations Report (Others)',permType:'view all donation report', hasChild: false, href: RECYCLE_DONATION, id: 'recycle', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'One Off Donations Report (Campaign)',permType:'view on off donation report', hasChild: false, href: ONE_OFF_CAMPAIGN_DONATION, id: 'one-off', allowed: ['admin'], icon: 'fas fa-money-bill-1' },        
        { name: 'Recycle Donations Report (Campaign)',permType:'view all donation report', hasChild: false, href: RECYCLE_CAMPAIGN_DONATION, id: 'recycle', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Individual Donor Report',permType:'view all donation report', hasChild: false, href: DONOR__REPORT, id: 'donor-report', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Category Report',permType:'view all user category', hasChild: false, href: DONATION__CATEGORY, id: 'category', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Campaign Report',permType:'view campaigns', hasChild: false, href: FUNDRAISER__DONATION, id: 'fundraiser', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Donor Status Report',permType:'view donor status', hasChild: false, href: DONOR__STATUS, id: 'donor-status', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        { name: 'Donor Classification Report',permType:'view donation category', hasChild: false, href: DONOR__CLASSIFICATION, id: 'donor-classification', allowed: ['admin'], icon: 'fas fa-money-bill-1' },
        {
            name: 'Notifications',permType:'view notification', hasChild: true, href: '#', id: 'notifications', allowed: ['admin'], icon: 'fas fa-bell',
            children: [
                // { name: 'Log', hasChild: false, href: NOTIFICATION_LOGS, icon: 'fas fa-money-bill-1' },
            { name: 'Templates ',permType:'view notification', hasChild: false, href: NOTIFICATION_TEMPLATES, icon: 'fas fa-clock-rotate-left' },
            // { name: 'User Birthdays ', hasChild: false, href: NOTIFICATION_BIRTHDAYS, icon: 'fas fa-clock-rotate-left' },
            // { name: 'Reminders ', hasChild: false, href: NOTIFICATION_REMINDERS, icon: 'fas fa-clock-rotate-left' },
            // { name: 'Aniversaries ', hasChild: false, href: NOTIFICATION_ANIVERSARIES, icon: 'fas fa-clock-rotate-left' }
        ]
        },
    ],

    'CALL MANAGEMENT': [
        { name: 'Users',permType:'view all users', hasChild: false, href: USER_LOG, id: 'call-user', allowed: ['admin'], icon: 'fas fa-users' },
        { name: 'ActionAid Admins',permType:'view all admins', hasChild: false, href: ADMIN_LOGS, id: 'call-admin', allowed: ['admin'], icon: 'fas fa-user-shield' }
    ],

    SETTINGS: [
        { name: 'User Management',permType:'view all users', hasChild: false, href: ACTION_AID_USER_MGT, id: 'aaid-user', allowed: ['admin'], icon: 'fas fa-user' },
        {
            name: 'Utilities',permType:'view forum', hasChild: true, href: '#', id: 'utilities', allowed: ['admin'], icon: 'fas fa-cogs',
            children: [
                { name: 'Forum Categories',permType:'view forum', hasChild: false, href: FORUM_CATEGORIES, icon: 'fas fa-cog'},
            { name: 'Campaign Types ',permType:'view campaigns', hasChild: false, href: FUNDRAISER_TYPES, icon: 'fas fa-cog'},
             { name: 'Roles', permType:'view all roles', hasChild: false, href: ROLES, icon: 'fas fa-cogs' },
            ]
        },
    ]
}

export const navUserMenu = [
    { name: 'Dashboard', hasChild: false, href: '/', id: 'dashboard', icon: 'fas fa-tachometer-alt' },
    { name: 'Forum', hasChild: false, href: FORUM, id: 'forum', icon: 'fas fa-table' },
    {
        name: 'Donation', hasChild: true, href: '#', id: 'donation', icon: 'fas fa-money-bill-1',
        children: [{ name: 'Donate', hasChild: false, href: DONATE, icon: 'fas fa-money-bill-1' },
        { name: 'Donation History', hasChild: false, href: DONATION_HISTORY, icon: 'fas fa-clock-rotate-left' }]
    },
    {
        name: 'Activities', hasChild: true, href: '#', id: 'activies', icon: 'fas fa-table-list',
        children: [
            { name: 'Campaign', hasChild: false, href: FUNDRAISER, icon: 'fas fa-magnifying-glass-dollar' },
            { name: 'My Activity', hasChild: false, href: MY_ACTIVITY, icon: 'fas fa-table-list' }        
        ]
    },
    {
        name: 'Network', hasChild: true, href: '#', id: 'network', icon: 'fas fa-network-wired',
        children: [{ name: 'My Network', hasChild: false, href: MY_NETWORK, icon: 'fas fa-users' },
        { name: 'Network Chart', hasChild: false, href: NETWORK_CHART, icon: 'fas fa-network-wired' },
        { name: 'Live Chat', hasChild: false, href: LIVE_CHAT, icon: 'fas fa-comment-dots',permission: false  }
        ]
    },
    {
        name: 'Progress', hasChild: true, href: '#', id: 'progress', icon: 'fas fa-chart-line',
        children: [{ name: 'Growth', hasChild: false, href: GROWTH, icon: 'fas fa-chart-column' },
        { name: 'Reports', hasChild: false, href: REPORTS, icon: 'fas fa-table-list' }]
    },
    { name: 'Notification', hasChild: false, href: NOTIFICATION, icon: 'fas fa-bell' },
]
