import CitySelect from "components/common/Selects/CitySelect";
import CountrySelect from "components/common/Selects/CountrySelect";
import StateSelect from "components/common/Selects/StateSelect";
import LoadingIndicator from "components/LoadingIndicator";
import Modals from "components/Modal";
import React, { Suspense } from "react";
import ReactSelect from "react-select";
import { ACTION_AID_USER_MGT } from "../../navigation/routes";
import Input from "components/innerComponents/Input";
import EnhancedTable from "components/EnhancedTable";
import { startCase } from 'lodash'
import { format } from "date-fns";
import { excludedFields } from "utils/constants";
import { getNames, scrollToErrors } from "utils/functions";
import useAaidUserManagement from "hook/useAaidUserManagement";

const AAidUserMgt = () => {
    const {
        shouldEditRole, setShouldEditRole,
        user,
        handleUpdateRoles,
        handleAssignRoles,
        formik, getDefaultValues, handleChangeRoles, updateMyData, updateAdminRoleLoading, updateAdminLoading, deleteAdminMutation, deleteAdminLoading, createAdminLoading, singleAdmin, allAdmins, isLoadingAdmins, isFetchingAdmins, columns, open, setOpen, roles, edit, setEdit, current, setCurrent, currentData, setCurrentData, skipPageReset, page, setPage, data, setData, selectedCity, setSelectedCity, selectedState, setSelectedState, selectedCountry, setSelectedCountry
    } = useAaidUserManagement()
    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>ActionAid Users</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={ACTION_AID_USER_MGT}>ActionAid Users</a></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-sm-end mb-2">
                        <button className="btn btn-primary" type="button" onClick={() => {
                            setCurrent('add')
                            setOpen(true)
                        }}>Add ActionAid User</button>
                    </div>
                </div>


                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Action Aid Users
                    </div>
                    <div className="card-body">
                        <EnhancedTable
                            columns={columns}
                            data={allAdmins?.data?.admins?.data || []}
                            setData={setData}
                            skipPageReset={skipPageReset}
                            lastPage={allAdmins?.data?.admins?.last_page}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isFetchingAdmins || isLoadingAdmins}
                        />
                    </div>
                </div>
            </div>
            <Modals show={open && current === 'add'}
                onHide={() => setOpen(false)} size={'md'} title='Create new actionaid user'>
                <form onSubmit={formik.handleSubmit}>
                    <Input
                        name='first_name'
                        value={formik.values.first_name}
                        type='text'
                        placeholder='First Name'
                        label='First Name'
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        error={formik.errors.first_name && formik.touched.first_name ? formik.errors.first_name : null}
                    />
                    <Input
                        name='last_name'
                        value={formik.values.last_name}
                        type='text'
                        placeholder='Last Name'
                        label='Last Name'
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        error={formik.errors.last_name && formik.touched.last_name ? formik.errors.last_name : null}
                    />
                    <Input
                        name='email'
                        value={formik.values.email}
                        type='email'
                        placeholder='Email'
                        label='Email'
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        error={formik.errors.email && formik.touched.email ? formik.errors.email : null}
                    />
                    <Input
                        name='phone'
                        value={formik.values.phone}
                        type='tel'
                        placeholder='Contact Number'
                        label='Contact Number'
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        error={formik.errors.phone && formik.touched.phone ? formik.errors.phone : null}
                    />
                    <div className="form-floating mb-3">
                        <Suspense fallback={<LoadingIndicator />} >
                            <CountrySelect selected={selectedCountry} handleChange={(e) => {
                                setSelectedCountry(JSON.parse(e.target.value))
                                formik.setFieldValue('country_id', JSON.parse(e.target.value)?.id)
                            }} />
                        </Suspense>
                        <label htmlFor="country">Country </label>
                    </div>
                    <div className="form-floating mb-3">
                        <Suspense fallback={<LoadingIndicator />} >
                            <StateSelect selectedCountry={selectedCountry} selected={selectedState} handleChange={(e) => {
                                setSelectedState(JSON.parse(e.target.value))
                                formik.setFieldValue('state_id', JSON.parse(e.target.value)?.id)
                            }} />
                        </Suspense>
                        <label htmlFor="state">State</label>
                    </div>
                    <div className="form-floating mb-3">
                        <Suspense fallback={<LoadingIndicator />} ><CitySelect selectedState={selectedState} selected={selectedCity} handleChange={(e) => {
                            setSelectedCity(JSON.parse(e.target.value))
                            formik.setFieldValue('city_id', JSON.parse(e.target.value)?.id)
                        }} />
                        </Suspense>
                        <label htmlFor="city">City/town</label>
                    </div>
                    <Input
                        name='address'
                        value={formik.values.address}
                        type='text'
                        placeholder='Address'
                        label='Address'
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        error={formik.errors.address && formik.touched.address ? formik.errors.address : null}
                    />
                    <div className="mb-3">
                        <label htmlFor="roles">Roles</label>
                        <ReactSelect
                            isMulti
                            name="roles"
                            onChange={handleChangeRoles}
                            options={roles}
                            className="no-padding"
                            classNamePrefix="form-select"
                        />
                        {/* {formik.errors.permissions && formik.touched.permissions && <small className={classNames('error', 'col-sm-7 offset-sm-4')}>{formik.errors.permissions}</small>} */}
                    </div>

                    <div
                        className="d-flex align-items-center justify-content-end mt-4 mb-0">
                        <button className="btn btn-primary" type="submit" disabled={createAdminLoading} onClick={() => scrollToErrors(formik.errors)}>{createAdminLoading && <LoadingIndicator />}Create</button>
                    </div>
                </form>
            </Modals>
            <Modals show={open && current === 'view' && singleAdmin}
                onHide={() => { setEdit(false); setOpen(false) }} title='View Roles' size='md' footer={(user?.id === 1 || (currentData?.id !== user?.id && currentData?.id !== 1)) ? <button type="button" className="btn btn-primary" onClick={() => {
                    if (edit) {
                        setEdit(false)
                        updateMyData()
                    } else {
                        setEdit(true)
                    }
                }} disabled={updateAdminLoading}>{updateAdminLoading && <LoadingIndicator />}{edit ? 'Update' : 'Edit'}</button> : null}>
                <dl className="striped">
                    {singleAdmin && Object.entries(singleAdmin)?.map(([key, value]) =>
                        excludedFields.includes(key) ? [] :
                            key === 'roles' ? <div key={key} className='row'>
                                <dt className='col-4'>{`${startCase(key)} :`}</dt>
                                <dd className='col-8'>
                                    {getNames(value)}
                                </dd>
                                {shouldEditRole && <ReactSelect
                                    defaultValue={getDefaultValues(value)}
                                    name="role"
                                    onChange={(handleUpdateRoles)}
                                    options={roles}
                                    className="col-8 offset-4 no-padding"
                                    classNamePrefix="form-select"
                                />}
                                {(currentData?.id !== user?.id && currentData?.id !== 1) &&
                                    <div className="col-8 offset-4 pt-2"> <button className="btn btn-secondary" onClick={() => shouldEditRole ? handleAssignRoles() : setShouldEditRole(!shouldEditRole)} disabled={(shouldEditRole && !currentData?.role_id) || updateAdminRoleLoading} >{updateAdminRoleLoading && <LoadingIndicator />}{shouldEditRole ? 'Update' : 'Assign Role'}</button>
                                        {shouldEditRole && <button className="btn btn-light ms-2" onClick={() => setShouldEditRole(false)} disabled={updateAdminRoleLoading}>Cancel</button>}
                                    </div>
                                }
                            </div> : key === 'created_at' ? <div key={key} className='row'>
                                <dt className='col-4'>Date Joined: </dt>
                                <dd className='col-8'>{format(new Date(value), 'yyyy/MM/dd')}</dd>
                            </div> : key === 'is_blocked' ? <div key={key} className='row'>
                                <dt className='col-4'>{`${startCase(key)} :`}</dt>
                                <dd className='col-8'>{value ? 'True' : 'False'}</dd>
                            </div> :
                                <div key={key} className='row'>
                                    <dt className='col-4'>{`${startCase(key)} :`}</dt>
                                    {(!edit || key === 'employment_id' || key === 'email') && <dd className='col-8'>{value}</dd>}
                                    {edit && key !== 'employment_id' && key !== 'email' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
                                </div>)}
                </dl>
            </Modals>
            <Modals show={open && current === 'delete'}

                onHide={() => setOpen(false)} title='Delete Roles' footer={<button type="button" className="btn btn-primary" onClick={() => deleteAdminMutation(currentData?.id)} disabled={deleteAdminLoading}>{deleteAdminLoading && <LoadingIndicator />} Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{`${currentData.first_name} ${currentData.last_name}`}</b>?</p>}
                </div>
            </Modals>
        </>
    );
}

export default AAidUserMgt;
