import ButtonWithTips from "components/ButtonWithTips"
import React from "react"
import { formatMoney, toStartCase } from "utils/functions"
import makeData from "utils/makeData"
import useReports from "./useReports"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

const useIndividualFundraiserReport = () => {
  //  const [data, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const navigate = useNavigate()
  const { fundraisingId } = useParams()
  const { getIndividualfundRaiserReport } = useReports(); 
  const [page, setPage] = React.useState(1)
  const { data, isLoading } = useQuery(['users', page], ()=> getIndividualfundRaiserReport(page,fundraisingId), {
    select: data => data.data.fund_raising.data
  });
  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []));
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  const handleBreakdown = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: row => toStartCase(`${row?.first_name} ${row?.last_name}`),
      },
      {
        Header: 'Email',
        accessor: row=> row.email,
      },
      // {
      //   Header: 'City',
      //   accessor: 'city',
      // },
      // {
      //   Header: 'Amount',
      //   id: 'donation',       
      // },
      {
        Header: 'Amount',
        accessor: row => formatMoney(row.amount)
      },
      // {
      //   Header: 'Total donation',
      //   id: 'total_donation',
      //   accessor: row => formatMoney(row.donations[0]==null?0:row.donations[0].total)
      // },
      {
        Header: 'Date',
        accessor: 'created_at',
        filter: 'dateFilter'
      },
      
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      //   Cell: row => (
      //     <div className="flex flex-wrap gap-2 w-100">
      //       <ButtonWithTips id={`view`} tips="View Report Details" btnProps={{
      //         className: "btn btn-secondary", onClick: () => {
      //           setCurrent('view')
      //           handleclick(row.row.original)
      //         }
      //       }}><i className="fas fa-eye" /></ButtonWithTips>
            
      //     </div>
          
      //   ),
      // }
    ],
    []
  )
  return { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData,isLoading, current, edit, setEdit }
}

export default useIndividualFundraiserReport;
