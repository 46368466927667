import LineChart from "../../../components/charts/LineChart";

const Growth = () => {
    return (
        <div className="growth">
            <div className="container px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Growth</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                            <li className="breadcrumb-item active">Reports</li>
                        </ol>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-chart-area me-1"></i>
                        Progression Report
                    </div>
                    <div className="card-body">
                        <LineChart />
                    </div>
                    <div className="card-footer small text-muted">Updated yesterday at 11:59 PM</div>
                </div>
            </div>
        </div>
    );
}

export default Growth;
