import { notify } from 'components/Alerts'
import LoadingBox from 'components/LoadingBox'
import CommonLayout from 'components/common/Layout'
import { formatDistanceToNowStrict } from 'date-fns'
import useAdminActions from 'hook/useAdminActions'
import useGenericActions from 'hook/useGenericAction'
import useUserActions from 'hook/useUserActions'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

const ViewActivity = () => {
  const { activityId } = useParams()
  const location = useLocation()
  const queryClient = useQueryClient()
  const [openComment, setOpenComment] = useState(false);
  const [comments, setComments] = useState('');
  const { addAComment, getAnActivity } = useGenericActions()
  const dummyref = useRef(null);
  const { data: forumData, isLoading: isDataLoading, isFetching, isError } = useQuery(['forum post', activityId], () => getAnActivity(activityId), {
    select: data => data.data.activity,
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })
  useEffect(() => {
    if (openComment) {
      dummyref?.current?.scrollIntoView({ behavior: "smooth" });
    }

  }, [forumData, openComment]);
  const { mutate, isLoading } = useMutation(addAComment, {
    onSettled: res => {
      if (res.success) {
        queryClient.invalidateQueries('forum post')
        notify(res.message, { type: 'success' })
        setComments('')
      }
      console.log(res)
    }
  })

  return (
    <CommonLayout pageText="Our Impact Stories">
            <Helmet>
                <title>Activity | An ActionAid Nigeria’s Community Sponsorship Initiative</title>
            </Helmet>
            <section className="page-section impact-stories" id="impact-stories">
            <div className="container px-4 px-lg-5">
                <h1 className="section-title text-center mt-0">
                    {forumData?.tagline}
                </h1> 
                <hr className="divider" />
                <div className="row g-4 g-lg-5 cards-container mt-5">
                <div className="blog-post">
                  <div className='blog-post__image--container'><img className="blog-post__image rounded" src={forumData?.featured_image} alt={forumData?.tagline} /></div>
                  <p className="blog-post__content">{forumData?.description}</p>
                </div>
                  </div> 
                  </div>
            </section>

    

    </CommonLayout>
  )
}

export default ViewActivity
