import { MY_NETWORK, NETWORK_CHART } from 'navigation/routes';
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share'
import useNetwork from "hook/useNetwork";
import EnhancedTable from "components/EnhancedTable";
import { Link } from 'react-router-dom';

const MyNetwork = () => {
    const { userDetails, selected, mailcontent, setSelected, handleCopy, linkRef, myNetwork, isLoading, columns, setPage, page, setData } = useNetwork()
    return (
        <div>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Network</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><Link to={MY_NETWORK}>Network</Link></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-sm-end gap-2 mb-2">
                        <Link to={`../${NETWORK_CHART}`} className="btn btn-secondary me-2">view network chart </Link >
                        <button className="btn btn-primary" onClick={() => setSelected('sm')} >
                            Share invite
                        </button>
                    </div>
                </div>
                {selected === 'sm' ?
                    <div>
                        <p>CLick on any of the icons below to share your invite link or click the button below to copy your unique referral link</p>
                        <div className="d-flex gap-2 flex-wrap mb-2">
                            <div className="Demo__some-network" title="Facebook">
                                <FacebookShareButton
                                    url={mailcontent.url}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </div>

                            <div className="Demo__some-network" title="Messenger">
                                <FacebookMessengerShareButton
                                    url={mailcontent.url}
                                    appId="521270401588372"
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookMessengerIcon size={32} round />
                                </FacebookMessengerShareButton>
                            </div>

                            <div className="Demo__some-network" title="Twitter">
                                <TwitterShareButton
                                    url={mailcontent.url}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                            </div>

                            <div className="Demo__some-network" title="Telegram">
                                <TelegramShareButton
                                    url={mailcontent.url}
                                    className="Demo__some-network__share-button"
                                >
                                    <TelegramIcon size={32} round />
                                </TelegramShareButton>
                            </div>

                            <div className="Demo__some-network" title="Whatsapp">
                                <WhatsappShareButton
                                    url={mailcontent.url}
                                    separator=":: "
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>

                            <div className="Demo__some-network" title="Linkedin">
                                <LinkedinShareButton url={mailcontent.url} className="Demo__some-network__share-button">
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                            <div className="Demo__some-network" title="Email">
                                <EmailShareButton
                                    url={mailcontent.url}
                                    subject={mailcontent.subject}
                                    body={mailcontent.body}
                                    className="Demo__some-network__share-button"
                                >
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <input
                                    name='text'
                                    value={`${window.location.origin}/signup/${userDetails?.unique_id}`}
                                    type='text'
                                    ref={linkRef}
                                    id="text"
                                    className=" form-control"
                                    disabled
                                /></div>
                            <div className="mb-3 col-md-6"><button onClick={handleCopy} className="btn btn-primary">Click to copy</button>
                            </div>
                        </div>
                    </div> : null}
                <div className="card mb-4">
                    <div className="card-body">
                        <EnhancedTable columns={columns} data={myNetwork?.data?.network || []} setData={setData}
                            skipPageReset={false}
                            lastPage={myNetwork?.data?.length}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isLoading} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MyNetwork;
