import Helmet from "react-helmet";
// import CommonLayout from "../../components/common/Layout";
import useGenericActions from "hook/useGenericAction";
import { useQuery } from "react-query";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import CommonLayout from "components/common/Layout";
export const Card = (props) => {
    return (
        <div className="card-subcontainer">
            <div className="card-image">
                <img src={props.image} alt="" />
            </div>
            <div className="card-content">
                <h3 className="post-title mb-2  text-truncate-2-lines">{props.title}</h3>
                <p className="text-muted mb-0 text-truncate-3-lines">{props.description}</p>
            </div>
        </div>
    );
}

const ViewPatriot = () => {
  const { getPatriots } = useGenericActions()
    const { data, isLoading } = useQuery(['patriots'], getPatriots, {
        select: data => data.data.patriot.data
    })
    return (
        <CommonLayout pageText="MILMAP Patriots">
            <Helmet>
                <title>MILMAP Patriots | An ActionAid Nigeria’s Community Sponsorship Initiative</title>
            </Helmet>
            <section className="page-section impact-stories" id="impact-stories">
            <div className="container px-4 px-lg-5">
                <h1 className="section-title text-center mt-0">
                    Our Patriots
                    <span className="subtitle">
                    MILMAP Patriots are regular or one-off donors committed to making an impact and helping put smiles on the faces of the poor, the discriminated and the ignored.
                    </span>
                </h1> 
                <hr className="divider" />
                <div className="row g-4 g-lg-5 cards-container mt-5">
                {data?.length ? data?.map(post => 
                    <Card
                    key={post.id}
                    image={post.profile_picture || '/img/placeholder.png'}
                    title={post.full_name}
                    slug={post.id}
                    description={post.profile} />) : ''}


                </div>
            </div>
        </section>
        </CommonLayout>
    );
}

export default ViewPatriot;
