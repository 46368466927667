// import Select from "components/innerComponents/Select";
import useGenericActions from "hook/useGenericAction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, useQuery } from "react-query";
import { notify } from "components/Alerts";
import { useRecoilState, useRecoilValue } from "recoil";
import { userSelector } from "hook/state/user";
import LoadingIndicator from "components/LoadingIndicator";
import { formatNumber, scrollToErrors, toFullName } from "utils/functions";
import SelectBox from "components/common/Selects/SelectBox";
import { donation_groups } from 'utils/constants'
import ButtonWithTips from "components/ButtonWithTips";
import SelectWrapper from "components/innerComponents/AsyncSelect/SelectWrapper";
import { selectedDonorAtom } from "hook/state/generic";
import useAdminActions from "hook/useAdminActions";
import Select from 'react-select';
import { useForm } from "react-hook-form";

const validationSchemaMakeDonations = Yup.object({
    subject: Yup.string()
        .required('Must not be empty'),
    message: Yup.string()
        .required('Field cannot be empty'),
    // donation_cause: Yup.object()
    //     .required('Field cannot be empty'),
    // donor_phone_number: Yup.string()
    //     .required('Field cannot be empty')
    //     .length(11, 'Phone number must be 11 digits only')
    //     .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
})


const NotificationTemplates = () => {
    const user = useRecoilValue(userSelector)
    const [options, setOptions] = useState([]);
  const [pageNo, setPage] = useState(0);
    const [selectedCycle, setSelectedCycle] = useState('')
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [value, setValue] = useState()
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isNextPageLoading, setNextPageLoading] = useState(false);
    const [selectedValue, setSelectedOption] = useRecoilState(selectedDonorAtom);
    const [selectedAppValue, setSelectedAppOption] = useRecoilState(selectedDonorAtom);
    const { getAllDonors,uploadNotification } = useAdminActions()
    // const { makeDonation, getDonationTypes, getDonationStatus, getFundRaisings, payFundRaisings } = useGenericActions()

    useEffect(() => {
        loadOptions(1);
      },[]);

    const loadOptions = async (page) => {
        try {
          setNextPageLoading(true);
          const data = await getAllDonors(page,'');
    
          const dataOptions = data?.data?.users?.data?.map(({ id, last_name, first_name }) => (
            {
              label: toFullName({ firstName: first_name, lastName: last_name }),
              value: id
            }
          ));
          const itemsData = options.concat(dataOptions);
          setOptions(itemsData);
          setNextPageLoading(false);
          setHasNextPage(data?.data?.users?.to < data?.data?.users?.total);
          setPage(page);
        } catch (err) {
          console.log(err);
        }
      };
    const loadNextPage = async () => {
        await loadOptions(pageNo + 1);
      };


    
    const makeDonationMutation = useMutation(uploadNotification, {
        onSuccess: (res) => {
            notify(res.message, { type: 'success' });
        },
        onError: err => {
            notify(err.message, { type: 'error' })
        }
    })

    const formik = useFormik({
        validationSchema: validationSchemaMakeDonations,
        enableReinitialize: true,
        initialValues: {
            subject: '',
            message: '',
            notification_tag:''
        },
        onSubmit: (values) => {
            
            const details = {
                subject: values.subject,
                notification_tag: 'email_notification',
                message: values.message,
                donor_email: selectedValue
            }
             makeDonationMutation.mutate(details)
            
        },
    })

    const formikApp = useFormik({
      validationSchema: validationSchemaMakeDonations,
      enableReinitialize: true,
      initialValues: {
          subject: '',
          message: '',
          notification_tag:''
      },
      onSubmit: (values) => {
          
          const details = {
              subject: values.subject,
              notification_tag: 'app_notification',
              message: values.message,
              donor_email: selectedAppValue
          }
           makeDonationMutation.mutate(details)
          
      },
  })
    const handleSelectchange = (e, name) => {
        formik.setFieldValue(name, e)
    }

    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Notifications</h1>
                    </div>
                    
                </div>


                <div className="col-md-5 offset-md-1">
            <div className="form-wrap hero-tab">
              <div className="form-holder">
                <ul
                  className="nav nav-pills mb-3 nav-justified"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item tab-1-nav" role="presentation">
                    <ButtonWithTips
                      id="monthly-tab"
                      tips="When you select the give regularly button you are agreeing to join our organisation as a member and give at a regular interval. your donation counts towards your level in our organisation"
                      btnProps={{
                        className: 'nav-link active',
                        // onClick: () => setCurrentPlan('Recurrent'),
                        id: 'monthly-tab',
                        'data-bs-toggle': 'pill',
                        'data-bs-target': '#monthly',
                        type: 'button',
                        role: 'tab',
                        'aria-controls': 'monthly',
                        'aria-selected': 'false',
                      }}
                    >
                      Mail Notification
                    </ButtonWithTips>
                  </li>
                  <li className="nav-item tab-2-nav" role="presentation">
                    <ButtonWithTips
                      id="once-tab"
                      tips="When you select the give once button, like the name implies you give once and it does not count towards your membership with us, and no account is created."
                      btnProps={{
                        className: 'nav-link',
                        // onClick: () => setCurrentPlan('One Off'),
                        id: 'once-tab',
                        'data-bs-toggle': 'pill',
                        'data-bs-target': '#once',
                        type: 'button',
                        role: 'tab',
                        'aria-controls': 'once',
                        'aria-selected': 'true',
                      }}
                    >
                      App Notification
                    </ButtonWithTips>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="monthly"
                    role="tabpanel"
                    aria-labelledby="monthly-tab"
                  >
                    <div className="content-wrap">
                      <div className="wrap-header">
                        <h4></h4>
                      </div>
                      <div className="wrap-body">
                        <div className="rendered-form ht-form">
                        <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-3">
                                   
                                      <Select
                                        placeholder="Select Users"
                                        options={options}
                                        hasNextPage={hasNextPage}
                                        isNextPageLoading={isNextPageLoading}
                                        loadNextPage={loadNextPage}
                                        isMulti={true}
                                        // onChange={(value) => setValue('permissions', value)}
                                        onChange={(value) => setSelectedOption( value)}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />  
                                    </div>
                                   
                                    
                                        <div className=" mb-3">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="subject" aria-label="subject" value={formik.values.subject} onChange={formik.handleChange} onBlur={formik.handleBlur} aria-describedby="basic-addon1" />
                                                <label htmlFor="donationType">Enter Subject</label>
                                            </div>
                                        </div>
                                        {formik.errors.subject && formik.touched.subject && (
                    <small className="error">{formik.errors.subject} </small>
                  )}

                                    <div className="mb-3">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">Message</span>
                                            </div>
                                            <textarea
                                                name="message"                   
                                                placeholder="message"
                                                label="message"
                                                value={formik.values.message}
                                                style={{height: 100, width:'100%'}}
                                                onChange={formik.handleChange}
                                            />
                                           {formik.errors.message && formik.touched.message && (
                    <small className="error">{formik.errors.message} </small>
                  )}
                                        </div>
                                       
                                    </div>
                                    <div className="flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={makeDonationMutation.isLoading} 
                                        onClick={() => scrollToErrors(formik.errors)}>
                                            {makeDonationMutation.isLoading && <LoadingIndicator />}
                                            Send Mail
                                            </button>
                                    </div>
                                </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="once"
                    role="tabpanel"
                    aria-labelledby="once-tab"
                  >
                    <div className="content-wrap">
                      <div className="wrap-header">
                        <h4>Choose amount to give per month234</h4>
                      </div>
                      <div className="wrap-body">
                        <div className="rendered-form ht-form">
                        <form onSubmit={formikApp.handleSubmit}>
                                    <div className="mb-3">
                                   
                                      <Select
                                        placeholder="Select Users"
                                        options={options}
                                        hasNextPage={hasNextPage}
                                        isNextPageLoading={isNextPageLoading}
                                        loadNextPage={loadNextPage}
                                        isMulti={true}
                                        // onChange={(value) => setValue('permissions', value)}
                                        onChange={(value) => setSelectedAppOption( value)}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />  
                                    </div>
                                   
                                    

                                        <div className=" mb-3">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="subject" aria-label="subject" value={formikApp.values.subject} onChange={formikApp.handleChange} onBlur={formikApp.handleBlur} aria-describedby="basic-addon1" />
                                                <label htmlFor="donationType">Enter Subject</label>
                                            </div>
                                        </div>
                                        {formikApp.errors.subject && formikApp.touched.subject && (
                    <small className="error">{formikApp.errors.subject} </small>
                  )}

                                    <div className="mb-3">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">Message</span>
                                            </div>
                                            <textarea
                                                name="message"                   
                                                placeholder="message"
                                                label="message"
                                                value={formikApp.values.message}
                                                style={{height: 100, width:'100%'}}
                                                onChange={formikApp.handleChange}
                                            />
                                           {formikApp.errors.message && formikApp.touched.message && (
                    <small className="error">{formikApp.errors.message} </small>
                  )}
                                        </div>
                                       
                                    </div>
                                    <div className="flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={makeDonationMutation.isLoading} 
                                        onClick={() => scrollToErrors(formikApp.errors)}>
                                            {makeDonationMutation.isLoading && <LoadingIndicator />}
                                            Send Notification
                                            </button>
                                    </div>
                                </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>














            </div>
        </>
    );
}

export default NotificationTemplates;
