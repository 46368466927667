import React, { useState } from "react";
import { useAsyncDebounce } from 'react-table';
import { useEffect } from "react";
import { format } from "date-fns";
import { notify } from "components/Alerts";


const Filters = (props) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [categoryFilterValue, setCategoryFilterValue] = useState(null);
  // const [value, setValue] = React.useState(props.globalFilter)
  // const count = props.preGlobalFilteredRows.length;

  // attached to onChange event listener of Global filter input box
  const onChange = useAsyncDebounce(value => {
    props.setGlobalFilter(value || undefined)
  }, 200)

  //called when a user selects filter start-date
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  }

  //called when a user selects filter end-date
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  }

  // Hnadles Selected Category filter option and update the state
  // const handleCategoryOptionsChange = (event) => {
  //   setCategoryFilterValue(event.target.value);
  // };

  // Auto Rest All Filters
  const autoResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setCategoryFilterValue(null);
    props.dispatch({ type: "resetFilters" });
    var radios = document.querySelectorAll('input[name="category-Filters"]');
    for (let i of radios) {
      if (i.checked) {
        i.checked = false;
        break
      }
    }
  }

  // Filter table by selected category option
  const handleFilterByCategory = () => {
    props.setFilter("category", categoryFilterValue);
  }

  // Filter table by selected start-date and end-date
  const handleFilterByDate = () => {
    if (startDate && endDate) {
      props.setFilter("date", [startDate, endDate]);
    }
  }

  // Handles all calls to filter the table <-- attached to onClick event of "apply filter button" -->

  useEffect(() => {
    const applyFilter = () => {
      if (startDate && endDate) {
        if (startDate > endDate) {
          notify("Please Make sure your start-date is not greater than your end-date", { type: 'error' });
        } else {
          handleFilterByDate();
        }
      }
      // if (!startDate && endDate || startDate && !endDate) {
      //   // window.alert("Please Make sure you select start-date and end-date");

      //   // handleFilterByDate();
      // }
    }
    if (startDate || endDate) {
      applyFilter()
    }
  }, [startDate, endDate])

  return (
    <div>

      {/** Datepicker and reset Button section */}
      <div className="filterParameters" id="filterParameters">
        <div className="datePickerWrapper">
          <div className="d-sm-flex align-items-end gap-2">
            <div className="form-inline">From:
              <input type='date' className="form-control" onChange={handleStartDate} value={startDate || ''} />
            </div>
            <div className="form-inline">To:
              <input type='date' className="form-control" onChange={handleEndDate} value={endDate || ''} />
            </div>
            <button onClick={autoResetFilter} className='btn btn-primary'><i className="fa fa-refresh"></i></button>
          </div>

        </div>
      </div>
    </div >
  )
}

export default Filters;
