import { notify } from 'components/Alerts'
import { format } from 'date-fns'
import React, { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { userSelector } from './state/user'
import useUserActions from './useUserActions'

const useNetwork = () => {
  const { getNetwork } = useUserActions()
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const userDetails = useRecoilValue(userSelector)
  const [selected, setSelected] = useState('')
  const linkRef = useRef(null)
  const [mailcontent, setMailcontent] = useState({
    email: '',
    subject: 'Invitation to Milmap',
    url: `${window.location.origin}/signup/${userDetails?.unique_id}`,
    body:
      ` hi,\nClick the link below to sign up on milmap.\n`,
  })

  const handleCopy = (e) => {
    if (linkRef.current) {
      navigator.clipboard.writeText(linkRef.current.value)
      notify('Link has been saved to clipboard', { type: 'success', duration: 3000 })
      setSelected('')
    }
  }

  const { data: myNetwork, isLoading } = useQuery(['network'], getNetwork, {
    // select: (res) => {
    //   const permOpt = res.data.fund_raising.data.map(item => {
    //     return { id: item.id, value: item.id, label: item.name }
    //   })

    //   return permOpt
    // },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })


  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: row => `${row.first_name} ${row.last_name}`,
      },
      {
        Header: 'Downliners',
        id: 'children',
        accessor: row => row.children.length,
      },
      {
        Header: 'Referred On',
        id: 'referred',
        accessor: row => format(new Date(row.created_at), 'EEEE LLLL do yyyy'),
      },
    ],
    []
  )
  const formatData = (data) => {
    return data?.map(item => ({
      id: item?.unique_id,
      name: `${item?.first_name} ${item?.last_name}`,
      ...(item.children && item.children.length > 0 && {
        children: formatData(item.children)
      })
    }))
  }

  const chartData = {
    id: userDetails.unique_id,
    name: `${userDetails.first_name} ${userDetails.last_name}`,
    ...(myNetwork?.data?.network && myNetwork.data.network.length > 0 && {
      children: formatData(myNetwork.data.network)
    })
  };

  return { userDetails, selected, mailcontent, setMailcontent, handleCopy, setSelected, linkRef, myNetwork, isLoading, columns, page, setPage, setData, chartData }
}

export default useNetwork
