import { format } from "date-fns"
import React from "react"
import { useQuery } from "react-query"
import { formatMoney, occurrencesOf, toStartCase } from "utils/functions"
import makeData from "utils/makeData"
import useReports from "./useReports"
import ButtonWithTips from "components/ButtonWithTips"

const useDonationCategoryReport = () => {
  const { getDonationByCategoryReport } = useReports()
  const [page, setPage] = React.useState(1)
  const [sFrom, setFrom] = React.useState('');
  const [sTo, setTo] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [level, setLevel] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const { data, isLoading } = useQuery(['donations',page, search,sFrom,sTo,level,  'category'], getDonationByCategoryReport(page, search,sFrom,sTo,level), {
    select: data => data.data.donation_report
  })
  const dataCategory = useQuery(['donations',page, search,sFrom,sTo,level, 'categories'], () => getDonationByCategoryReport(page, search,sFrom,sTo,level), {
    // select: data => data.data.donation_report,
    onSuccess: (res) => {
      setLoading(false)
      // setDonors(res.data.users.data)
      // const permOpt = res.data.donation_report.map(item => {
      //   return { id: item.id, value: item.id, email: item.email, phone: item.phone_mobile, label: toFullName({ firstName: item.first_name, lastName: item.last_name }) }
      // })
      // setData(res.data.donation_report)
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [bar_data, setBar_data] = React.useState([])
  const [line_data, setLine_data] = React.useState([])
  const [pie_data, setPie_data] = React.useState([])
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }

  const dummy_data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", 'Aug', "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "donations",
        backgroundColor: "rgba(2,117,216,1)",
        borderColor: "rgba(2,117,216,1)",
        data: bar_data,
      },
    ],
  };

  const dummy_line_data = {
    labels: line_data.length ? line_data.map(item => format(new Date(item.date), 'yyyy')) : [],
    datasets: [
      {
        fill: true,
        label: "Total donors",
        lineTension: 0.3,
        backgroundColor: "rgba(2,117,216,0.2)",
        borderColor: "rgba(2,117,216,1)",
        pointRadius: 5,
        pointBackgroundColor: "rgba(2,117,216,1)",
        pointBorderColor: "rgba(255,255,255,0.8)",
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(2,117,216,1)",
        pointHitRadius: 50,
        pointBorderWidth: 2,
        data: line_data.length ? line_data.map(item => item.count) : [],
      },
    ],
  };

  const dummy_pie_data = {
    labels: pie_data.length ? pie_data.map(item => item.level) : [],
    datasets: [
      {
        label: '# of Donations',
        data: pie_data.length ? pie_data.map(item => item.count) : [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }; 

  React.useEffect(() => {
    const donationReportByMonth = async () => {
      const today = format(new Date(), 'yyyy/MM/dd')
      const date = today.split('/')[0]
      const records = []
      const allRecord = []
      data.forEach(item => {
        if (item?.created_at?.includes(date)) {
          records.push(format(new Date(item.created_at), 'yyyy/MM/dd').split('/')[1])
          allRecord.push({ level: item?.user?.category.name, amount: item.transaction?.amount })
        }
      })
      const new_data = []
      await Array.from(Array(12).keys(), item => {
        const occurrences = occurrencesOf(item + 1, records)
        new_data.push(occurrences)
      })
      const occurrencesCycle = allRecord.sort((p1, p2) =>
        (p1.level > p2.level) ? 1 : (p1.level < p2.level) ? -1 : 0).reduce(function (allCycles, level) {
          if (allCycles.some(function (e) {
            return e.level === level.level
          })) {
            allCycles.filter(function (e) {
              return e.level === level.level
            })[0].count += +level.amount
          } else {
            allCycles.push({
              level: level.level,
              count: +level.amount
            })
          }
          return allCycles
        }, []);
      setPie_data(occurrencesCycle)

      setBar_data(new_data)
    }

    const donationsThisMonthFunc = () => {
      const records = []
      data.forEach(item => {
        records.push({ date: format(new Date(item.created_at), 'yyyy/MM/dd'), count: 0 })
      })
      const reduced = records.sort((p1, p2) =>
        (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allDates, date) {
          if (allDates.some(function (e) {
            return e.date.split('/')[0] === date.date.split('/')[0]
          })) {
            allDates.filter(function (e) {
              return e.date.split('/')[0] === date.date.split('/')[0]
            })[0].count += 1
          } else {
            allDates.push({
              date: date.date,
              count: +1
            })
          }
          return allDates
        }, []);
      setLine_data(reduced)
    }

    if (data) {
      donationReportByMonth()
      donationsThisMonthFunc()
    }
  }, [data])

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        id: 'date',
        accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
        filter: 'dateFilter'
      },
      {
        Header: 'Name',
        id: 'donor_name',
        accessor: row => toStartCase(row?.donor_name),
      },
      {
        Header: 'Phone Number',
        accessor: 'donor_phone_number',
      },
      {
        Header: 'Amount',
        id: 'donation',
        accessor: row => formatMoney(row?.transaction?.amount)
      },
      {
        Header: 'City',
        id: 'city',
        accessor: row => toStartCase(row?.user?.city?.name) || '-',
      },
      {
        Header: 'Category',
        id: 'category',
        accessor: row => toStartCase(row?.user?.category?.name) || '-',
      },
      {
        Header: 'Cycle',
        id: 'donation_cycle',
        accessor: row => toStartCase(row?.donation_cycle) || ' -',
      },
      {
        Header: 'Fundraise',
        accessor: 'donation_course.tagline',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Report Details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )

  function getExcel(){  
    getDonationByCategoryReport(1, search,sFrom,sTo,level,"export")
    .then((response) => {  
        
     const url = window.URL.createObjectURL(new Blob([response]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `category Report.xlsx`);
     document.body.appendChild(link);
     link.click();
  })
   }
  return { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, current, edit, 
    setFrom, setTo,setSearch,page,setPage,setLevel,dataCategory,loading,setLoading,getExcel,
    setEdit, dummy_data, dummy_line_data, dummy_pie_data, isLoading }
}

export default useDonationCategoryReport;
