import React from "react";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useAdminActions from "hook/useAdminActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notify } from "components/Alerts";
import { format } from "date-fns";
import ButtonWithTips from "components/ButtonWithTips";


const validationSchema = Yup.object({
  name: Yup.string()
    .required('Must not be empty'),
  permissions: Yup.array().min(1).required('at least one item needs to be here'),
})

const useRoleManagement = () => {
  const [data, setData] = React.useState([])
  const queryClient = useQueryClient()
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [options, setOptions] = React.useState(null)
  const [edit, setEdit] = React.useState(false)
  const [skipPageReset] = React.useState(false)
  const { getAllRoles, createARole, getARole, getAllPermissions, deleteARole, updateARole } = useAdminActions()

  React.useEffect(() => {
    const getPermissions = async () => {
      await getAllPermissions().then(res => {
        const permOpt = res.data.permissions.map(item => {
          return { id: item.id, value: item.id, label: item.name }
        }
        )
        setOptions(permOpt)
      }).catch(err => console.log(err))
    }

    getPermissions()
  }, [])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      permissions: [],
    },
    onSubmit: (values) => {
      const data = new FormData();
      data.append("name", values.name);
      values.permissions.forEach((permission, i) => data.append(`permissions[${i}]`, permission))
      mutate(data)
    },
  })

  const { mutate, isLoading } = useMutation(createARole, {
    onSuccess: (res) => {
      formik.resetForm()
      queryClient.invalidateQueries('roles')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify('post creation failed!', { type: 'error' })
    }
  })

  const { data: allRoles, isLoading: isDataLoading, isFetching } = useQuery(['roles', 'page', page], () => getAllRoles(page), {
    onSuccess: (res) => {
      const _roles = res.data.roles.data?.filter(item => {
        return item.id !== 1
      })
      setData(_roles)
    },
    onError: err => {
      notify('Failed to load roles!', { type: 'error' })
    },
    staleTime: Infinity
  })

  const { data: singleRole } = useQuery(['roles', 'id', currentData?.id], () => getARole(currentData?.id), {
    select: data => data.data.role,
    onError: err => {
      notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    enabled: current === 'view'
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Last Modified',
        id: 'updated_at',
        accessor: row => `${format(new Date(row.updated_at), 'yyyy/MM/dd')}`,
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View role Details" btnProps={{
              className: "btn btn-info", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
            <ButtonWithTips id={`delete`} tips="delete role" btnProps={{
              className: "btn btn-primary", onClick: () => {
                setCurrent('delete')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-trash" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )
  const updateMyData = () => {
    const details = {
      id: currentData.id,
      name: currentData.name,
      _method: 'PUT',
      permissions: currentData.permissions
    }

    updateRoleMutation(details)
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const { mutate: updateRoleMutation, isLoading: updateRoleLoading } = useMutation(updateARole, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('roles')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const { mutate: deleteRoleMutation, isLoading: deleteRoleLoading } = useMutation(deleteARole, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('roles')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const handleChangePermissions = async (e) => {
    if (e.length) {
      const permissions = e.map(item => item.value)
      formik.setFieldValue('permissions', permissions)
    } else {
      formik.setFieldValue('permissions', e)
    }
  }

  const handleUpdatePermissions = async (e) => {
    // const perms = singleRole?.permissions?.map(item => item.id)
    // const newPerm = [...perms];

    if (e.length) {
      const permissions = e.map(item => item.value)
      setCurrentData({ ...currentData, permissions: permissions })
    } else {
      setCurrentData({ ...currentData, permissions: e })
    }
  }

  const getDefaultValues = (arr) => {
    const permOpt = arr.map(item => {
      return { id: item.id, value: item.id, label: item.name }
    })
    return permOpt
  }

  return {
    formik, getDefaultValues, updateMyData, columns, open, setOpen, edit, setEdit, current, setCurrent, currentData, setCurrentData, skipPageReset, page, setPage, data, setData, updateRoleLoading, handleUpdatePermissions, handleChangePermissions, deleteRoleMutation, deleteRoleLoading, singleRole, allRoles, isDataLoading, isFetching, isLoading, options,
  }
}

export default useRoleManagement
