import React, { useEffect, useMemo, useRef, useState } from "react";
import { notify } from "components/Alerts";
import EnhancedTable from "components/Table";
import { usersAtom } from "../../hook/state/user";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import { format } from "date-fns";
import LoadingIndicator from 'components/LoadingIndicator';
import { routes } from "../../utils/routes";
// import { useRouter } from "next/router";
import  classNames  from  "../../utils/classNames";
import styles from "../../pages/innerPages/MessageChat/Chat/styles.module.css";
// import { token } from '../../../utils/constants';
import  baseUrl, { baseURL }  from "../../hook/useAxios";
import axios from 'axios';
import Pusher from "pusher-js"
import { authAtom } from "../../hook/state/auth";
import Echo from 'laravel-echo';
// import MessageBox from "../../textEditor/EditorModified";
import * as Yup from "yup";
import {useFormik} from "formik";
import {extractContent} from "../../utils/functions";
import Linkify from 'react-linkify';
import Modals from "components/Modal";
import useAdminActions from "hook/useAdminActions";
import Pagination from "components/innerComponents/Pagination";
import useUserActions from "hook/useUserActions";
import { useLocation } from 'react-router-dom';
import { getFCP } from "web-vitals";


//Filter list by category in React JS
const validationSchema = Yup.object({
    message: Yup.string()
        .required('Message body cannot be empty'),
})
export default function ManageChat({ handleOpenCLicked }) {
    const queryClient = useQueryClient()
    const bottomRef = useRef(null);

    // const router = useRouter()
    const location = useLocation();
    const [auth, setAuth] = useRecoilState(authAtom);
    const [edit, setEdit] = React.useState(false)
    const [data, setData] = useRecoilState(usersAtom)
    const [page, setPage] = React.useState(1)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [currentData, setCurrentData] = React.useState(null)
    const [currentId, setCurrentId] = React.useState(null)
    const [current, setCurrent] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [roomId, setRoomId] = React.useState('')
    const [chatMessage, setChatMessage] = useState([]);
    const [liveChatMessage, setLiveChatMessage] = useState([]);
    const ref = React.useRef();
    
    const routeCheck = location.pathname ===  routes.ADMIN_CHAT

    const {
        getUserChatRoom,
        getAllChatMessage,
        getDocuments,
        createMessage,
        getUnreadNotification,
        markNotificationAsRead,
    } = useUserActions()

    const {
        getAllAdminChat,
        updateAdminChat,
        deleteAdminChat,
    } = useAdminActions()

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Date',
                id: 'date',
                accessor: row => `${format(new Date(row.created_at), 'yyyy/MM/dd')}`,
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => (
                    <div className="avatar flex-wrap">
                        <button className="btn btn-info" onClick={e => {
                            setCurrent('view')
                            handleclick(row.row.original)
                        }}><i className="fa fa-eye" /></button>
                        <button className="btn btn-danger" onClick={e => {
                            setCurrent('delete')
                            handleclick(row.row.original)
                        }} ><i className="fa fa-trash" /></button>
                    </div>
                ),
            }
        ],
        []
    )

    const updateMyData = () => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        setData(old =>
            old.map((row) => {
                if (row.id === currentData.id) {
                    return {
                        ...currentData
                    }
                }
                return row
            })
        )
        notify('record edited successfully', { type: 'success' })
        setOpen(false)
    }
    const handleclick = (obj) => {
        const { id, ...rest } = obj;
        setOpen(true)
        setCurrentData(rest)
        setCurrentId(id)

    }
    const handleChange = (
        newValue,
        textValue
    ) => {
        if (textValue.length && textValue !== '\n') {
            formik.setFieldValue('message', newValue)
        } else {
            formik.setFieldError('message', 'Message cannot be empty')
        }
    };
    // console.log("MESSGAGAGAGAGA", message)
    const removeByIndexs = (array, index) =>
        array.filter((item) => item.id !== index)


    const [description, setDescription] = useState(null)


    const handleSubmit = (e) => {
        e.preventDefault()
        const data = new FormData();
        const id = currentId
        const val = { data, id }
        // data.append("name", name ? name : currentData?.name );
        data.append("description", description ? description : currentData?.description);
        data.append("_method", "PATCH");
        mutate(val)
    }


    const { mutate, isLoading } = useMutation(updateAdminChat, {
        onSuccess: (res) => {
            // notify('Profile updated successfully', { type: 'success' })

            notify(res.message, { type: 'success' })
            setTimeout(function () {
                window.location.reload();
            }, 1200)
        },
        onError: err => {
            console.log(err)
            notify('Chart Forum Update failed!', { type: 'error' })
        }
    })



    const deleteRecordHandler = () => {
        deleteChatMutation.mutate({ id: currentId })
    }

    const { data: chatData, isSuccess, isLoading: isLoadingChat, isFetching } = useQuery(['chat', page, roomId], () => routeCheck ? getAllAdminChat(page) : roomId && getAllChatMessage(roomId), {
        staleTime: Infinity,
        cacheTime: Infinity,

        onError: () => {
            if (!!auth?.token) {
                queryClient.invalidateQueries('chat')
            }
        }
    })

    const deleteChatMutation = useMutation(deleteAdminChat, {
        onSuccess: () => {
            queryClient.invalidateQueries('users')
            setOpen(false)
            notify('Chat Deleted successfully', { type: 'success' })
            window.location.reload()
        },
        onError: (error) => {
            //notify('Something went wrong', { type: 'error' })
        },
    })

    const [fetchMessage, setFetchMessage] = useState([])
    const [testVal, setTestVal] = useState("one")

    const A = liveChatMessage;

    const B = fetchMessage;

    const AllChatresult = A.concat(B.filter(bo => A.every(ao => ao.id != bo.id)));

    let liveData = liveChatMessage
    let fetchData = fetchMessage
    let chatMessageMapData = liveData.concat(fetchData)
    
    chatMessageMapData.sort((a, b) => {
        // let aa = new Date (a.data?.created_at || a.created_at).toLocaleString().substring(1, 20);
        // let bb = new Date(b.data?.created_at || b.created_at).toLocaleString().substring(1, 20);
        // let aa = new Date (a.data?.created_at || a.created_at).toISOString().slice(0,19).replace('T', ' ');
        // let bb = new Date(b.data?.created_at || b.created_at).toISOString().slice(0,19).replace('T', ' ');
        let aa = a.data?.created_at || a.created_at;
        let bb = b.data?.created_at || b.created_at;
        let aSliceTime = aa?.slice(0,19).replace('T', ' ')
        let bSliceTime = bb?.slice(0,19).replace('T', ' ')

        return (aSliceTime)?.localeCompare((bSliceTime))
    });

    useEffect(() => {
        if (chatData) {
            routeCheck ?
                setData(chatData?.data?.rooms)
                :
                setFetchMessage(chatData?.data?.messages)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData])
    useEffect(() => {
        const getRoom = async () => {
            await getUserChatRoom().then(res => {
                setRoomId(res.data?.room[0]?.id)
            }).catch(err => console.log(err))
        }
        !routeCheck && getRoom()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [chatMessageMapData]);

    const [typeMessage, setTypeMessage] = useState('')
// console.log("TTETEYEYEYEYEYEY", typeMessage)

    const createChartMessage = (e) => {
        e.preventDefault();
        const token = auth?.token;
        if(!typeMessage)
          return notify('Message Cannot Be Empty', { type: 'error' })

        const dataObj = {
            "message": typeMessage,
        }
        axios.post(`${baseURL}/user/chat/rooms/${roomId}/message`, dataObj, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(response =>
            setTypeMessage(''),
            ref.current.reset(),

        notify('Message sent', { type: 'success' }),
    )
            .catch(error => {
                notify('Error Occur', { type: 'error' })
            });
    }


    const formik = useFormik({
        validationSchema,
        initialValues: {
            message: '',
        },
        onSubmit: (values) => {
            const token = auth?.token;
            const dataObj = {
                "message": values.message,
            }
            axios.post(`${baseURL}/user/chat/rooms/${roomId}/message`, dataObj, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            }).then(response =>
                    setTestVal(""),
                formik.resetForm(),
            setTestVal(""),
            notify('Message sent', { type: 'success' }),

            // setTimeout(() => {
            //     window.location.reload()
            // }, 5000)
        )
                .catch(error => {
                    notify('Error Occur', { type: 'error' })
                });
        },
    })

    const [onlineUsersCount, setOnlineUsersCount] = useState(0);
    const [onlineUsers, setOnlineUsers] = useState([]);


    useEffect(() => {
        const token = auth?.token;
        const options = {
            broadcaster: 'pusher',
           
            key: '8c787933868ab5de165b',
            cluster: 'eu',
            encrypted: true,

            //authEndpoint is your apiUrl + /broadcasting/auth
          authEndpoint: `${baseURL}/user/broadcasting/auth`,
            // As I'm using JWT tokens, I need to manually set up the headers.
            auth: {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        };
        const echo = new Echo(options);

        var channel = echo.channel('private-groups.1');
        channel.listen('NewMessage', function(data) {
            console.log(data);
            const {username, message} = data
         setLiveChatMessage((prevState) => [
                ...prevState,
                { data },
            ]);

           chatMessageMapData =getUnique(chatMessageMapData,'message_id');
        });
        // channel.w

        // channel.listenForWhisper('WisperMessage',function(data){

        // });
        function getUnique(arr, index) {

            const unique = arr
                 .map(e => e[index])
          
                 // store the keys of the unique objects
                 .map((e, i, final) => final.indexOf(e) === i && i)
            
                 // eliminate the dead keys & store unique objects
                .filter(e => arr[e]).map(e => arr[e]);      
          
             return unique;
          }
        
        // var channel = echo.channel('chat-gateway');
        // channel.listen('.chat-event', function(data) {
        //     console.log(data);
        //   alert(JSON.stringify(data));
        // });

        // var channel = echo.channel('chat');
        // channel.notification((e)=>{
        //     console.log(e);
        // });
        // channel.listen('.App.Events.ChatEvent', function(data) {
        //     console.log(data);
        //   alert(JSON.stringify(data));
        // });



    //   echo.private('App.Models.User.' + auth?.user?.id).notification((data) => {
    //         console.log(data)
    //         // console.log(data);
    //         const {username, message} = data
    //      setLiveChatMessage((prevState) => [
    //             ...prevState,
    //             { data },
    //         ]);
    //     });
    }, []);
// console.log(chatMessageMapData);
    return (
        <>
            {
                routeCheck
                    ?
                    <>
                        <div className="container-fluid px-4">
                            <div
                                className="d-flex align-items-center justify-content-end my-4">
                            </div>
                            <div className="card mb-4">
                                <div className="card-header d-flex align-items-center  justify-content-between">
                  <span><i className="fa fa-group me-1"></i>
                    Chats</span>

                                    <div>
                                        {/*    <button className="btn btn-primary" onClick={() => {*/}
                                        {/*    setOpen(true)*/}
                                        {/*    setCurrent('add')*/}
                                        {/*}} disabled>Create Chat</button>*/}

                                        <button className="btn btn-primary" onClick={() => handleOpenCLicked('add')}>Create Chat Forum</button>

                                    </div>
                                </div>
                                <div className="card-body">
                                    {!isLoadingChat ? <>
                                    <EnhancedTable
                                        columns={columns}
                                        data={data || []}
                                        setData={setData}
                                        skipPageReset={skipPageReset}
                                    />

                                    <div className='container pb-5 pt-3'>
                                        <Pagination
                                            totalPages={chatData?.data?.last_page}
                                            page={page}
                                            pageChangeHandler={setPage}
                                            rowsPerPage={15}
                                            loading={isFetching}
                                        />

                                    </div>
                                    </> : <LoadingIndicator />}
                                </div>
                            </div>
                        </div>
                        <Modals show={open && current === 'view'}
                                onHide={() => setOpen(false)} title='View Chat' size='md' footer={<div className="avatar flex-wrap">
                            {routeCheck &&
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    if (edit) {
                                        setEdit(false)
                                        // updateMyData()
                                        updateMyData()
                                    } else {
                                        setEdit(true)
                                    }
                                }}>{edit ? '' : 'Edit'}</button>
                            }
                        </div>}>
                            {
                                !edit
                                    ?
                                    <dl className="striped">
                                        {currentData && Object.entries(currentData)?.map(([key, value]) =>
                                            <div key={key} className='row'>
                                                <dt className='col-4'>{`${key} : `}</dt>
                                                {(!edit || key === 'id' || key === 'status') && <dd className='col-8'>{value}</dd>}
                                                {/*{edit && key !== 'id' && key !== 'status' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}*/}
                                            </div>)}
                                    </dl>
                                    :
                                    <div className="row" key={currentId}>
                                        <div className="col-md-12">
                                            <div className={classNames(styles.newPostForm, ' mb-4')}>
                                                <form className='w-auto mx-auto' onSubmit={handleSubmit}>

                                                    <div className="row form-group">
                                                        <label htmlFor="description" className='col-sm-4'>Description</label>
                                                        <textarea
                                                            className="form-control col-sm-7"
                                                            name="description"
                                                            rows="4"
                                                            placeholder="description .."
                                                            defaultValue={currentData?.description}
                                                            onChange={(e) => {
                                                                setDescription(e.target.value)
                                                            }}
                                                        ></textarea>
                                                    </div>

                                                    <div className='mt-2'>
                                                        <button type='submit' className='btn button--burgundy' disabled={isLoading}>{isLoading ? <LoadingIndicator /> : 'Submit'}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                            }
                        </Modals>
                        <Modals show={open && current === 'delete'} size='sm'
                                onHide={() => setOpen(false)} title='Delete Room' footer={<button type="button" className="btn btn-primary" onClick={deleteRecordHandler}>Delete</button>}>
                            <div className="striped">
                                {currentData && <p>Are you sure you want to delete <b>{currentData.name}</b>?</p>}
                            </div>
                        </Modals>
                    </>

                    :
                    <div className="container-fluid px-1">
                        <div
                            className="d-flex align-items-center justify-content-end my-4">
                        </div>
                      

                            <div className="card-body" style={{paddingTop:"0"}}>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className='mb-2'>
                                            <div style={{height:"500px", overflowY:"scroll", marginBottom:"50px"}}>
                                                {/*{chatMessageMapData ? chatMessageMapData?.reverse()?.map((msg, idx) =>*/}
                                                {chatMessageMapData ? chatMessageMapData?.map((msg, idx) =>
                                                    <div key={idx} style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className={(msg?.data?.user_id || msg?.user_id) === auth?.user?.id ? "text-left margin-left-50" : "text-left"}>
                                                            <div className={(msg?.data?.user_id || msg?.user_id)=== auth?.user?.id ? "text-left text-white bg-lightgray px-2 py-2 w-300 b-rad5" : "text-left text-white bg-lightBlue w-300 py-2 px-2 b-rad5"}>
                                                                <p className='two_lines_text mb-0'>
                                                                    <Linkify>
                                                                    {/* from db msg?.message*/}
                                                                        {extractContent(msg?.data?.message || msg?.message)}
                                                                    </Linkify>
                                                                </p>
                                                            </div>
                                                            <label className="mb-0 font-weight-bold mb-4" style={{ fontSize: "12px", marginTop: "-15px", color: "black" }}>{msg?.data?.user.full_name || msg?.user?.full_name}</label>
                                                            <span style={{float:"right"}}>
                                                                    {`${msg?.created_at || msg?.data?.created_at ? new Date( msg?.created_at || msg?.data?.created_at).toString().substring(4,16) : ""}`}
                                                                </span>
                                                        </div>
                                                        <div ref={bottomRef} />

                                                    </div>
                                                ) :
                                                    <LoadingIndicator />
                                                }
                                            </div>

                                            <form className='w-auto mx-auto' onSubmit={formik.handleSubmit} ref={ref}>
                                            

                                                <div className="messages-compose">
                                                    <div className="new-message" style={{marginRight:"40px", marginLeft:"0px"}}>
                                                        <input
                                                            className="form-control col-11"
                                                            name="message"
                                                            id="message"
                                                            placeholder="message .."
                                                            onChange={(e) => {
                                                                setTypeMessage(e.target.value)
                                                            }}
                                                        />
                                                         <div className="new-message__wrapper">
                                                             <button onClick={(e) => createChartMessage(e)} type='submit' className='btn button--burgundy ' disabled={isLoading}>
                                                                {
                                                                isLoading ? <LoadingIndicator /> :  <i className="fa">&#xf1d9;</i>
                                                                }
                                                               
                                                               
                                                                </button>
                                                         </div>
                                                    </div>
                                                </div>

                                               
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/*</div>*/}

                    </div>
            }

        </>
    );
}
