import { useEffect, useRef } from "react";
import { DataTable } from "simple-datatables";

const Table = ({ children }) => {
    const tableRef = useRef(null)
    useEffect(() => {
        const datatablesSimple = tableRef?.current;
        if (datatablesSimple) {
            new DataTable(datatablesSimple, {
                responsive: true
            });
        }
    }, [])
    return (
        <table ref={tableRef} className="table table-striped">
            {children}
        </table>
    );
}

export default Table;
