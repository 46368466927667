import { useNavigate } from "react-router-dom";
import AuthLayout from "components/innerComponents/AuthLayout";
import LoadingIndicator from "components/LoadingIndicator";
import { useRecoilValue } from "recoil";
import useSignOnFee from "hook/useSignOnFee";
import { DASHBOARD, SIGNIN } from "navigation/routes";
import { userSelector } from "hook/state/user";
import { useEffect } from "react";

const SignupNextPage = () => {
    const navigate = useNavigate()
    const user = useRecoilValue(userSelector);
    useEffect(() => {
        if (!user) {
            navigate(SIGNIN)
        }
    }, [user])


    const handlePayFee = () => {
        const _data = {
            callbackUrl: `${window.location.origin}/signin`
        }
        paySignOnFeeMutation.mutate(_data)
    }

    const paySignOnFeeMutation = useSignOnFee()
    return (
        <AuthLayout>
            <div className="card shadow-lg border-0 rounded-lg mt-5 mb-5">
                <div className="card-header bg-red text-white"><h3
                    className="text-center font-weight-light my-4">Register</h3></div>
                <div className="card-body">
                    <p>Welcome to Actionaid {`${user?.first_name} ${user?.last_name}`}, click on the explore button to explore our app or click on the pay now button to make a first time donation</p>
                    <div className="text-center flex justify-content-center gap-2 social-btn">
                        <button type="submit" className="btn btn-primary" onClick={() => navigate(`/users/${DASHBOARD}`)}>Explore</button>
                        <button type="submit" className="btn btn-primary" onClick={() => handlePayFee()}>{paySignOnFeeMutation.isLoading && <LoadingIndicator />} Pay now</button>
                    </div>
                </div>

            </div>
        </AuthLayout>
    );
}

export default SignupNextPage;
