import { OverlayTrigger, Tooltip } from "react-bootstrap"


const ToolTips = ({ id, children, tooltip }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement="top"
      delayShow={300}
      delayHide={150}
    >
      {children}
    </OverlayTrigger>
  )
}

export default ToolTips
