import LoadingIndicator from 'components/LoadingIndicator';
import { getStatesByCountryId } from 'hook/state/generic';
import { Suspense, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Select from '../../innerComponents/Select';

const StateSelect = ({ selectedCountry, selected, handleChange, disabled = false, placeholder = 'State' }) => {
    const states = useRecoilValue(getStatesByCountryId(selectedCountry?.id));
    let selectedStates = null;
    if (states && selected) {
        selectedStates = states.find(item => item.id === selected.id)
    }
    return (
        <Suspense fallback={<LoadingIndicator />}>
            {!disabled ?
                <Select id="state" className="form-select" placeholder={placeholder} selected={selectedStates} handleChange={handleChange} options={states || null} disabled={disabled} />
                : <input className="form-control" id="selectedState" name='selectedState' type="text" value={selectedStates?.name} disabled={disabled} />}
        </Suspense>
    );
}

export default StateSelect;
