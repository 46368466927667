import React from 'react';
import PropTypes from 'prop-types';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import Pagination from './innerComponents/Pagination';
import ButtonWithTips from './ButtonWithTips';
// import { Pagination } from 'react-bootstrap'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  return (
    <span className="form-inline">
      {/* <DebouncedInput
        value={value ?? ''}
        className="form-control"
        onChange={(value) => {
          setValue(value);
          setGlobalFilter(String(value));
        }}
        placeholder={`Search ${count} records...`}
      /> */}
    </span>

    
  );
}

export default function MembershipEnhancedTable({
  columns,
  data = [],
  setData,
  skipPageReset,
  deleteRecords,
  pagesize,
  currentPage,
  setCurrentPage,
  lastPage,
  isFetching,
}) {
  // Use the state and functions returned from useTable to build your UI
  // const memoizedData = React.useMemo(() => data, [data])
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { selectedRowIds, globalFilter: globalFilterState },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      manualPagination: true,
      pageCount: lastPage,
      initialState: { pageSize: pagesize || 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              {deleteRecords && (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              )}
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              {deleteRecords && (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )}
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteRecordHandler = (event) => {
    const newData = removeByIndexs(
      data,
      Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
    );
    setData(newData);
  };

  //  Render the UI for your table
  return (
    <div>
      <div className="d-flex align-items-center flex-wrap justify-content-between gap-2 my-2">
        <div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilterState}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-2 mb-2">
        {deleteRecords && (
          <span title="Select record(s) to delete">
            <ButtonWithTips
              id="deleteRecords"
              tips="Click to delete records"
              btnProps={{
                disabled: Object.keys(selectedRowIds).length <= 0,
                className: 'btn btn-danger',
                onClick: deleteRecordHandler,
              }}
            >
              Delete Selected
            </ButtonWithTips>
          </span>
        )}
      </div>
      {Object.keys(selectedRowIds).length > 0 && (
        <p>
          {Object.keys(selectedRowIds).length} record(s) selected
          {/* {selectedFlatRows.map(
                        d => d.original.firstName
                    ).join(', ')} selected */}
        </p>
      )}
      <div className="table-responsive">
        <table className="table table-striped" {...getTableProps()}>
          {/* <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} key={i}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}

<thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.id}
                        style={{ fontSize: '14px' }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="container pb-5 pt-3">
        <Pagination
          totalPages={lastPage}
          page={currentPage}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={15}
          loading={isFetching}
        />
        {!data.length && !isFetching && (
          <div className="text-center">No entries found!</div>
        )}
      </div>
    </div>
  );
}
MembershipEnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  deleteRecords: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  lastPage: PropTypes.number,
  isFetching: PropTypes.bool,
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
