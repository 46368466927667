import { useFormik } from "formik"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as Yup from 'yup'
import { notify } from "components/Alerts"
import { fileToDataUri } from "utils/fileToData"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { userSelector } from "./state/user"
import useAdminActions from "./useAdminActions"
import classNames from "utils/classNames"
import ButtonWithTips from "components/ButtonWithTips"
const validationSchema = Yup.object({
  tagline: Yup.string()
    .required('Field cannot be empty'),
  description: Yup.string()
    .required('Must not be empty'),
  duration_in_days: Yup.number()
    .required('Must not be empty'),
  featured_image: Yup.mixed()
    .test('fileSize', 'File too large', (value) => value === null || (value && value.size < 1200000))
    .test(
      'fileFormat',
      'Unsupported file type',
      (value) => value === null || (value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type))
    ),
  start_date: Yup.string()
    .required('Field cannot be empty'),
  end_date: Yup.string()
    .required('Field cannot be empty'),
})
const useActivityAdmin = () => {
  const queryClient = useQueryClient()
  const user = useRecoilValue(userSelector)
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const [current, setCurrent] = React.useState(null)
  const [currentData, setCurrentData] = React.useState(null)
  const [img, setImg] = React.useState(() => currentData?.featured_image || null)
  const [open, setOpen] = React.useState(false)
  const [comment, setComment] = React.useState('')
  const navigate = useNavigate()
  const {
    getActivities,
    createNewActivity,
    updateAnActivity,
    deleteAnActivity, toggleActivityActiveness } = useAdminActions()

  const handleFeaturedImageChange = async (e) => {

    const image = e.target.files[0];
    if (image?.type?.match(/^image\//)) {
      await fileToDataUri(image)
        .then(async (_image) => {
          setImg(_image);
          formik.setFieldValue("featured_image", image);
        })
        .catch((error) => console.log(error))
    }

  };
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      tagline: '',
      description: '',
      featured_image: '',
      duration_in_days: '',
      start_date: '',
      end_date: '',
    },
    onSubmit: (values) => {
      const formData = new FormData()
      Object.entries(values).map(([key, value]) => key === 'categories' || key === 'tags' ? value?.map((item, i) => formData.append(`${key}[${i}]`, item)) : formData.append(key, value))
      formData.append('user_id', user?.id)
      createActivityMutation.mutate(formData)
    },
  })

  const { data: activities, isLoading: isActivityLoading, isFetching } = useQuery('activities', getActivities, {
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })

  const createActivityMutation = useMutation(createNewActivity, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('activities')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setOpen(false)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const updateActivityMutation = useMutation(updateAnActivity, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('activities')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
      setOpen(false)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const deleteActivityMutation = useMutation(deleteAnActivity, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('activities')
      setOpen(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const toggleActiveMutation = useMutation(toggleActivityActiveness, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('activities')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const handleStatusChange = (id, user, status) => {
    const formData = new FormData()
    formData.append('_method', 'PUT')
    formData.append('id', id)
    formData.append('status', status)
    formData.append('user_id', user)
    formData.append('notify_user', 1)
    if (status === 'rejected') {
      formData.append('comment', comment)
    }
    updateActivityMutation.mutate(formData)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Tagline',
        accessor: 'tagline',
      },
      {
        Header: 'Desciption',
        accessor: 'description',
      },
      {
        Header: 'Duration',
        accessor: 'duration_in_days',
      },
      {
        Header: 'Start',
        accessor: 'start_date',
      },
      {
        Header: 'End',
        accessor: 'end_date',
      },
      {
        Header: 'Published',
        id: 'is_active',
        accessor: row => <span className={classNames(row.is_active === '1' ? 'bg-success' : 'bg-danger', "d-block text-center  rounded-pill py-1 px-2 text-white")}>{row.is_active === '1' ? 'Published' : 'Not Published'}</span>
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => <span className={classNames(row.status === 'pending' ? 'bg-warning' : row.status === 'rejected' ? 'bg-danger' : 'bg-success', "d-block text-center  rounded-pill py-1 px-2 text-white")}>{row.status}</span>
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex gap-2 w-100">

            <ButtonWithTips id={`view`} tips="View Activity Details" btnProps={{
              className: "btn btn-info", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>

            <div className="dropdown">
              <ButtonWithTips id='dropdownMenuButtonTips' tips="Click to perform other actions" btnProps={{
                className: "btn",
                'data-toggle': "dropdown",
                'aria-haspopup': "true",
                "aria-expanded": "false",
                type: 'button',
                id: 'dropdownMenuButton'
              }}><i className="fa fa-ellipsis-v" aria-hidden="true" /></ButtonWithTips>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className="dropdown-item" onClick={() => {
                  setCurrent('delete')
                  handleclick(row.row.original)
                }}><i className="fas fa-trash" aria-hidden="true" /> Delete</button>

                <button className="dropdown-item" onClick={() => {
                  setCurrent('edit')
                  handleclick(row.row.original)
                }}><i className="fas fa-pencil" aria-hidden="true" /> Edit</button>

                {row.row.original.status !== 'approved' &&
                  <button className="dropdown-item" onClick={() => {
                    handleStatusChange(row.row.original.id, row.row.original.user_id, 'approved')
                  }} disabled={updateActivityMutation.isLoading}><i className={"fas fa-check-circle"} aria-hidden="true" /> Set as Approved</button>}
                {row.row.original.status !== 'rejected' && <button className="dropdown-item" onClick={() => {
                  setCurrent('rejected')
                  handleclick(row.row.original)
                }} disabled={updateActivityMutation.isLoading}><i className={"fa fa-times-circle"} aria-hidden="true" /> Set as Rejected</button>
                }
                <button className="dropdown-item" onClick={() => {
                  toggleActiveMutation.mutate({ id: row.row.original.id })
                }} disabled={toggleActiveMutation.isLoading || row.row.original.status !== 'approved'}><i className={row.row.original.is_active === '1' ? "fas fa-eye-slash" : "fa fa-eye"} aria-hidden="true" /> Set as {row.row.original.is_active === '1' ? 'In-Active' : 'Active'}</button>
              </div>
            </div>
          </div>
        ),
      }
    ],
    []
  )
  const handleReject = () => {
    if (!comment) {
      notify('Comment Cannot be empty', { type: 'error', duration: 5000 })
    } else if (!comment || comment?.length < 8) {
      notify('Your Comment is not explanatory enough', { type: 'error', duration: 5000 })
    } else {
      handleStatusChange(currentData.id, currentData.user_id, 'rejected')
    }
  }
  const deleteRecordHandler = () => {
    deleteActivityMutation.mutate({ id: currentData?.id })
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  return {
    activities, isActivityLoading, columns, isFetching, page, setPage, setData, currentData, current, open, setOpen, createActivityMutation, formik, setCurrent, img, setImg, handleFeaturedImageChange, setCurrentData, deleteRecordHandler, updateActivityMutation, comment, setComment, handleReject
  }
}

export default useActivityAdmin
