import { useFormik } from "formik"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useAdminActions from "./useAdminActions"
import * as Yup from 'yup'
import { notify } from "components/Alerts"
import { format } from "date-fns"
import useGenericActions from "./useGenericAction"
import { useLocation } from "react-router-dom"
import classNames from "utils/classNames"
import ButtonWithTips from "components/ButtonWithTips"

const useUtilities = () => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const [current, setCurrent] = React.useState(null)
  const [currentData, setCurrentData] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const { getFundRaisingTypes, updateFundRaisingTypes,addFundRaisingTypes,deleteAFundRaisingTypes } = useGenericActions()
  const {
    getAllCategories,
    createACategory,
    updateACategory,
    deleteACategory } = useAdminActions()

  const formik = useFormik({
    validationSchema: categorySchema,
    enableReinitialize: true,
    initialValues: {
      name: currentData?.name || '',
      description: currentData?.description || '',
    },
    onSubmit: (values) => {
      if (currentData) {
        const updateValues = {
          ...currentData,
          ...values,
        }
        updateMutation.mutate(updateValues)
      } else {
        const newValues = {
          ...values,
          is_active: 1
        }
        createMutation.mutate(newValues)
      }

    },
  })

  // const { data, isLoading, isFetching } = useQuery(['forum categories'], getAllCategories, {
  //   onError: err => {
  //     console.log(err)
  //     // notify('post creation failed!', { type: 'error' })
  //   },
  // })


  const { data: fundraisingTypes, isLoading: isLoadingFRT, isFetching: isFetchigFRT } = useQuery(['fundraisingtypes'], getFundRaisingTypes, {
    onError: err => {
      console.log(err)
    },
  })

  const createMutation = useMutation(addFundRaisingTypes, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('forum categories')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setOpen(false)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const updateMutation = useMutation(updateFundRaisingTypes, {
    onSuccess: (data) => {
      setOpen(false)
      queryClient.invalidateQueries('forum categories')
      notify(data.message, { type: 'success' })
      formik.resetForm()
      setCurrentData(null)
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const deleteMutation = useMutation(deleteAFundRaisingTypes, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('forum categories')
      setOpen(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Status',
        id: 'active',
        accessor: row => row.is_active ? <span className="d-block text-center bg-light-green rounded-pill py-1 px-2 text-white">Active</span> : <span className="d-block text-center bg-red rounded-pill py-1 px-2 text-white">Not Active</span>
      },
      {
        Header: 'Last Modified',
        id: 'updated_at',
        accessor: row => `${format(new Date(row.updated_at), 'yyyy/MM/dd')}`,
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => ( 
          <div className="flex flex-wrap gap-2 w-100">
            {/* {!location.pathname.includes('fundraiser-types') && <> */}

              <ButtonWithTips id={`edit`} tips="edit category" btnProps={{
                className: "btn btn-info", onClick: () => {
                  setCurrent('edit')
                  handleclick(row.row.original)
                }
              }}><i className="fas fa-pencil" /></ButtonWithTips>

              <ButtonWithTips id={`delete`} tips="delete category" btnProps={{
                className: "btn btn-primary", onClick: () => {
                  setCurrent('delete')
                  handleclick(row.row.original)
                }
              }}><i className="fas fa-trash" /></ButtonWithTips>
            {/* </>} */}
          </div>
        ),
      }
    ],
    [location.pathname]
  )

  const deleteRecordHandler = () => {
    deleteMutation.mutate({ id: currentData?.id })
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  // isLoading, columns, isFetching, page, setPage, setData, open, current, setOpen, createMutation, formik, setCurrent, currentData, setCurrentData, deleteRecordHandler, updateMutation, deleteMutation, fundraisingTypes, isFetchigFRT, isLoadingFRT
  return {
     columns,  page, setPage, setData, open, current, setOpen, createMutation, formik, setCurrent, currentData, setCurrentData, deleteRecordHandler, updateMutation, deleteMutation, fundraisingTypes, isFetchigFRT, isLoadingFRT
  }
}

export default useUtilities

const categorySchema = Yup.object({
  name: Yup.string()
    .required('Field cannot be empty'),
  description: Yup.string()
    .required('Must not be empty'),
})
