
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types'
import { useQuery } from 'react-query';
import useUserActions from 'hook/useUserActions';
import { useState } from 'react';
import { format } from "date-fns";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
    },
};

// export 

const LineChart = () => {
    // debugger 
    const [graph, setGraph]=useState(null);
    var amount=[];
    // var amount2=[5,10,30,50,20,5];
    var dateAcquired= [];
    const { getUserDonationDashboard } = useUserActions()
    const { isFetching, isLoading, data: history } = useQuery(['donations', 'history'], getUserDonationDashboard, {
        select: (data) => {
            
            var ret= data.data.user_donations.data
            
            ret.forEach(item => {
                amount.push(item.amount)
                dateAcquired.push(format(new Date(item.created_at), 'MMM dd yyyy'))
            });
        },
        onError: err => {
            console.log(err)
            // notify('post creation failed!', { type: 'error' })
        },
        staleTime: Infinity,
        cacheTime: Infinity
    })
    
    
    // var amount =history.fo
    const dummy_data = {
        // labels: ["Mar 1", "Mar 2", "Mar 3", "Mar 4", "Mar 5", "Mar 6", "Mar 7", "Mar 8", "Mar 9", "Mar 10", "Mar 11", "Mar 12", "Mar 13"],
        labels: dateAcquired,
        datasets: [
            {
                fill: true,
                label: "Sessions",
                lineTension: 0.3,
                backgroundColor: "rgba(2,117,216,0.2)",
                borderColor: "rgba(2,117,216,1)",
                pointRadius: 5,
                pointBackgroundColor: "rgba(2,117,216,1)",
                pointBorderColor: "rgba(255,255,255,0.8)",
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(2,117,216,1)",
                pointHitRadius: 50,
                pointBorderWidth: 2,
                data: amount,
            },
        ],
    };
    //dummy_data.datasets[0](history)
    return (
        <Line options={options} data={ dummy_data} />
    );
}

export default LineChart;
LineChart.propTypes = {
    data: PropTypes.object,
}
