import Password from 'components/innerComponents/Profile/Password';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Profile from '../Profile';

function SettingsPage() {
  const [key, setKey] = useState('Profile');

  return (

    <div className="growth">
      <div className="container px-4">
        <h1 className="mt-4">{key}</h1>
        <ol className="breadcrumb mb-4">
          <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
          <li className="breadcrumb-item active">{key.toLowerCase()}</li>
        </ol>
        <div className="row">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="Profile" title="Profile">
              <Profile />
            </Tab>
            <Tab eventKey="Passwords" title="Passwords">
              <Password />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
