import Input from 'components/innerComponents/Input';
import useActivityAdmin from 'hook/useActivityAdmin';
import React from 'react'
import { scrollToErrors } from "utils/functions";

const AddActivityFormAdmin = () => {
  const { formik, createActivityMutation, img, handleFeaturedImageChange } = useActivityAdmin()
  return (
    <form onSubmit={(e) => formik.handleSubmit(e)}>
      <div className="modal-body">
        <div className="form-group mb-3">
          <Input
            name='tagline'
            value={formik.values.tagline}
            type='text'
            placeholder='Tagline'
            label='Tagline'
            className='form-group'
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.errors.tagline && formik.touched.tagline ? formik.errors.tagline : null}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description">Description</label>
          <textarea className="form-control" id="description" name='description'
            placeholder="description" onChange={formik.handleChange} />
          {formik.errors.description && formik.touched.description && <small className="error">{formik.errors.description} </small>}
        </div>
        <div className="row mb-3">
          <div>
            <Input
              name='duration_in_days'
              value={formik.values.duration_in_days}
              type='number'
              placeholder='Duration(in days)'
              label='Duration(in days)'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.duration_in_days && formik.touched.duration_in_days ? formik.errors.duration_in_days : null}
            />
          </div>
          <div className='col-sm-6'>
            <Input
              name='start_date'
              value={formik.values.start_date}
              type='date'
              placeholder='From'
              label='From'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.start_date && formik.touched.start_date ? formik.errors.start_date : null}
            />
          </div>
          <div className='col-sm-6'>
            <Input
              name='end_date'
              value={formik.values.end_date}
              type='date'
              placeholder='To'
              label='To'
              className='form-group'
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.end_date && formik.touched.end_date ? formik.errors.end_date : null}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="media">Media</label>
            <input type="file" className="form-control" id="media" aria-describedby="media" aria-label="Upload" name="featured_image" onChange={(e) => {
              handleFeaturedImageChange(e);
              formik.setFieldValue("featured_image", e.target.files[0])
            }} />
            {formik.errors.featured_image && formik.touched.featured_image && <small className="error">{formik.errors.featured_image} </small>}

            <small>Only images of type png, jpeg, jpg, svg, webp and max size of 5mb are accepted</small>
            {img && <div className="mt-2 w-100">
              <img src={img} alt="" className="mw-100 rounded" />
            </div>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" onClick={() => scrollToErrors(formik.errors)} className="btn btn-primary" disabled={createActivityMutation.isLoading}>Create</button>
      </div>
    </form>
  )
}

export default AddActivityFormAdmin
