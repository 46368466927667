import React from 'react';
import Modals from '../../../components/Modal';
import { FORUM_MGT } from '../../../navigation/routes';
import useForumAdmin from 'hook/useForumAdmin';
import EnhancedTable from 'components/EnhancedTable';
import AddForumForm from './AddForum';
import EditForumForm from './EditForum';
import ButtonWithTips from 'components/ButtonWithTips';

const ForumMgt = () => {
  const {
    forums, columns, page, setPage, setData, isForumLoading, open, current, setCurrent, setOpen, deleteRecordHandler, currentData, setCurrentData, formik, img, categories, isLoadingCategories, selectedCategory, setSelectedCategory, createForumMutation, handleFeaturedImageChange, updateForumMutation
  } = useForumAdmin();

  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Forum Management</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item">
                <a href={FORUM_MGT}>Forum</a>
              </li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <div className="d-flex align-items-center justify-content-end mb-2">
            <ButtonWithTips
              id="addForum"
              tips="Create a new forum"
              btnProps={{
                className: 'btn btn-primary',
                onClick: () => {
                  setOpen(true);
                  setCurrent('add');
                },
              }}
            >
              <>Create Forum</>
            </ButtonWithTips>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Forums
          </div>
          <div className="card-body">
            <EnhancedTable
              columns={columns}
              data={forums?.data?.forums?.data || []}
              setData={setData}
              skipPageReset={false}
              lastPage={page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={isForumLoading}
            />
          </div>
        </div>
      </div>

      <Modals
        show={open && (current === 'add' || current === 'edit')}
        onHide={() => {
          setOpen(false);
          formik.resetForm();
        }}
        title={current === 'add' ? 'Create Forum' : 'Edit Forum'}
        size="md"
      >
        {currentData && current === 'edit' ? (
          <EditForumForm
            data={currentData} setData={setCurrentData} img={img} categories={categories} isLoadingCategories={isLoadingCategories} updateForumMutation={updateForumMutation}
          />
        ) : (
          <AddForumForm createForumMutation={createForumMutation} categories={categories} isLoadingCategories={isLoadingCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} formik={formik} img={img} handleFeaturedImageChange={handleFeaturedImageChange} />
        )}
      </Modals>
      <Modals
        show={open && current === 'delete'}
        onHide={() => setOpen(false)}
        title="Delete Advert"
        footer={
          <button
            type="button"
            className="btn btn-primary"
            onClick={deleteRecordHandler}
          >
            Delete
          </button>
        }
      >
        <div className="striped">
          {currentData && (
            <p>
              Are you sure you want to delete <b>{currentData.poster}</b>?
            </p>
          )}
        </div>
      </Modals>
    </>
  );
};

export default ForumMgt;
