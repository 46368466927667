import LoadingIndicator from 'components/LoadingIndicator';
import { Country } from 'country-state-city';
import { mapCountriesToState } from 'hook/state/generic';
import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import Select from '../../innerComponents/Select';

const CountrySelect = ({ selected, handleChange, disabled = false, placeholder = 'Country' }) => {
    const countries = useRecoilValue(mapCountriesToState);
    return (
        <Suspense fallback={<LoadingIndicator />}>
            {!disabled ? <Select id="country" placeholder={placeholder} className="form-select" selected={selected} handleChange={handleChange} options={countries} disabled={disabled} />
                : <input className="form-control" id="selectedCountry" name='selectedCountry' type="text" value={selected?.name} disabled={disabled} />}
        </Suspense>
    );
}

export default CountrySelect;
