import { format } from "date-fns"
import { selectedDonorAtom } from "hook/state/generic"
import useAdminActions from "hook/useAdminActions"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useRecoilState } from "recoil"
import { formatMoney, occurrencesOf, toFullName, toStartCase } from "utils/functions"
import makeData from "utils/makeData"
import useReports from "./useReports"
import ButtonWithTips from "components/ButtonWithTips"

const useDonorReport = () => {
  const [selectedDonor, setSelectedDonor] = useRecoilState(selectedDonorAtom);
  const [selectedDonorDetails, setSelectedDonorDetails] = useState(null);
  const { getDonorReport } = useReports()
  const options = []

  const { data, isLoading } = useQuery(['donors', 'report', selectedDonor?.value], () => getDonorReport(selectedDonor?.value), {
    select: data => data.data.donation_report,
    enabled: Boolean(selectedDonor?.value),
  })
  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [bar_data, setBar_data] = React.useState([])
  const [line_data, setLine_data] = React.useState([])
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }

  const dummy_data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", 'Aug', "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "defaulters",
        backgroundColor: "rgba(2,117,216,1)",
        borderColor: "rgba(2,117,216,1)",
        data: bar_data,
      },
    ],
  };

  const dummy_line_data = {
    labels: line_data.length ? line_data.map(item => format(new Date(item.date), 'MMM dd')) : [],
    datasets: [
      {
        fill: true,
        label: "Total defaulters",
        lineTension: 0.3,
        backgroundColor: "rgba(2,117,216,0.2)",
        borderColor: "rgba(2,117,216,1)",
        pointRadius: 5,
        pointBackgroundColor: "rgba(2,117,216,1)",
        pointBorderColor: "rgba(255,255,255,0.8)",
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(2,117,216,1)",
        pointHitRadius: 50,
        pointBorderWidth: 2,
        data: line_data.length ? line_data.map(item => item.count) : [],
      },
    ],
  };

  useEffect(() => {
    const donationReportByMonth = async () => {
      const today = format(new Date(), 'yyyy/MM/dd')
      const date = today.split('/')[0]
      const records = []
      const allRecord = []
      data.forEach(item => {
        if (item?.created_at?.includes(date)) {
          records.push(item.created_at.split('/')[1])
          allRecord.push({ level: item.level, count: 0 })
        }
      })
      const new_data = []
      await Array.from(Array(12).keys(), item => {
        const occurrences = occurrencesOf(item + 1, records)
        new_data.push(occurrences)
      })

      setBar_data(new_data)
    }

    const donationsThisMonthFunc = () => {
      const records = []
      data.forEach(item => {
        records.push({ date: item.created_at, count: 0 })
      })

      const reduced = records.sort((p1, p2) =>
        (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allDates, date) {
          if (allDates.some(function (e) {
            return e.date === date.date
          })) {
            allDates.filter(function (e) {
              return e.date === date.date
            })[0].amount += +date.amount
          } else {
            allDates.push({
              date: date.date,
              amount: +date.amount
            })
          }
          return allDates
        }, []);

      setLine_data(reduced)
    }

    if (data && data.length) {
      setSelectedDonorDetails(data[0])
      donationReportByMonth()
      donationsThisMonthFunc()
    } else {

      setSelectedDonorDetails(null)
    }
  }, [data, selectedDonor])

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const columns = React.useMemo(
    () => [
      { 
        Header: 'Date',
        id: 'date',
        accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
        filter: 'dateFilter'
      },
      {
        Header: 'Amount',
        id: 'donation',
        accessor: row => formatMoney(row?.amount)
      },
      {
        Header: 'Cycle',
        id: 'donation_cycle',
        accessor: row => toStartCase(row?.donation_cycle) || ' -',
      },
      {
        Header: 'Payment Purpose',
        accessor: 'payment_purpose',
      },
      {
        Header: 'Type',
        accessor:row => row.payment_purpose=="donation"? row.donation_course.tagline: '-',
      },
      {
        Header: 'Fundraise',
        accessor:row => row.payment_purpose=="fund_raising"? row.fund_raising_course?.name: '-',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Report Details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )
  return { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, current, edit, setEdit, dummy_data, dummy_line_data, options, selectedDonor, setSelectedDonor, isLoading, selectedDonorDetails }
}

export default useDonorReport;
