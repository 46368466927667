import useAxios from "hook/useAxios";

const useReports = () => {
  const axios = useAxios()
  const format={
    method: 'GET',
    responseType: 'blob', // important
};
  return {
    getDonationReport,
    getDonationByCategoryReport,getRecycleReport,getOneOffCampaignReport,getRecycleCampaignReport,
    getOneOffReport, getDonorReport, getDefaultReport,getCampaignDefaultReport, getDonorStatusReport,getfundRaiserReport,getIndividualfundRaiserReport
  }

  function getDonationReport(page=1, search, level,status,cycle='') {
    return axios.get(`/admin/transactions/donation_reports?page=${page}&filter[status]=${status}&filter[search]=${search}&filter[cycle]=${cycle}&filter[category]=${level}`);
  }

  function getDonationByCategoryReport(page, search,sFrom,sTo,level,download='e') {
    if(download==='e')
      return axios.get(`/admin/transactions/category_reports?page=${page}&filter[search]=${search}&filter[user.category.id]=${level}&download=${download}&filter[date_interval][]=${sFrom},${sTo}`);
    return axios.get(`/admin/transactions/category_reports?page=${page}&filter[search]=${search}&filter[user.category.id]=${level}&download=${download}&filter[date_interval][]=${sFrom},${sTo}`,format);
  }

  function getfundRaiserReport(page= 1, search, fundraiseType) {
    // &include=state,city
    return axios.get(`/admin/transactions/fundraiser_reports?page=${page}&filter[name]=${search}&filter[type_id]=${fundraiseType}`);
  }

  function getIndividualfundRaiserReport(page= 1, fundRaisingId) {
    // &include=state,city
    return axios.get(`/admin/transactions/fundraiser_reports/${fundRaisingId}?page=${page}`);
  }
  function getOneOffReport() {
    return axios.get(`/admin/transactions/oneOff_reports`);
  }

  function getRecycleReport(page, search, cycle,sFrom,sTo,download='e') {
    
    if(download==="export")
      return axios.get(`/admin/transactions/recycle_reports?page=${page}&download=${download}&filter[search]=${search}&filter[donation_cycle]=${cycle}&filter[date_interval][]=${sFrom},${sTo}`,format);
    else if(sFrom!="" && sTo!="")  
      return axios.get(`/admin/transactions/recycle_reports?page=${page}&download=${download}&filter[search]=${search}&filter[donation_cycle]=${cycle}&filter[date_interval][]=${sFrom},${sTo}`);
    else
    return axios.get(`/admin/transactions/recycle_reports?page=${page}&download=${download}&filter[search]=${search}&filter[donation_cycle]=${cycle}`);
  }

  function getOneOffCampaignReport(page,sFrom,sTo,search) {
    return axios.get(`/admin/transactions/campaign?page=${page}&filter[donation_cycle]=one-off&filter[search]=${search}&filter[date_interval][]=${sFrom},${sTo}`);
  }

  function getRecycleCampaignReport(page,sFrom,sTo,search,cycle) {
    return axios.get(`/admin/transactions/campaign?page=${page}&filter[search]=${search}&filter[date_interval][]=${sFrom},${sTo}&filter[donation_cycle]=${cycle}`);
    // return axios.get(`/admin/transactions/campaign?page=${page}&filter[donation_cycle]=one-off&filter[search]=${search}&filter[date_interval][]=${sFrom},${sTo}`);
  }
  function getDonorReport(payload) {
    return axios.get(`/admin/transactions/donor_reports?filter[user_id]=${payload}`);
  }

  function getDefaultReport(page, search, cycle,sFrom,sTo,level) {
    return axios.get(`/admin/transactions/default_reports?include=donor_status&page=${page}&filter[search]=${search}&filter[category]=${level}&filter[cycle]=${cycle}&filter[date_interval][]=${sFrom},${sTo}`);
  }

  function getCampaignDefaultReport() {
    return axios.get(`/admin/transactions/default_campaign_reports?include=donor_status`);
  }

  function getDonorStatusReport( page, sFrom, sTo, sStatus, search, download) {
    if(download==='e')
      return axios.get(`/admin/transactions/donor_status_reports?page=${page}&status=${sStatus}&download=${download}&filter[search]=${search}&filter[date_interval][]=${sFrom},${sTo}`, format);
    return axios.get(`/admin/transactions/donor_status_reports?page=${page}&status=${sStatus}&filter[search]=${search}&filter[date_interval][]=${sFrom},${sTo}`);
  }
}

export default useReports;
