
 import ManageChat from "components/message/ManageChat";
import NewChat from "../MessageChat/Chat/NewChat";
import styles from '../MessageChat/Chat/styles.module.css'
import {useState} from "react";

const ChatPage = () => {
  const [curentView, setCurentView] = useState('list');

  const handleOpenCLicked = (str) => {
    setCurentView(str);
  };

  const handleBackCLicked = () => {
    setCurentView('list');
  };
  return (
    <div>
      {curentView === 'list' ? (
          <div className={styles.wrapper}>
            <section className='mt-5'>
              <div className='container-fluid'>
                <div className="row">
                  <div className="col-12 mb-4" style={{ borderBottom: '1px solid #f1f1f1' }}><h1 className="" style={{ fontSize: '2rem' }}>
                    Manage Chat Forum
                  </h1>
                  </div>
                  <div className='col-12'>
                    <ManageChat handleOpenCLicked={handleOpenCLicked} />
                  </div>
                </div>
              </div>
            </section>
          </div>) : <NewChat handleBackCLicked={handleBackCLicked} />}
    </div>
  );
  
}
export default ChatPage;
