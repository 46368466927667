import CitySelect from 'components/common/Selects/CitySelect';
import CountrySelect from 'components/common/Selects/CountrySelect';
import StateSelect from 'components/common/Selects/StateSelect';
import EnhancedTable from 'components/EnhancedTable';
import Input from 'components/innerComponents/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import Modals from 'components/Modal';
import useDonationManagement from 'hook/useDonationManagement';
import { Suspense, useState } from 'react';
import { getNames, scrollToErrors } from 'utils/functions';
import Select from '../../../components/innerComponents/Select';
import { FORUM } from '../../../navigation/routes';
import { startCase } from 'lodash';
import { format } from 'date-fns';
import { excludedFields,excludedMessageFields } from 'utils/constants';
import ButtonWithTips from 'components/ButtonWithTips';
import MembershipEnhancedTable from 'components/MembershipEnhancedTable';
import SelectBox from 'components/common/Selects/SelectBox';
import useUserReport from 'hook/reports/useUserReport';
import useAdminActions from 'hook/useAdminActions';

const VolunteerMgt = () => { 
  
  const donation_cycles = [
    { id: 1, value: 'one-off', label: 'One Off' },
    { id: 2, value: 'recurrent', label: 'Recurrent' }];

    const user_level = [      
      { id: '', value: 'All', label: 'All' },
      { id: 1, value: 'Member', label: 'Member' },
      { id: 2, value: 'Champion', label: 'Champion' },
      { id: 3, value: 'Patriot', label: 'Patriot' }
      
    ];  
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('') 
    const {getAllDonorDownload} =useAdminActions();
    //   const {search,searchLevel, selectedState2, selectedCity2,sFrom,sTo } =useUserReport(); 
  const { 
    open,
    setOpen,
    allDonors,
    getAllDonors,
    setSearch,
    setSearchLevel, 
    setSelectedCity2,setSelectedState2, setFrom, setTo, level,
    search, selectedCity2,selectedState2,sFrom,sTo,   
    MembershipColumns, 
    skipPageReset,
    page,
    pagesize,
    setPage,
    setDonors,
    current,
    setCurrent,
    currentData,
    setCurrentData,
    messageDonor,
    messageDonorMutation,
    formik2,
    updateMyData,

    singleDonor,
    edit,
    setEdit
    
  } = useDonationManagement();
  function getExcel(){  
    getAllDonorDownload(1,search,level, selectedState2,selectedCity2,sFrom,sTo)
    .then((response) => {    
     const url = window.URL.createObjectURL(new Blob([response]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `userReport.xlsx`);
     document.body.appendChild(link);
     link.click(); 
  })
}
  return (
    <>
      <div className="container-fluid px-4">
        
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donors
          </div>
          <div className="card-body">
            <div className="row">
            <div className="col-2"  style={{width:'20%'}}>
            <input type='text' placeholder='Global Search Record' className="form-control"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
            </div>
            <div className="col-3" style={{width:'20%'}}>
            <Suspense fallback={<LoadingIndicator />} >
            <StateSelect selectedCountry={{id:'160'}} selected={selectedState} handleChange={(e) => {
                    setSelectedState(JSON.parse(e.target.value))                    
                    setSelectedState2(JSON.parse(e.target.value).id)
                    // formik.setFieldValue('state_id', JSON.parse(e.target.value)?.id)
                }} />
            </Suspense>
            
            </div>
            <div className="col-3" style={{width:'20%'}}>
              
            <Suspense fallback={<LoadingIndicator />} >              
                <CitySelect selectedState={selectedState} selected={selectedCity} handleChange={(e) => {
                  setSelectedCity(JSON.parse(e.target.value))
                  setSelectedCity2(JSON.parse(e.target.value).id)
                  // formik.setFieldValue('city_id', JSON.parse(e.target.value)?.id)
              }} />
            </Suspense>

            </div>
            
            <div className="col-2" style={{width:'20%'}}>
            <SelectBox
                    selectName="donation_group"
                    selectLabel={`Donation category`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={user_level}
                    onChange={(e) => {
                      setSearchLevel(e.id);
                    }}
                    selectOption={`Select Level`}
                   
                  />
            </div>

            <div className="col-3"> 
            <div className="filterParameters" id="filterParameters">
        <div className="datePickerWrapper">
          <div className="d-sm-flex align-items-end gap-2">
            <div className="form-inline">From:
              <input type='date' className="form-control" onChange={(e) => {
                setFrom(e.target.value);
              }}  />
            </div>
            <div className="form-inline">To:
              <input type='date' className="form-control" onChange={(e) => {
                setTo(e.target.value);
              }}/>
            </div>
            {/* <button className='btn btn-primary'><i className="fa fa-refresh"></i></button> */}
            <ButtonWithTips
                id="getExcel"
                tips="Click to download data in excel format"
                btnProps={{
                  className: 'btn btn-success',
                  onClick: getExcel,
                }}
              >
                <i className="fas fa-file-excel"></i>
              </ButtonWithTips>
          </div>

        </div>
      </div>
            </div>

            </div>
            
            <MembershipEnhancedTable
              columns={MembershipColumns}
              data={allDonors?.data?.data?.users?.data || []}
              // setData={setDonors}
              skipPageReset={skipPageReset}
              lastPage={allDonors?.data?.data?.users?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              pagesize={pagesize}
              isFetching={allDonors?.isFetching || allDonors?.isLoading}
            />
          </div>
        </div>
        <Modals
        show={open && current === 'messageDonation'  && messageDonor}
        onHide={() => setOpen(false)}
        size={'md'}
        title="Message Donor"
        footer={
          <button
            type="button"
            className="btn btn-primary"
            disabled={messageDonor?.isLoading}
            onClick={() => {              
                updateMyData('message_donor');
              
            }}
          >
            {messageDonorMutation.isLoading && <LoadingIndicator />}
            Send Message
          </button>
        }
      >
       <form onSubmit={formik2.handleSubmit}>
        {messageDonor?.data &&
        Object.entries(messageDonor?.data)?.map(([key, value]) => 
          excludedFields.includes(key) ? (
            []
          ):  key === 'phone_mobile'  ?(
        
            <div key={key} className="row">
                  <dt className="col-4">Phone Number</dt>
                  <dd className="col-8">
                  <input
                    className="form-control"
                    type='text'
                    defaultValue={value}
                    readOnly
                    onChange={(e) =>
                      setCurrentData({
                        ...currentData,
                        [key]: e.target.value,
                      })
                    }
                  />
                  </dd>
                </div>
            
         
          ): key === 'email'?(
      

            <div className="row">
                  <dt className="col-4">Email</dt>
                  <dd className="col-8">
                  <input
                      className="form-control"
                      type='email'
                      defaultValue={value}
                      readOnly
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          [key]: e.target.value,
                        })
                      }
                    />
                  </dd>
                </div>
            
          ):(''))}
             
             <div className="row">
                  <dt className="col-4">Message</dt>
                  <dd className="col-8">
                  <textarea
                    name="message"                   
                    placeholder="message"
                    label="message"
                    style={{height: 100, width:'100%'}}
                    onChange={(e) =>
                      setCurrentData({
                        ...currentData,
                        message: e.target.value,
                      })
                    }
                  />
                  </dd>
                </div>
          </form>
      </Modals>

      <Modals
        show={open && current === 'viewDonor' && singleDonor}
        onHide={() => {
          setEdit(false);
          setOpen(false);
        }}
        title="View User"
        size="md"
        footer={
          <button
            type="button"
            className="btn btn-primary"
            disabled={singleDonor?.isLoading}
            onClick={() => {
              if (edit) {
                setEdit(false);
                updateMyData('donor');
              } else {
                setEdit(true);
              }
            }}
          >
            {/* {updateDonorMutation.isLoading && <LoadingIndicator />} */}
            {edit ? 'Update' : 'Edit'}
          </button>
        }
      >
        {singleDonor?.isLoading && <LoadingIndicator />}
        <dl className="striped">
          {singleDonor?.data &&
            Object.entries(singleDonor?.data)?.map(([key, value]) =>
              excludedFields.includes(key) ? (
                []
              ) : key === 'state' || key === 'country' || key === 'city' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">{value?.name}</dd>
                </div>
              ) : key==='category'||key==='present_level_contribution'||key==='next_level_contribution'?(
                <div></div>
              )
              
              
              
              : key === 'updated_at' || key === 'created_at' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">
                    {format(new Date(value), 'yyyy/MM/dd')}
                  </dd>
                </div>
              ) : typeof value === 'boolean' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">{value ? 'Yes' : 'No'}</dd>
                </div>
              ) : (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  {(!edit || key === 'unique_id' || key === 'email') && (
                    <dd className="col-8">{value}</dd>
                  )}
                  {edit && key !== 'unique_id' && key !== 'email' && (
                    <div className="col-8">
                      {key === 'gender' ? (
                        <select
                          className="form-select"
                          defaultValue={value || ''}
                          onChange={(e) =>
                            setCurrentData({
                              ...currentData,
                              [key]: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            Choose one...
                          </option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                        </select>
                      ) : (
                        <input
                          className="form-control"
                          type={key === 'date_of_birth' ? 'date' : 'text'}
                          defaultValue={value}
                          onChange={(e) =>
                            setCurrentData({
                              ...currentData,
                              [key]: e.target.value,
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              )
            )}
        </dl>
      </Modals>
      </div>

     
    </>
  );
};

export default VolunteerMgt;
