import Helmet from 'react-helmet';
import CallToAction from '../components/common/CTA';
import Interests from '../components/common/Interests';
import CommonLayout from '../components/common/Layout';
import OurPartners from '../components/common/OurPartners';
import Patriots from '../components/common/Patriots';
import Services from '../components/common/Services';
import WhoWeAre from '../components/common/WhoWeAre';
import CommunitySponsorship from '../components/common/AboutPageContent/ComunitySponsorship';
import WhatWeDo from '../components/common/AboutPageContent/WhatWeDo';
import FocusAreas from '../components/common/AboutPageContent/FocusAreas';
import DonationCommon from '../components/common/Donation';
import Header from 'components/common/Header';
import Banner from 'components/common/Banner';
import Footer from 'components/common/Footer';

const DonationPage = () => {
  return (
    <div id="page-top">
      <Helmet>
        <title>
          Donate | An ActionAid Nigeria’s Community Sponsorship Initiative
        </title>
      </Helmet>
      <Header />
      {/* <Banner
        img={''}
        pageText={''}
        page={'donate'}
      /> */}
      {/* <main> */}
      <div
        className="donation-page"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(28, 28, 28,0.2) 100%), url(/img/milmap-home-hero-bg.jpg)`,
        }}
      >
        <div className="donation-page-overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-6  mb-3 offset-lg-3">
                <div className="donation-content">
                  <h1 className="section-title text-center mt-0 text-white">
                    Donate to End the Scourge of Poverty in Nigeria Today.
                    <span className="subtitle  text-white">
                      Donations can be done in two ways, as a regular donation
                      or as an anonymous donation
                    </span>
                  </h1>
                  <DonationCommon />
                  <p className="mt-3 text-white">
                    With your help we take on the big issues, wherever it
                    matters. We confront the problems, the causes that keep
                    people poor, like inequality, discrimination against women,
                    climate change, and the eviction of people from their land.
                    We support and assist people to build better lives for
                    themselves. And we won’t stop until every person on the
                    planet can enjoy a life free from poverty.
                  </p>
                  <h3 className="text-white">
                  With your regular support, your donations would:
                  </h3>
                  <ul className="text-white">
                    <li>
                    Help communities grow their own food, so children and families won’t go hungry. (Food Sustainability)
                    </li>
                    <li>
                    Help provide the health care that families need to thrive. (Health)
                    </li>
                    <li>
                    Provide a conducive learning environment for children and youths so that they can work out of poverty. (Education)
                    </li>
                    <li>
                    Provide access to clean and portable drinking water for children/ women. (Water Sanitation/WASH)
                    </li>
                    <li>
                    Help displaced families have secured homes and provide food/nonfood supplies for them. ( Conflict and Emergency Humanitarian Response)
                    </li>
                    <li>
                    Support our work to advocate for better governance, policies, and laws for poor communities. (Governance)
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </main> */}
      <Footer />
    </div>
  );
};

export default DonationPage;
