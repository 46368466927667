import { notify } from 'components/Alerts';
import useAdminActions from 'hook/useAdminActions';
import useUserActions from 'hook/useUserActions';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toInitials } from 'utils/functions';
import { fileToDataUri } from '../../../utils/fileToData';
import './index.css'

const ProfilePicture = ({ edit, setEdit, user }) => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [image, setImg] = useState(user?.profile_picture);
  const { updateAdminProfilePicture } = useAdminActions()
  const { updateUserProfilePicture } = useUserActions()
  const routeCheck = location.pathname.includes('admin')
  const handleChange = async (e) => {
    const image = e.target.files[0];
    const data = new FormData();

    if (image.type.match(/^image\//)) {
      if (image?.size <= 1200000) {
        setLoading(true);
        await fileToDataUri(image)
          .then(async (_image) => {
            setImg(_image);
            setShow(false);
            data.append("profile_picture", image);
            if (routeCheck) {
              await updateAdminProfilePicture(data).then(res => notify(res.message, { type: 'success' }))
            } else {
              await updateUserProfilePicture(data).then(res => notify(res.message, { type: 'success' }))
            }
            queryClient.invalidateQueries('profile')
          })
          .catch((error) => console.log(error))
          .finally(() => {
            setLoading(false);
          });
      } else {
        setMsg('file must be 1.2mb or less');
      }
    } else {
      setMsg('Only image files are accepted');
    }
  };

  React.useEffect(() => {
    if (msg) {
      setShow(true);
    }
  }, [msg]);
  return (
    <div className="picture">
      <div className="innerSection">
        <label
          className="avatar"
          style={{ position: 'relative', width: 'max-content' }}
        >
          <div>
            {image ? (
              <img
                src={image}
                width="100px"
                height="100px"
                alt="avatar"
                className="rounded-circle shadow-4"
              />
            ) : loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="initials-wrapper flex align-items-center justify-content-center bg-info rounded-circle ">
                <p className="initials">{toInitials(`${user?.first_name} ${user?.last_name}`)}</p>
              </div>
            )}
          </div>
        </label>
        <div>
          <div className="profile">
            <p style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <span>{`${user?.first_name} ${user?.last_name}`}</span>
            </p>
          </div>
          {!show && (
            <p className="helper">
              Max file size allowed: <b>1200kb</b>
            </p>
          )}
          <div className="btns">
            <label className=" btn btn-primary">
              <input type="file" accept="image/*" className='hidden' onChange={handleChange} />
              <p
                style={{
                  margin: 0,
                }}
              >
                <span>Change Picture</span>
              </p>
            </label>
            <div>
              <button className="btn btn-info" onClick={() => setEdit(!edit)}>
                {edit ? 'Cancel' : 'Edit profile'}
              </button>
            </div>
          </div>
          {show && msg === 'file must be 400kb or less' && (
            <p className="helper error">
              {msg} <br />
              You can go to{' '}
              <a href="https://tinypng.com/" target="_blank">
                <strong>https://tinypng.com</strong>
              </a>{' '}
              to compress your image
            </p>
          )}
          {show && msg !== 'file must be 400kb or less' && (
            <p className="helper error">{msg}</p>
          )}

        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
