import { Link } from "react-router-dom";
import { SIGNUP } from "../../navigation/routes";

const CallToAction = () => {
    return (
        <section className="row section-cta gx-0">
            <div className="col-lg-7 col-sm-12 cols-1" style={{ backgroundImage: 'url(./img/section-cta-bg.jpg)' }}>
                <div className="image-wrap">
                </div>
            </div>
            <div className="col-lg-5 col-sm-12 cols-2">
                <div className="info-wrap">
                    <div className="title-wrap">
                        <h1>Milmap
                            <span>Million Movement of People Against Poverty</span></h1>
                    </div>
                </div>
                <div className="prose-wrap">
                    <p className="text-white">This is a movement of Activists raising voices and resources to support the poor, facing discrimination and whose voices are ignored.</p>
                </div>
                <div className="action-wrap">
                    <Link className="btn btn-hero-alt" to={SIGNUP}>Join Movement</Link>
                </div>
            </div>
        </section>
    );
}

export default CallToAction;
