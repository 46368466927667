import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useDonationReport from "hook/reports/useDonationReport";
import { DONATION_ANALYTICS } from "navigation/routes";
import { useState } from "react";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import Select from "components/innerComponents/Select";
import useDonationManagement from "hook/useDonationManagement";
import SelectBox from "components/common/Selects/SelectBox";
import MembershipEnhancedTable from "components/MembershipEnhancedTable";

const DonationReport = () => {
  const { currentData, setCurrentData, open, setOpen,
    setSearch,
    setSearchLevel,
    setSearchStatus ,
    setSearchCycle ,

    updateMyData, skipPageReset, columns, data,pagesize,dataReport, setData, current, edit, setEdit, isLoading } = useDonationReport()
  const user_level = [      
    { id: '', value: 'All', label: 'All' },
    { id: 1, value: 'Member', label: 'Member' },
    { id: 2, value: 'Champion', label: 'Champion' },
    { id: 3, value: 'Patriot', label: 'Patriot' }
    
  ]; 

    const donation_cycles = [
    { id: 1, value: 'monthly', label: 'Monthly' },
    { id: 2, value: 'quarterly', label: 'Quarterly' },
    { id: 3, value: 'yearly', label: 'Yearly' }] 
  const { 
    page,
    setPage, 
    statuses,
    
    allDonors
         
  } = useDonationManagement();
 
  return (
    <>

      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donation Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><a href='{FORUM_MGT}'>Donations</a></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONATION_ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donations
          </div>
          <div className="row">
          <div className="col-3">
              <input type='text' placeholder='Global Search Record' className="form-control" 
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
            </div>
            <div className="col-3" style={{width:'20%'}}>
            <SelectBox
                    selectName="donation_group"
                    selectLabel={`Donation category`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={user_level}
                    onChange={(e) => {
                       setSearchLevel(e.id);
                    }}
                    selectOption={`Select Level`}
                   
                  />
            
            </div>
            <div className="col-2" style={{width:'20%'}}>
            
            <Select
                className="form-select"
                // selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  var id=JSON.parse(e.target.value)?.value
                  setSearchCycle(id);
               }}
                
                options={donation_cycles}
              />
            </div>
          </div>
          <div className="card-body">
           
          <MembershipEnhancedTable
              columns={columns}
              data={dataReport?.data?.data?.donation_report || []}
              setData={setData}
              skipPageReset={skipPageReset} 
              // lastPage={data.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allDonors?.isFetching || allDonors?.isLoading}
            />


            {/* <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              pagesize={pagesize}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              isLoading={isLoading}
              fileName='Donations'
            /> */}
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donation' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  );
}

export default DonationReport;
