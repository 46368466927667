import LoadingBox from 'components/LoadingBox'
import useAdminActions from 'hook/useAdminActions'
import useUserActions from 'hook/useUserActions'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { toStartCase } from 'utils/functions'

const ViewActivity = ({ id }) => {
  const location = useLocation()
  const { getAnActivity } = useUserActions()
  const { getAnActivity: getAnActivityAdmin } = useAdminActions()

  const { data: activityData, isLoading: isDataLoading, isFetching, isError } = useQuery(['forum post', id], () => location.pathname.includes('admin') ? getAnActivityAdmin(id) : getAnActivity(id), {
    select: data => data.data.activity,
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })
  return (
    <div className='container mt-3'>
      {isDataLoading ? <LoadingBox /> : !isError ?
        <div className="blog-post">
          <div className='blog-post__image--container'><img className="blog-post__image rounded" src={activityData?.featured_image} alt={activityData?.tagline} /></div>
          <h2 className="blog-post__title">{toStartCase(activityData?.tagline)}</h2>
          <p className="blog-post__author">{`by ${activityData?.user?.first_name} ${activityData?.user?.last_name}`}</p>
          <p className="blog-post__content">{activityData?.description}</p>
        </div> : ''}
    </div>
  )
}

export default ViewActivity
