import { Spinner } from "react-bootstrap";

const LoadingIndicator = ({ as = 'div', size = 'sm' }) => {
  return (
    <Spinner as={as} size={size} animation="border" role="status"
      aria-hidden="true">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default LoadingIndicator;
