import Input from 'components/innerComponents/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react'
import { useState } from 'react';
import { fileToDataUri } from 'utils/fileToData';

const EditActivityForm = ({ data, setData, updateActivityMutation }) => {
  const [img, setImg] = useState(() => data?.featured_image)

  const handleFeaturedImageChange = async (e) => {
    const image = e.target.files[0];
    if (image?.type?.match(/^image\//)) {
      await fileToDataUri(image)
        .then(async (_image) => {
          setImg(_image)
        })
        .catch((error) => console.log(error))
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    const { id, description, tagline, duration, start_date, end_date, featured_image } = data
    const newdata = {
      id, description, tagline, duration, start_date, end_date, featured_image, _method: 'PUT'
    }
    const formData = new FormData()
    Object.entries(newdata).map(([key, value]) => key === 'featured_image' && typeof value === 'string' ? [] : formData.append(key, value))
    updateActivityMutation.mutate(formData)
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="modal-body">
        <div className="form-group mb-3">
          <Input
            name='tagline'
            value={data?.tagline}
            type='text'
            placeholder='Tagline'
            label='Tagline'
            className='form-group'
            handleChange={(e) => setData({ ...data, tagline: e.target.value })}
            error={null}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description">Description</label>
          <textarea className="form-control" id="description" name='description'
            placeholder="description"
            value={data?.description}
            onChange={(e) => setData({ ...data, description: e.target.value })} />
        </div>
        <div className="row mb-3">
          <div>
            <Input
              name='duration_in_days'
              value={data?.duration_in_days}
              type='number'
              placeholder='Duration(in days)'
              label='Duration(in days)'
              className='form-group'
              handleChange={(e) => setData({ ...data, duration_in_days: e.target.value })}
              error={null}
            />
          </div>
          <div className='col-sm-6'>
            <Input
              name='start_date'
              value={data?.start_date}
              type='date'
              placeholder='From'
              label='From'
              className='form-group'
              handleChange={(e) => setData({ ...data, start_date: e.target.value })}
              error={null}
            />
          </div>
          <div className='col-sm-6'>
            <Input
              name='end_date'
              value={data?.end_date}
              type='date'
              placeholder='To'
              label='To'
              className='form-group'
              handleChange={(e) => setData({ ...data, end_date: e.target.value })}
              error={null}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="media">Media</label>
            <input type="file" className="form-control" id="media" accept="image/*" aria-describedby="media" aria-label="Upload" name="featured_image" onChange={(e) => {
              handleFeaturedImageChange(e);
              setData({ ...data, featured_image: e.target.files[0] })
            }} />
            {img && <div className="mt-2 w-100">
              <img src={img} alt="" className="mw-100 rounded" />
            </div>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary" disabled={updateActivityMutation.isLoading}>{updateActivityMutation.isLoading && <LoadingIndicator />}Update</button>
      </div>
    </form>
  )
}

export default EditActivityForm
