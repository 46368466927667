import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { format } from 'date-fns';
import CommonLayout from "../../components/common/Layout";
import useGenericActions from "hook/useGenericAction";
import { useMutation, useQuery, useQueryClient } from "react-query";
import LoadingBox from "components/LoadingBox";
import { notify } from "components/Alerts";
import { useRecoilValue } from "recoil";
import { userSelector } from "hook/state/user";

const SingleBlogPage = () => {
    const { id } = useParams();
    const {addAComment, getAPost } = useGenericActions();
    
    const userDetails = useRecoilValue(userSelector);
    const queryClient = useQueryClient();
    const [comments, setComments] = useState('');
    const [email, setEmail] = useState(userDetails?.email); 
    const [name, setName] = useState(userDetails? userDetails?.first_name +' '+userDetails?.last_name:'');
    const { data, isLoading } = useQuery(['forum post', id], () => getAPost(id), {
        select: data => data.data.forum
    })

    const { mutate, isLoading2 } = useMutation(addAComment, {
        onSettled: res => {
          if (res.success) {
            queryClient.invalidateQueries('forum post')
            notify(res.message, { type: 'success' })
            setComments('')
          }
          console.log(res)
        }
      })
    return (
        <CommonLayout pageText={data?.title} img={data?.featured_image}>
            <Helmet>
                <title>Blog | An ActionAid Nigeria’s Community Sponsorship Initiative</title>
            </Helmet>
            {isLoading ? <LoadingBox /> :
                <div className="container">
                    <div className="row m-5">
                        {data?.created_at && <p>{
                            format(new Date(data?.created_at), 'yyyy-MM-dd')}</p>}
                        <div>{parse(data?.body || '')}</div>
                    </div>


                    <div className="col-lg-3 col-md-6 text-center interest">
                            <div className="mt-5 interest-wrap">
                                <div className="mb-2 card-image">
                                <img src={data?.featured_image} alt="" />
                                </div>
                               
                                <div className="hover-canvas" style={{ backgroundImage: `url(${data?.featured_image})` }}>
                                <div className="hover-content">
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                    <div className="row">
                        <h1 className="section-title text-center mt-0">
                            Leave a comment
                            <span className="subtitle">
                                Be the first to leave a comment.Your email address will not be published.
                            </span>
                        </h1>
                        <div className="col-lg-6 offset-lg-3">
 
                            {/* <form> */}
                                <div className="form-floating mb-3">
                                    <input className="form-control" 
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                        placeholder="My new topic" />
                                    <label htmlFor="topic">Name</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input className="form-control" 
                                     type="text"
                                     value={email}
                                     onChange={(e) => setEmail(e.target.value)}
                                        placeholder="My new tags" />
                                    <label htmlFor="tags">Email</label>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="body">Comment</label>
                                    <textarea className="form-control" 
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    rows={6} />
                                </div>
                                <div className="mb-3">

                                    <button className="btn btn-primary"
                                    onClick={() => { mutate({ body: comments, postId: id, email: email,name:name }) }}
                                    >Comment</button>
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>}
        </CommonLayout>
    );
}

export default SingleBlogPage;
