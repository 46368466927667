import LoadingIndicator from "components/LoadingIndicator";
import useGenericActions from "hook/useGenericAction";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
export const Card = (props) => {
    return (
        <div className="card-subcontainer">
            <div className="card-image">
                <img src={props.image} alt="" />
            </div>
            <div className="card-content">
                <h3 className="post-title mb-2  text-truncate-2-lines"><Link to={`${props.slug}`}>{props.title}</Link></h3>
                <p className="text-muted mb-0 text-truncate-3-lines">{props.description}</p>
            </div>
        </div>
    );
};

const ServicesFull = () => {
    const { getPosts } = useGenericActions()
    const { data, isLoading } = useQuery(['forum post'], getPosts, {
        select: data => data.data.forums
    })

    return (
        <section className="page-section impact-stories" id="impact-stories">
            <div className="container px-4 px-lg-5">
                <h1 className="section-title text-center mt-0">
                    Our Blog Stories
                    <span className="subtitle">
                        See how donations and supports from our MILMAP benefactors are being utilised to put smiles on the faces of Nigerians.
                    </span>
                </h1>
                <hr className="divider" />
                <div className="row g-4 g-lg-5 cards-container mt-5">
                    {isLoading ?
                        <LoadingIndicator /> :
                        data?.data?.map(post =>
                            <Card
                                key={post.id}
                                image={post.featured_image || '/img/placeholder.png'}
                                title={post.title}
                                slug={post.id}
                                description={post.body} />)
                    }

                </div>
            </div>
        </section>
    );
}

export default ServicesFull;
