import { useFormik } from "formik"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as Yup from 'yup'
import useUserActions from "./useUserActions"
import { notify } from "components/Alerts"
import { fileToDataUri } from "utils/fileToData"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { userSelector } from "./state/user"
import ButtonWithTips from "components/ButtonWithTips"
const validationSchema = Yup.object({
  tagline: Yup.string()
    .required('Field cannot be empty'),
  description: Yup.string()
    .required('Must not be empty'),
  duration_in_days: Yup.number()
    .required('Must not be empty'),
  featured_image: Yup.mixed()
    .test('fileSize', 'File too large', (value) => value === null || (value && value.size < 1200000))
    .test(
      'fileFormat',
      'Unsupported file type',
      (value) => value === null || (value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/svg+xml', 'image/svg', 'image/webp'].includes(value.type))
    ),
  start_date: Yup.string()
    .required('Field cannot be empty'),
  end_date: Yup.string()
    .required('Field cannot be empty'),
  
})

const useActivityUser = () => {
  const queryClient = useQueryClient()
  const user = useRecoilValue(userSelector)
  const [page, setPage] = useState(1)
  const [, setData] = useState(null)
  const [current, setCurrent] = React.useState(null)
  const [currentData, setCurrentData] = React.useState(null)
  const [img, setImg] = React.useState(() => currentData?.featured_image || null)
  const [open, setOpen] = React.useState(false)
  const [openAdd, setOpenAdd] = React.useState(false)
  const navigate = useNavigate()
  const {
    getActivities,
    createNewActivity,
    updateAnActivity,
    deleteAnActivity, } = useUserActions()

  const handleFeaturedImageChange = async (e) => {
    const image = e.target.files[0];
    if (image?.type?.match(/^image\//)) {
      await fileToDataUri(image)
        .then(async (_image) => {
          setImg(_image);
          formik.setFieldValue("featured_image", image);
        })
        .catch((error) => console.log(error))
    }

  };

  const handleDateDifference = async (start_date,e) => {
    
      let startDate = new Date( start_date );
      let endDate = new Date( e.target.value );
      var totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      formik.setFieldValue("duration_in_days", totalDays);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      tagline: '',
      description: '',
      featured_image: '',
      duration_in_days: '',
      start_date: '',
      end_date: '',
    },
    onSubmit: (values) => {
        const formData = new FormData()
        Object.entries(values).map(([key, value]) => key === 'categories' || key === 'tags' ? value?.map((item, i) => formData.append(`${key}[${i}]`, item)) : formData.append(key, value))
        createActivityMutation.mutate(formData)
    },
  })

  const { data: activities, isLoading: isActivityLoading, isFetching } = useQuery('activities', getActivities, {
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
  })

  const createActivityMutation = useMutation(createNewActivity, {
    onSuccess: (data) => {
      formik.resetForm()
      setOpenAdd(false)
      setCurrent(null)
      queryClient.invalidateQueries('activities')
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })
  const updateActivityMutation = useMutation(updateAnActivity, {
    onSuccess: (data) => {

      queryClient.invalidateQueries('activities')
      setOpenAdd(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const deleteActivityMutation = useMutation(deleteAnActivity, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('activities')
      setOpen(false)
      setCurrentData(null)
      notify(data.message, { type: 'success' })
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Tagline',
        accessor: 'tagline',
      },
      {
        Header: 'Desciption',
        accessor: 'description',
      },
      {
        Header: 'Duration',
        accessor: 'duration_in_days',
      },
      {
        Header: 'Start',
        accessor: 'start_date',
      },
      {
        Header: 'End',
        accessor: 'end_date',
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => row.status === 'pending' ? <span className="d-block text-center bg-red rounded-pill py-1 px-2 text-white">{row.status}</span> : <span className="d-block text-center bg-light-green rounded-pill py-1 px-2 text-white">{row.status}</span>
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Activity Details" btnProps={{
              className: "btn btn-info", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>

            {row.row.original?.user_id === user.id && <>
              <div className="dropdown">
                <ButtonWithTips id='dropdownMenuButtonTips' tips="Click to perform other actions" btnProps={{
                  className: "btn",
                  'data-toggle': "dropdown",
                  'aria-haspopup': "true",
                  "aria-expanded": "false",
                  type: 'button',
                  id: 'dropdownMenuButton'
                }}><i className="fa fa-ellipsis-v" aria-hidden="true" /></ButtonWithTips>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" onClick={() => {
                    setCurrent('delete')
                    handleclick(row.row.original)
                  }}><i className="fas fa-trash" /> Delete</button>

                  <button className="dropdown-item" onClick={() => {
                    setCurrent('edit')
                    setOpenAdd(true)
                    handleclick(row.row.original)
                  }}><i className="fas fa-pencil" /> Edit</button>

                </div>
              </div>
            </>}
          </div>
        ),
      }
    ],
    []
  )

  const deleteRecordHandler = () => {
    deleteActivityMutation.mutate({ id: currentData?.id })
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  return {
    activities, isActivityLoading, columns, isFetching, page, setPage, setData, currentData, current, open, setOpen, createActivityMutation, formik, setCurrent, img, setImg, handleFeaturedImageChange, handleDateDifference, setCurrentData, deleteRecordHandler, updateActivityMutation, openAdd, setOpenAdd
  }
}

export default useActivityUser
