import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import Select from "react-select";
import AutoSizer from "react-virtualized-auto-sizer";

const SelectWrapper = React.memo((props) => {
  const {
    hasNextPage,
    isNextPageLoading,
    options,
    loadNextPage,
    placeholder,
    onChange,
    value,
  } = props;

  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const itemCount = hasNextPage ? options.length + 1 : options.length;

  const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !hasNextPage || index < options.length;

  const MenuList = () => {
    const childrenArray = options;
    // Render an item or a loading indicator.
    const Item = ({ index, style, ...rest }) => {
      let content;
      if (!isItemLoaded(index)) {
        content = "Loading...";
      } else {
        content = options[index].label;
      }

      return (
        <div
          key={content?.value}
          className="drop-down-list"
          style={{
            borderBottom: '1px solid rgb(243 234 234 / 72%)',
            display: 'flex',
            paddingLeft: '5px',
            alignItems: 'center',
            cursor: 'pointer',
            ...style,
          }}
          onClick={() => handleChange(options[index])}
        // {...rest}
        >
          {content}
        </div>
      );
    };

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <InfiniteLoader
            isItemLoaded={(index) => index < options.length}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={0}
          >
            {({ onItemsRendered, ref }) => (
              <List
                height={150}
                itemCount={itemCount}
                itemSize={35}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}
                overscanCount={2}//The number of options (rows or columns) to render outside of the visible area.
              >
                {Item}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  };

  const handleChange = (selected) => {
    onChange(selected);
  };

  return (
    <Select
      placeholder={placeholder}
      components={{ MenuList }}
      value={selectedOption}
      options={options}
      {...props}
    />
  );
});
export default SelectWrapper;
