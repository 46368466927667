import React from 'react'
import { useMutation } from 'react-query';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { notify } from "components/Alerts";
import LoadingIndicator from "components/LoadingIndicator";
import useAdminActions from 'hook/useAdminActions';
import { useLocation } from 'react-router-dom';
import useUserActions from 'hook/useUserActions';
import { scrollToErrors } from 'utils/functions';


const validationSchema = Yup.object({
  current_password: Yup.string()
    .required('Must be valid email'),
  new_password: Yup.string()
    .min(8, 'Password cannot be less than 8 characters')
    .required('Password cannot be empty'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Passwords must match'),
})
const Password = () => {
  const location = useLocation()
  const routeCheck = location.pathname.includes('admin')
  const { updateAdminPassword } = useAdminActions()
  const { updateUserPassword } = useUserActions()

  const { mutate, isLoading } = useMutation(routeCheck ? updateAdminPassword : updateUserPassword, {
    onSuccess: (data) => {
      // setEmail(null)
      notify(data.message, { type: 'success' })
      formik.resetForm()
    },
    onError: (error) => {
      notify(error, { type: 'error' })
    }
  })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    onSubmit: (values) => {
      const credentials = {
        current_password: values.current_password,
        new_password: values.new_password,
        new_password_confirmation: values.new_password_confirmation
      }
      mutate(credentials)
    },
  })
  return (
    <div className='row'>
      <div className='col-lg-6 offset-lg-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <div className="form-floating">
              <input className="form-control" id="current_password" name='current_password' type="password" value={formik.values.current_password} onChange={formik.handleChange} />
              <label htmlFor="current_password">Current password</label>
            </div>
            {(formik.errors.current_password ||
              formik.touched.current_password) && <small className="error">{formik.errors.current_password} </small>}
          </div>
          <div className="mb-3">
            <div className="form-floating">
              <input className="form-control" id="new_password" name='new_password' type="password" value={formik.values.new_password} onChange={formik.handleChange} />
              <label htmlFor="new_password">New password</label>
            </div>
            {(formik.errors.new_password ||
              formik.touched.new_password) && <small className="error">{formik.errors.new_password} </small>}
          </div>

          <div className="mb-3">
            <div className="form-floating">
              <input className="form-control" id="new_password_confirmation" name='new_password_confirmation' type="password" value={formik.values.new_password_confirmation} onChange={formik.handleChange} />
              <label htmlFor="new_password_confirmation">Confirm password</label>
            </div>
            {(formik.errors.new_password_confirmation ||
              formik.touched.new_password_confirmation) && <small className="error">{formik.errors.new_password_confirmation} </small>}
          </div>
          <div
            className="d-flex align-items-center justify-content-end mt-4 mb-0">
            <button className="btn btn-primary" type="submit" disabled={isLoading} onClick={() => scrollToErrors(formik.errors)}>{isLoading && <LoadingIndicator />}Update</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Password
