import React, { useState } from "react";
import Table from "../../../components/TableTwo";
import { USER_LOG } from "../../../navigation/routes";
import makeData from "../../../utils/makeData";

const UsersLog = () => {
    const [currentView, setCurrentView] = useState('main')
    const [data, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
    const [callData, setCallData] = React.useState(React.useMemo(() => makeData('call history', 15), []))
    const [skipPageReset] = React.useState(false)

    const columnCalls = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Duration(mm:ss)',
                accessor: 'duration',
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Time',
                accessor: 'time'
            }
        ],
        []
    )
    const columnMessages = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Subject',
                accessor: 'subject',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Date',
                accessor: 'messagedate'
            }
        ],
        []
    )
    const columnUsers = React.useMemo(
        () => [
            {
                Header: 'Unique Id',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Contact no',
                accessor: 'contactno',
            },
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'Date joined',
                accessor: 'date',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => (
                    <div className="flex flex-wrap gap-2 w-100">
                        <button className="btn btn-secondary" ><i className="fas fa-pencil" /></button>
                        <button className="btn btn-primary" >Manage</button>
                    </div>
                ),
            }
        ],
        []
    )
    const columnCallRecords = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Contact no',
                accessor: 'contactno',
            },
            {
                Header: 'Call type',
                accessor: 'calltype',
            },
            {
                Header: 'Call type',
                accessor: 'callcenter',
            },
            {
                Header: 'Duration(mm:ss)',
                accessor: 'duration',
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Time',
                accessor: 'time'
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => (
                    <div className="flex flex-wrap gap-2 w-100">
                        <button className="btn btn-secondary" ><i className="fas fa-phone" /></button>
                    </div>
                ),
            }
        ],
        []
    )

    const columnMessageRecords = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Contact Address',
                accessor: 'email',
            },
            {
                Header: 'Message Type',
                accessor: 'type',
            },
            {
                Header: 'CRM Id',
                accessor: 'crmid',
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Time',
                accessor: 'time'
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: row => (
                    <div className="flex flex-wrap gap-2 w-100">
                        <button className="btn btn-primary" ><i className="fas fa-envelope" /></button>
                    </div>
                ),
            }
        ],
        []
    )
    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Call/Sms Center</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={USER_LOG}>Call/Sms Center</a></li>
                            <li className="breadcrumb-item active">Users</li>
                            <li className="breadcrumb-item active">{currentView === 'main' ? 'Dashboard' : 'Logs'}</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-sm-end mb-2">
                        <button className="btn btn-primary" onClick={() => currentView === 'main' ? setCurrentView('log') : setCurrentView('main')}>{currentView === 'main' ? 'View Logs' : 'View call dashboard'}</button>
                    </div>
                </div>
                {currentView === 'main' && <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-primary text-white mb-4">
                            <div className="card-header">Current Highest Donation
                            </div>
                            <div className="card-body flex flex-column">
                                <span className="small text-white stretched-link">Name: Ciroma Daniel</span>
                                <span className="small text-white stretched-link">Amount: ₦15,000.00</span>
                                <span className="small text-white stretched-link">Date: 18/05/2022</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-warning text-white mb-4">
                            <div className="card-header">Highest Sign-up Region</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Name: Southwest</span>
                                <span className="small text-white stretched-link">Number: 		280</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-success text-white mb-4">
                            <div className="card-header">Call History</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">Incoming Calls: 150</span>
                                <span className="small text-white stretched-link">Outgoing calls :		280</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-danger text-white mb-4">
                            <div className="card-header">Messages</div>
                            <div className="card-body d-flex flex-column">
                                <span className="small text-white stretched-link">SMS: 150</span>
                                <span className="small text-white stretched-link">Emails: 280</span>
                            </div>
                        </div>
                    </div>
                </div>}

                {currentView === 'main' && <>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <i className="fas fa-table me-1"></i>
                                    Incoming calls
                                </div>
                                <div className="card-body">
                                    <Table
                                        columns={columnCalls}
                                        data={callData}
                                        setData={setCallData}
                                        skipPageReset={skipPageReset}
                                        pagesize={5}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <i className="fas fa-table me-1"></i>
                                    Outgoing calls
                                </div>
                                <div className="card-body">
                                    <Table
                                        columns={columnCalls}
                                        data={callData}
                                        setData={setCallData}
                                        skipPageReset={skipPageReset}
                                        pagesize={5}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Messages History
                        </div>
                        <div className="card-body">
                            <Table
                                columns={columnMessages}
                                data={callData}
                                setData={setCallData}
                                skipPageReset={skipPageReset}
                                pagesize={5}
                            />
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Users
                        </div>
                        <div className="card-body">
                            <Table
                                columns={columnUsers}
                                data={data || []}
                                setData={setData}
                                skipPageReset={skipPageReset}
                                pagesize={5}
                            />
                        </div>
                    </div>
                </>}
                {currentView === 'log' && <>
                    <div className="card mb-4">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Call records
                        </div>
                        <div className="card-body">

                            <Table
                                columns={columnCallRecords}
                                data={callData}
                                setData={setCallData}
                                skipPageReset={skipPageReset}
                                pagesize={5}
                            />
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Email/SMS records
                        </div>
                        <div className="card-body">
                            <Table
                                columns={columnMessageRecords}
                                data={callData}
                                setData={setCallData}
                                skipPageReset={skipPageReset}
                                pagesize={5}
                            />
                            {/* <Table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Contact Address</th>
                                        <th>Message Type</th>
                                        <th>CRM Id</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Contact Address</th>
                                        <th>Message Type</th>
                                        <th>CRM Id</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr>
                                        <td>001</td>
                                        <td>Emeka Ike</td>
                                        <td>09012345678</td>
                                        <td>SMS</td>
                                        <td>001</td>
                                        <td>2011/04/25</td>
                                        <td>09:10:30 AM</td>
                                        <td><div className="flex flex-wrap gap-2 w-100">
                                            <button type="button" className="btn btn-secondary me-2"><i className="fas fa-envelope"></i></button></div></td>
                                    </tr>
                                    <tr>
                                        <td>002</td>
                                        <td>Chioma Ike</td>
                                        <td>chioma.i@test.com</td>
                                        <td>Email</td>
                                        <td>001</td>
                                        <td>2011/04/25</td>
                                        <td>09:10:30 AM</td>
                                        <td><div className="flex flex-wrap gap-2 w-100">
                                            <button type="button" className="btn btn-secondary me-2"><i className="fas fa-envelope"></i></button></div></td>
                                    </tr>

                                </tbody>
                            </Table> */}
                        </div>
                    </div>
                </>}
            </div>
        </>
    );
}

export default UsersLog;
