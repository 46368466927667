export const routes = {
  FORUM_POSTS: '/forum/posts',
  TESTIMONIALS: '/forum/testimonials',
  BENEFICIARIES: '/forum/beneficiaries',
  LOGIN: '/forum/sign-in',
  LOGOUT: '/forum/logout',
  CHANNELS: '/forum/home',
  BOARD_CHANNEL: '/forum/board',
  CAPRO_CHANNEL: '/forum/capro',
  MANAGE_USERS: '/forum/settings/users',
  MANAGE_POST: '/forum/settings/posts',
  DOCUMENTS: '/forum/documents',
  CHAT: '/forum/chat',
  SETTINGS: '/forum/settings',
  FORGOT_PASSWORD: '/forum/forgot-password',
  RESET_PASSWORD: '/forum/reset-password',
  VERIFY_EMAIL: '/forum/email/verify',


  ADMIN_LOGIN: '/admin/sign-in',
  ADMIN_LOGOUT: '/admin/logout',
  ADMIN_FORGOT_PASSWORD: '/admin/forgot-password',
  ADMIN_VERIFY_EMAIL: '/admin/email/verify',
  ADMIN_RESET_PASSWORD: '/admin/reset-password',
  ADMIN_TESTIMONIALS: '/admin/testimonials',
  ADMIN_DASHBOARD: '/admin/admin-dashboard',
  USER_MANAGEMENT: '/admin/user-management',
  ADMIN_MANAGEMENT: '/admin/admin-management',
  CAPRO_MANAGEMENT: '/admin/capro-management',
  BOARD_MANAGEMENT: '/admin/board-management',
  ALUMNI_MANAGEMENT: '/admin/alumni-management',
  //ADMIN_BENEFICIARIES: '/admin/beneficiaries',
  ADMIN_BENEFICIARIES: '/admin/beneficiaries/students',
  ADMIN_BENEFICIARIES_ID: '/admin/beneficiaries/students/',
  ADMIN_ALUMNI: '/admin/beneficiaries/alumni',
  ADMIN_OTHERS: '/admin/beneficiaries/others',
  ADMIN_COMPANY_IFO: '/admin/company-info',
  ADMIN_IMPORT_BENEFICIARY: '/admin/import-beneficiaries',
  BLOG_POST: '/admin/blog-posts',
  NEWS_POST: '/admin/news-posts',
  MEDIA: '/admin/media',
  BANK: '/admin/bank',
  BOARD_POSITION: '/admin/board_position',
  MEDIA_IMAGE: '/admin/media-image',
  ADMIN_CHAT: '/admin/chat',
  MEDIA_FEATURED_VIDEO: '/admin/media-featured-video',
  ADMIN_SETTINGS: '/admin/settings',
};
