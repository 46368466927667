import { notify } from "components/Alerts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { token } from "utils/constants";
import { authAtom } from "./state/auth";
import { userAtom } from "./state/user";
import useAxios from "./useAxios";
import { ADMIN_ROUTES, DASHBOARD, FORUM, USER_ROUTES } from 'navigation/routes'

const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const setUser = useSetRecoilState(userAtom);
  const axios = useAxios()
  const navigate = useNavigate()


  return {
    loginAdmin,
    loginUser,
    registerUser,
    getDetailsByPaymentReference,
    registrationComplete,
    verifyUser,
    resendVerificationLink,
    resendAdminVerificationLink,
    logout,
    paySignOnFee,
    verifyAdmin,
    forgotPasswordAdmin,
    resetPasswordAdmin,
    forgotPasswordUser,
    resetPasswordUser,
    contactUs
  }

  function loginUser({ email, password }) {
    return axios.post(`/user/auth/login`, { email, password })
      .then(user => {
        if (user?.data.user.is_blocked) {
          notify('Your account is blocked. Please, contact the support team', { type: 'error' })
          return
        }
        setAuth(user?.data);
        setUser(user?.data?.user)

        return user
      });
  }

  function loginAdmin({ email, password }) {
    return axios.post(`/admin/auth/login`, { email, password })
      .then(user => {
        if (user?.data.admin.is_blocked) {
          notify('Your account is blocked. Please, contact the support team', { type: 'error' })
        } else {
          setAuth(user?.data);
          setUser(user?.data?.admin)
          // if (user?.data.admin.email_verified_at) {
          navigate(`${ADMIN_ROUTES}/${DASHBOARD}`, { replace: true });
          // } else {
          //   // navigate(routes.ADMIN_VERIFY_EMAIL, { replace: true });
          // }
        }

        return user
      });
  }
  function getDetailsByPaymentReference(id) {
    return axios.get(`/generic/paystack/payment-reference/${id}`);
  }
  function registerUser(payload) {
    return axios.post(`/user/auth/register`, payload)
  }

  function contactUs(payload) {
    return axios.post(`/generic/contactus`, payload)
  }

  function registrationComplete(payload) {
    return axios.post(`/user/auth/register-complete`, payload)
  }
  function paySignOnFee(payload) {
    return axios.post(`/user/single-sign-on`, payload)
  }


  function verifyUser(payload) {
    return axios.get(`/user/auth/email/verify?expires=${payload.expires}&hash=${payload.hash}&id=${payload.id}&signature=${payload.signature}`)
  }

  function verifyAdmin(payload) {
    return axios.get(`/admin/auth/email/verify?expires=${payload.expires}&hash=${payload.hash}&id=${payload.id}&signature=${payload.signature}`)
  }

  function resendAdminVerificationLink(payload) {
    return axios.get(`/admin/auth/email/resend-verification?callbackUrl=${payload.callbackUrl}`)
  }

  function resendVerificationLink(payload) {
    return axios.post(`/user/auth/email/resend-verification`, payload)
  }

  function forgotPasswordAdmin(payload) {
    return axios.post(`/admin/auth/password/forgot`, { email: payload.email, callbackUrl: `${window.location.origin}/admin/reset-password` })
  }

  function resetPasswordAdmin(payload) {
    return axios.post(`/admin/auth/password/reset`, payload)
  }

  function forgotPasswordUser(payload) {
    return axios.post(`/user/auth/password/forgot`, { callbackUrl: `${window.location.origin}/reset-password`, email: payload.email })
  }

  function resetPasswordUser(payload) {
    return axios.post(`/user/auth/password/reset`, { ...payload })
  }

  function logout(route) {
    if (auth.admin) {
      axios.post(`/admin/auth/logout`)
        .then(res => {
          localStorage.removeItem(token);
          setAuth(null);
          navigate(route, { replace: true });
        });
    } else if (auth.user) {
      axios.post(`/user/auth/logout`)
        .then(res => {
          localStorage.removeItem(token);
          setAuth(null);
          navigate(route, { replace: true });
        });
    } else {
      localStorage.removeItem(token);
      setAuth(null);
      navigate(route, { replace: true });
    }

  }


}

export default useAuth;
