import ButtonWithTips from 'components/ButtonWithTips';
import Select from 'components/innerComponents/Select';
import LoadingIndicator from 'components/LoadingIndicator';
import { userSelector } from 'hook/state/user';
import useDonation from 'hook/useDonation';
import { DONATE, DONATION, USER_ROUTES } from 'navigation/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { formatMoney } from 'utils/functions';
const usAmmount = [50, 100, 150, 200];
const ngAmmount = [2000, 4000, 5000, 10000];
const usMonthlyAmmount = [10, 25, 50, 100];
const ngMonthlyAmmount = [500, 1000, 2000, 5000];
const Hero = () => {
  const userDetails = useRecoilValue(userSelector);
  const { donationCause, createDonationMutation } = useDonation();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currency, setCurrency] = useState('NGN');
  const [currentPlan, setCurrentPlan] = useState('recurrent');
  const [currentView, setCurrentView] = useState('amount');
  const [name, setName] = useState({
    fName: '',
    lName: '',
  });
  const [email, setEmail] = useState('');
  const [amountArray, setAmountArray] = useState(ngAmmount);
  const [amount, setAmount] = useState(0);
  const [donor_phone_number, setDonor_phone_number] = useState('');
  useEffect(() => {
    if (currentPlan === 'One Off') {
      if (currency == 'NGN') {
        setAmountArray(ngAmmount);
      } else {
        setAmountArray(usAmmount);
      }
    } else {
      if (currency == 'NGN') {
        setAmountArray(ngMonthlyAmmount);
      } else {
        setAmountArray(usMonthlyAmmount);
      }
    }
  }, [currency, currentPlan]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const details = {
      currency,
      donor_name: `${name.fName} ${name.lName}`,
      donation_id: selectedCategory.id,
      donor_email: email,
      donor_status_id: 2,
      donor_phone_number: donor_phone_number,
      amount,
      donation_cycle: 'one-off',
      callbackUrl: window.location.origin,
    };
    createDonationMutation.mutate(details);
  };

  const navigate = useNavigate();
  function _navigateToPage() {
    if(userDetails){
      if(userDetails.roles)
      {

      }else{
        navigate(USER_ROUTES+"/"+ DONATE, {
        state: {
          amount,
          currency,
          type: 'recurrent',
        },
      });
      }
    }
    else{
       navigate(DONATION, {
      state: {
        amount,
        currency,
        type: 'recurrent',
      },
    });
    }
   
  }
  return (
    <header className="masthead">
      <div className="container px-4 px-lg-5 h-100">
        <video autoPlay muted loop id="heroVideo">
          <source src="./img/video/home_video_banner.mp4" type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="row hero-row gx-4 gx-lg-5 h-100 gap-4 gap-md-0">
          <div className="col-md-6 align-items-center justify-content-center">
            <div className="align-self-baseline">
              <h1 className="text-white font-weight-bold">
                Million Movement of People Against Poverty
              </h1>
              <hr className="divider left" />
            </div>
            <div className="align-self-end">
              <p className="text-white mb-5">
                MILMAP is an <strong>ActionAid Nigeria</strong> platform where
                individuals make regular voluntary donations towards ActionAid’s
                work across Nigeria.
              </p>
              <a className="btn btn-hero" href="#who-we-are">
                <span>Learn More</span>
              </a>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="form-wrap hero-tab">
              <div className="form-holder">
                <ul
                  className="nav nav-pills mb-3 nav-justified"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item tab-1-nav" role="presentation">
                    <ButtonWithTips
                      id="monthly-tab"
                      tips="When you select the give regularly button you are agreeing to join our organisation as a member and give at a regular interval. your donation counts towards your level in our organisation"
                      btnProps={{
                        className: 'nav-link active',
                        onClick: () => setCurrentPlan('Recurrent'),
                        id: 'monthly-tab',
                        'data-bs-toggle': 'pill',
                        'data-bs-target': '#monthly',
                        type: 'button',
                        role: 'tab',
                        'aria-controls': 'monthly',
                        'aria-selected': 'false',
                      }}
                    >
                      Give Regularly
                    </ButtonWithTips>
                  </li>
                  <li className="nav-item tab-2-nav" role="presentation">
                    <ButtonWithTips
                      id="once-tab"
                      tips="When you select the give once button, like the name implies you give once and it does not count towards your membership with us, and no account is created."
                      btnProps={{
                        className: 'nav-link',
                        onClick: () => setCurrentPlan('One Off'),
                        id: 'once-tab',
                        'data-bs-toggle': 'pill',
                        'data-bs-target': '#once',
                        type: 'button',
                        role: 'tab',
                        'aria-controls': 'once',
                        'aria-selected': 'true',
                      }}
                    >
                      Give Once
                    </ButtonWithTips>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="monthly"
                    role="tabpanel"
                    aria-labelledby="monthly-tab"
                  >
                    <div className="content-wrap">
                      <div className="wrap-header">
                        <h4>Choose amount to give per month</h4>
                      </div>
                      <div className="wrap-body">
                        <div className="rendered-form ht-form">
                          <form name="one-time" action="" method="GET">
                            <div className="form-group">
                              <div className="row radio-group">
                                <div className="item col-6 col-sm-3">
                                  <input
                                    name="currency"
                                    access="false"
                                    id="NGN"
                                    value="NGN"
                                    onChange={(e) =>
                                      setCurrency(e.target.value)
                                    }
                                    checked={currency === 'NGN'}
                                    type="radio"
                                  />
                                  <label htmlFor="NGN">NGN</label>
                                </div>
                                <div className="item col-6 col-sm-3">
                                  <input
                                    name="currency"
                                    access="false"
                                    id="USD"
                                    value="USD"
                                    type="radio"
                                    onChange={(e) =>
                                      setCurrency(e.target.value)
                                    }
                                    checked={currency === 'USD'}
                                  />
                                  <label htmlFor="USD">USD</label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row radio-group">
                                {amountArray.map((item) => (
                                  <div
                                    key={item}
                                    className="item monthly col-6 col-sm-4"
                                  >
                                    <input
                                      name="amount"
                                      access="false"
                                      id={`${item}${currency}`}
                                      value={item}
                                      type="radio"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                    <label htmlFor={`${item}${currency}`}>
                                      {formatMoney(item, currency)}
                                    </label>
                                  </div>
                                ))}
                                <div className="item monthly col-sm-8">
                                  <input
                                    name="amount"
                                    access="false"
                                    id="amount-other"
                                    className="undefined other-option"
                                    value=""
                                    type="radio"
                                  />
                                  <label
                                    className="other"
                                    htmlFor="amount-other"
                                  >
                                    Other
                                    <input
                                      type="number"
                                      step="1"
                                      id="amount-other-value"
                                      placeholder="75"
                                      className="other-val"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="btn-wrap">
                                <button type="button" onClick={_navigateToPage}>
                                  Join Today
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="once"
                    role="tabpanel"
                    aria-labelledby="once-tab"
                  >
                    <div className="content-wrap">
                      <div className="wrap-header">
                        <h4>
                          {userDetails
                            ? 'Choose amount to give'
                            : 'Login to make this donation count towards your growth with us'}
                        </h4>
                      </div>
                      <div className="wrap-body">
                        <div className="rendered-form ht-form">
                          <form name="one-time" onSubmit={handleSubmit}>
                            {currentView === 'amount' ? (
                              <>
                                {' '}
                                <div className="form-group">
                                  <div className="row radio-group">
                                    <div className="item col-6 col-sm-3">
                                      <input
                                        name="currency"
                                        access="false"
                                        id="NGN"
                                        value="NGN"
                                        onChange={(e) =>
                                          setCurrency(e.target.value)
                                        }
                                        checked={currency === 'NGN'}
                                        type="radio"
                                      />
                                      <label htmlFor="NGN">NGN</label>
                                    </div>
                                    <div className="item col-6 col-sm-3">
                                      <input
                                        name="currency"
                                        access="false"
                                        id="USD"
                                        value="USD"
                                        type="radio"
                                        onChange={(e) =>
                                          setCurrency(e.target.value)
                                        }
                                        checked={currency === 'USD'}
                                        disabled={true}
                                      />
                                      <label htmlFor="USD">USD</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row radio-group">
                                    {amountArray.map((item) => (
                                      <div
                                        key={item}
                                        className="item col-6 col-sm-4"
                                      >
                                        <input
                                          name="amount"
                                          access="false"
                                          id={`${item}${currency}`}
                                          value={item}
                                          type="radio"
                                          onChange={(e) =>
                                            setAmount(e.target.value)
                                          }
                                        />
                                        <label htmlFor={`${item}${currency}`}>
                                          {formatMoney(item, currency)}
                                        </label>
                                      </div>
                                    ))}
                                    <div className="item col-sm-8">
                                      <input
                                        name="amount"
                                        access="false"
                                        id="amount-other"
                                        className="undefined other-option"
                                        value=""
                                        type="radio"
                                      />
                                      <label
                                        className="other"
                                        htmlFor="amount-other"
                                      >
                                        Other
                                        <input
                                          type="number"
                                          step="1"
                                          id="amount-other-value"
                                          placeholder="75"
                                          value={amount}
                                          onChange={(e) =>
                                            setAmount(e.target.value)
                                          }
                                          className="other-val"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="btn-wrap">
                                    <button
                                      type="button"
                                      disabled={!amount}
                                      onClick={() => setCurrentView('details')}
                                    >
                                      Continue
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="item  form-floating">
                                      <input
                                        name="name"
                                        id="name"
                                        autoComplete="name"
                                        className="form-control"
                                        type="text"
                                        value={name.fName}
                                        onChange={(e) =>
                                          setName({
                                            ...name,
                                            fName: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                      <label htmlFor="name">First Name </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="item  form-floating">
                                      <input
                                        name="name"
                                        id="name"
                                        autoComplete="name"
                                        className="form-control"
                                        type="text"
                                        value={name.lName}
                                        onChange={(e) =>
                                          setName({
                                            ...name,
                                            lName: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                      <label htmlFor="name">Last Name </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="item  form-floating">
                                      <input
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        required
                                      />
                                      <label htmlFor="name">Email </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="item  form-floating">
                                      <Select
                                        id="donationType"
                                        className="form-select"
                                        selected={selectedCategory}
                                        handleChange={(e) => {
                                          setSelectedCategory(
                                            JSON.parse(e.target.value)
                                          );
                                        }}
                                        options={donationCause}
                                      />
                                      <label htmlFor="donationType">
                                        Cause{' '}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="item form-floating ">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="donor_phone_number"
                                        aria-label="donor_phone_number"
                                        value={donor_phone_number}
                                        onChange={(e) =>
                                          setDonor_phone_number(e.target.value)
                                        }
                                        aria-describedby="basic-addon1"
                                      />
                                      <label htmlFor="donor_phone_number">
                                        Phone number
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="btn-wrap">
                                  <button
                                    type="submit"
                                    disabled={
                                      !email ||
                                      !name.fName ||
                                      !name.lName ||
                                      createDonationMutation.isLoading
                                    }
                                  >
                                    {createDonationMutation.isLoading && (
                                      <LoadingIndicator />
                                    )}
                                    Donate
                                  </button>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;
