import React from "react";
import Modals from "components/Modal";
import { FORUM_MGT } from "navigation/routes";
import EnhancedTable from "components/EnhancedTable";
import EditForumForm from "./EditActivity";
import useActivityUser from "hook/useActivityUser";
import AddActivityFormUser from "./AddActivity";
import ViewActivity from "./viewActivities";
import { Modal } from "react-bootstrap";

const ActivityUser = () => {
    const { activities, columns, page, setPage, setData, isActivityLoading, open, current, setCurrent, setOpen, deleteRecordHandler, currentData, setCurrentData, formik, img, openAdd, setOpenAdd, createActivityMutation, handleFeaturedImageChange, handleDateDifference, updateActivityMutation } = useActivityUser()

    return (
        <>
            <div className="container-fluid px-4">
                <div className="d-sm-flex align-items-start justify-content-between mt-4">
                    <div>
                        <h1>Activity</h1>
                        <ol className="breadcrumb mb-2">
                            <li className="breadcrumb-item"><a href={FORUM_MGT}>Activity</a></li>
                            <li className="breadcrumb-item active">All</li>
                        </ol>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end mb-2">
                        <button className="btn btn-primary" onClick={() => {
                            setCurrent('add')
                            setOpenAdd(true)
                        }}>Create Activity</button>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        My Activity
                    </div>
                    <div className="card-body">
                        <EnhancedTable columns={columns} data={activities?.data?.activity?.data || []} setData={setData}
                            skipPageReset={false}
                            lastPage={page}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isFetching={isActivityLoading} />
                    </div>
                </div>
            </div>
            <Modal show={openAdd} onHide={() => setOpenAdd(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{current === 'add' ? 'Create Forum' : 'Edit Forum'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentData && current === 'edit' ?
                        <EditForumForm data={currentData} setData={setCurrentData} img={img} updateActivityMutation={updateActivityMutation} /> :
                        <AddActivityFormUser formik={formik} createActivityMutation={createActivityMutation} img={img} handleFeaturedImageChange={handleFeaturedImageChange} handleDateDifference={handleDateDifference} />}
                </Modal.Body> 
            </Modal>

            <Modals show={open && current === 'view'}
                onHide={() => setOpen(false)} title='View Activity' size='md'>
                <ViewActivity id={currentData?.id} />
            </Modals>
            <Modals show={open && current === 'delete'}
                onHide={() => setOpen(false)} title='Delete Advert' footer={<button type="button" className="btn btn-primary" onClick={deleteRecordHandler}>Delete</button>}>
                <div className="striped">
                    {currentData && <p>Are you sure you want to delete <b>{currentData?.tagline}</b>?</p>}
                </div>
            </Modals>
        </>
    );
}

export default ActivityUser;
