import Input from 'components/innerComponents/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react'
import { useState } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { fileToDataUri } from 'utils/fileToData';

const EditForumForm = ({ categories, isLoadingCategories, updateForumMutation, data, setData }) => {

  const [img, setImg] = useState(() => data?.featured_image)

  const handleFeaturedImageChange = async (e) => {
    const image = e.target.files[0];
    if (image?.type?.match(/^image\//)) {
      await fileToDataUri(image)
        .then(async (_image) => {
          setImg(_image)
        })
        .catch((error) => console.log(error))
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    const { id, meta, body, title, categories, tags, featured_image, is_active } = data
    const newdata = {
      id, meta, body, title, categories, tags, featured_image, _method: 'PUT'
    }
    const formData = new FormData()
    Object.entries(newdata).map(([key, value]) => key === 'categories' ?
      value?.map((item, i) => formData.append(`${key}[${i}]`, item?.id))
      : key === 'tags' ?
        value?.map((item, i) => formData.append(`${key}[${i}]`, item?.name))
        : key === 'featured_image' && typeof value === 'string' ?
          []
          : formData.append(key, value))
    updateForumMutation.mutate(formData)
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="modal-body">
        <div className="form-group mb-3">
          <Input
            name='title'
            value={data?.title}
            type='title'
            placeholder='Title'
            label='Title'
            className='form-group'
            handleChange={(e) => setData({ ...data, title: e.target.value })}
            error={null}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="category">Category</label>
          <ReactSelect
            id='category'
            name="categories"
            placeholder='Select categories'
            isSearchable
            isClearable
            isMulti
            noOptionsMessage={() => 'No Category found.'}
            onChange={(e) => {
              setData({ ...data, categories: e })
            }}
            value={data?.categories}
            options={categories}
            isDisabled={isLoadingCategories}
            isLoading={isLoadingCategories}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option['id']}`}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="meta">Description</label>
          <textarea className="form-control" id="meta" name='meta' value={data?.meta}
            placeholder="description" onChange={(e) => setData({ ...data, meta: e.target.value })} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="body">Body</label>
          <textarea className="form-control" id="body" name='body' rows={3} value={data?.body}
            placeholder="Body" onChange={(e) => setData({ ...data, body: e.target.value })} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="tags">Tags</label>
          <CreatableSelect name="tags"
            isMulti
            placeholder='Type to create new tags'
            options={[{ name: 'education' }, { name: 'techie' }]}
            isSearchable
            isClearable
            value={data?.tags}
            onChange={(e) => setData({ ...data, tags: e })}
            noOptionsMessage={() => 'type and enter to create a tag'}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.name}`} />
        </div>
        <div className="mb-3">
          <label htmlFor="media">Media</label>
          <input type="file" className="form-control" id="media" accept="image/*" aria-describedby="media" aria-label="Upload" name="featured_image" onChange={(e) => {
            handleFeaturedImageChange(e);
            setData({ ...data, featured_image: e.target.files[0] })
          }} />
          {img && <div className="mt-2 w-100">
            <img src={img} alt="" className="mw-100 rounded" />
          </div>}
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary" disabled={updateForumMutation.isLoading}>{updateForumMutation.isLoading && <LoadingIndicator />}Update</button>
      </div>
    </form>
  )
}

export default EditForumForm
