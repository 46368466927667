import Modals from "components/Modal";
import { authAtom } from "hook/state/auth";
import useUserActions from "hook/useUserActions";
import { PROFILE, USER_ROUTES } from "navigation/routes";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { useRecoilValue } from "recoil";


const Header = ({ clicked, setClicked, setOpen, user }) => {
    const navigate = useNavigate()
    const [notification, setNotification] = useState([]);
    const { getUserNotificationsOnly } = useUserActions();
    const auth = useRecoilValue(authAtom)
 
    useEffect(() => {
        if (auth?.admin) {
            // getUserNotificationsOnly().then(data=>{
            //     setNotification(data.data.notifications)
            // });
        }else{
            getUserNotificationsOnly().then(data=>{
                setNotification(data.data.notifications)
            });
    }
      },[]);

      const handleClick=()=>{
        // debugger
      }
    return (
        <>
            <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                <Link className="navbar-brand ps-3" to="/"><img src="/img/logo_light.png" alt="logo" /></Link>
                <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#" data-bs-toggle="sb-sidenav-toggled"
                    data-bs-target="#layoutSidenav_nav" onClick={() => setClicked(!clicked)}><i className="fas fa-bars"></i></button>
                <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    <div className="input-group">
                        <input className="form-control" type="text" placeholder="Search for..."
                            aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                        <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i
                            className="fas fa-search"></i></button>
                    </div>
                </form>
                <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i> <span className="d-none d-sm-inline-block">{user}</span></a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href={USER_ROUTES+'/'+PROFILE}>My Account</a></li>
                            {/* <li><a className="dropdown-item" href="#">Activity Log</a></li> */}
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li><span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>Logout</span></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle position-relative" id="notificationDropdown"  onClick={handleClick} href="#" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bell fa-fw"></i><span className="badge bg-primary position-absolute rounded-circle">{notification.length}</span></a>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="notificationDropdown">
                            { notification?.map((prd, idx) =>
                            <div className="dropdown-item">
                                <div className="card" onClick={() => navigate('/users/notification')}>
                                    
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <strong className="mr-auto">{prd.title}</strong>
                                                <small><ReactTimeago date={prd.created_at} /></small>
                                            </div>
                                            <div className="toast-body">
                                            {prd.body}
                                            </div>
                                        </div>
                                        
                                </div>
                            </div>
                            )
                           }
                            {/* <div className="dropdown-item">
                                <div className="card" onClick={() => navigate('/notification')}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <strong className="mr-auto">Patriot</strong>
                                            <small>11 mins ago</small>
                                        </div>
                                        <div className="toast-body">
                                            Hello Ade, we are live!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-item">
                                <div className="card" onClick={() => navigate('/notification')}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <strong className="mr-auto">Live</strong>
                                            <small>11 mins ago</small>
                                        </div>
                                        <div className="toast-body">
                                            Hello Ade, we are live!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-item">
                                <div className="card" onClick={() => navigate('/notification')}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <strong className="mr-auto">Enjoy</strong>
                                            <small>11 mins ago</small>
                                        </div>
                                        <div className="toast-body">
                                            Hello Ade, we are live!
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    )
}
export default Header;
