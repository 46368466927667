import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useDonorStatusReport from "hook/reports/useDonorStatusReport";
import { DONOR__STATUS_ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import SelectBox from "components/common/Selects/SelectBox";
import MembershipEnhancedTable from "components/MembershipEnhancedTable";
import Select from "components/innerComponents/Select";
import ButtonWithTips from "components/ButtonWithTips";

const DonorStatusReport = () => {
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData,
    setFrom, setTo, current, edit, setEdit, isLoading, page, setPage, setSearch,setSearchStatus,getExcel } = useDonorStatusReport();
    console.log(data);
// console.log(data?.data);
    const statuses = [      
      { id: 1, value: 'Active', label: 'Active' },
      { id: 2, value: 'Dormant', label: 'Dormant' },
      
    ];
    
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donor Status Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><Link to='#'>Donor Status</Link></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONOR__STATUS_ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donor Status
          </div>
          
          <div className="row">
              <div className="col-3">Search By Name
                <input type='text' placeholder='Global Search Record' className="form-control" 
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                />
              </div>
              <div className="col-3" style={{width:'20%'}}>Select Status
              <SelectBox
                      selectName="status"
                      selectLabel={`Status`}
                      style={{width: '17%'}}
                      className="form-control"
                      isMulti={false}
                      itemList={statuses}
                      onChange={(e) => {
                         setSearchStatus(e.id);
                      }}
                      selectOption={`Select Status`}
                    
                    />
              
              </div>

              <div className="col-3">
                <div className="filterParameters" id="filterParameters">
                  <div className="datePickerWrapper">
                    <div className="d-sm-flex align-items-end gap-2">
                      <div className="form-inline">From:
                        <input type='date' className="form-control" onChange={(e) => {
                          setFrom(e.target.value);
                        }}  />
                      </div>
                      <div className="form-inline">To:
                        <input type='date' className="form-control" onChange={(e) => {
                          setTo(e.target.value);
                        }}/>
                      </div>
                      {/* <button className='btn btn-primary'><i className="fa fa-refresh"></i></button> */}
                      <ButtonWithTips
                          id="getExcel"
                          tips="Click to download data in excel format"
                          btnProps={{
                            className: 'btn btn-success',
                            onClick: getExcel,
                          }}
                        >
                          <i className="fas fa-file-excel"></i>
                        </ButtonWithTips>
                    </div>

                  </div>
                </div>
              </div>
           
          </div>

          <div className="card-body">
          <MembershipEnhancedTable 
              columns={columns}
              data={data?.data || []}
              // setData={setData}
              skipPageReset={skipPageReset}
              lastPage={data?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
               isFetching={false}          
            />
            {/* <TableTwo
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              fileName='donor-status-reports'
              isLoading={isLoading}
            /> */}
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donor Status' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default DonorStatusReport
